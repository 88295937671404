<template>
  <div
    class="d-flex flex-row justify-end" 
    :style="{
      'background-image': 'url(' + require('../assets/backGroundPicture.png') + ')',
      'background-color': '#000000',
      'background-position': 'bottom left',
      'background-size': '80%'
    }"
  >
    <div class="d-flex justify-center pa-4" style="width: 570px; height: 100vh">
      <v-card style="width: 100%; height: 100%">
        <div class="pt-12">
          <LogoSvg class="logo-svg"/>
        </div>
        <div class="pt-8 px-15">
          <div v-if="currentMode === 'login'">
            <LoginComponent @switch-to-register="switchModeToRegister" @switch-to-recovery="switchModeToRecovery" />  
          </div>
          <div v-if="currentMode === 'register'">
            <RegisterComponent @switch-to-login="switchModeToLogin" /> 
          </div>
          <div v-if="currentMode === 'recovery'">
            <RecoveryComponent @switch-to-login="switchModeToLogin"/>
          </div>
          <div v-if="currentMode === 'change-password'">
            <ChangePasswordComponent :token="token" @switch-to-login="switchModeToLogin"/>
           </div>
          <div v-if="currentMode === 'finish-registration'">
            <SuccessfullyRegisteredComponent :token="token" @switch-to-login="switchModeToLogin"/>
           </div>
        </div>
        <div class="semibold-14 d-flex align-center justify-center mt-16">
          2024. ООО "Интеллектуальные системы". Все права защищены
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import LoginComponent from '../components/LoginPage/LoginComponent.vue';
import RegisterComponent from '../components/LoginPage/RegisterComponent.vue';
import RecoveryComponent from '../components/LoginPage/RecoveryComponent.vue';
import ChangePasswordComponent from '../components/LoginPage/ChangePasswordComponent.vue'
import SuccessfullyRegisteredComponent from '../components/LoginPage/SuccessfullyRegisteredComponent.vue';
import LogoSvg from '../assets/LogoSvg.vue';

export default {
  components: {
    LoginComponent,
    RegisterComponent,
    RecoveryComponent,
    ChangePasswordComponent,
    SuccessfullyRegisteredComponent,
    LogoSvg
  },
  setup() {
    const currentMode = ref('login');
    const token = ref(null);
    const route = useRoute();

    onMounted(() => {
      // Check if we're in the change-password route with a token
      if (route.path === '/change-password/' && route.query.token) {
        token.value = route.query.token;
        currentMode.value = 'change-password'; // Set mode to change-password
      } else if (route.path === '/finish-registration/' && route.query.token) {
        token.value = route.query.token;
        currentMode.value = 'finish-registration'; // Set mode to change-password
      }
    });

    const switchModeToRegister = () => {
      currentMode.value = 'register';
    };

    const switchModeToLogin = () => {
      currentMode.value = 'login';
    };

    const switchModeToRecovery = () => {
      currentMode.value = 'recovery';
    };

    return {
      currentMode,
      token,
      switchModeToRegister,
      switchModeToLogin,
      switchModeToRecovery,
    };
  }
};
</script>

<style scoped>
.logo-svg {
  display: block;
  margin: 0 auto;
}
</style>
