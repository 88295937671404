<template>
  <v-container>
    <v-card-title class="d-flex justify-center text-h4">
      <div>{{ $t('loginPage.signin') }}</div>
    </v-card-title>
    <CustomTextInput
      v-model="emailModel"
      :placeholder="$t('loginPage.enterEmail')"
      :isValid="isFieldValid('email')"
      @validate="debouncedValidateEmail"
      prependIcon="at"
      height="48px"
      class="mb-2 pt-8"
    />
    <CustomPasswordField
      v-model="passwordModel"
      :placeholder="$t('loginPage.enterPassword')"
      :isValid="isFieldValid('password')"
      @validate="debouncedValidatePassword"
      prependIcon="lock"
      height="48px"
      class="mb-2"
    />
    <top-three-list 
      :records="errors.map(item => item.message)" 
      :rowsQty="2" 
    />
    <div class="d-flex flex-row justify-between align-center pb-8">
      <div>
        <CustomCheckbox 
          :disabled="isSubmitButtonDisabled" 
          v-model="rememberModel" 
          :label="$t('login.remember')"
          :isChecked="rememberModel"
          :size="50"
          labelSpanClass="pl-3"
        /> 
      </div>
      <div class="hyperlink-text" @click="switchModeToRecovery">
        {{ $t("login.forgotPassword") }}
      </div>
    </div>
    <CustomButton
      :label="$t('loginPage.signin')"
      backgroundColor="#1D85DD"
      textColor="white"
      customHeight="52px"
      iconClass="mr-2"
      rounded="medium"
      block
      :disabled="isSubmitButtonDisabled"
 
      @click="onSubmit"
    />
    <CustomButton 
      :label="$t('loginPage.logInViaGoogle')"
      backgroundColor="#4A4A4A"
      textColor="white"
      customHeight="52px"
      iconClass="mr-2"
      rounded="medium"
      block
      class="text-subtitle-1 my-2"
      googleIcon
      :disabled="false"
      @click="()=>{}"
    />
    <div class="regular-text d-flex flex-column justify-center align-center py-8">
      <div class="text-text-secondary mb-2">
        {{ $t("loginPage.dontHaveAccount") }}
      </div>
      <div
        class="hyperlink-text pl-2" 
        @click="$emit('switch-to-register')"
      >
        {{ $t("loginPage.signup") }}
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'; 
import TopThreeList from './TopThreeList.vue';
import { debounce } from 'lodash';
import { requiredRule, emailFormatRule, minLengthRule } from './rules';
import CustomTextInput from './CustomTextInput.vue';
import CustomPasswordField from './CustomPasswordField.vue';
import CustomCheckbox from '../Common/CustomCheckbox.vue';
import CustomButton from './CustomButton.vue';

export default {
  components: { 
    TopThreeList,
    CustomTextInput,
    CustomPasswordField,
    CustomCheckbox,
    CustomButton,
  },
  data() {
    return {
      emailModel: '',
      passwordModel: '',
      rememberModel: false,
      errors: [], 
    };
  },
  computed: {
    isFieldValid() {
      return (fieldName) => this.errors.filter(error => error.field === fieldName).length === 0;
    },
    isSubmitButtonDisabled() { 
      return !this.passwordModel || !this.emailModel || !this.isFieldValid('email') || !this.isFieldValid('password');
    },
    emailRules() {
      return [
        value => requiredRule(this.$t)(value, 'email'),
        value => emailFormatRule(this.$t)(value, 'email'),
        // value => minLengthRule(value, 'email'),
      ];
    },
    passwordRules() {
      return [
        value => requiredRule(this.$t)(value, 'password'),
        value => minLengthRule(this.$t)(value, 'password', 1),
        // value => uppercaseRule(value, 'password'),
        // value => numberRule(value, 'password'),
      ];
    }
  },
  methods: {
    ...mapActions('auth', ['authUser']),
    onSubmit() {
      this.errors = [];
      this.$store.dispatch("authUser", {
        email: this.emailModel,
        password: this.passwordModel,
        remember: this.rememberModel,
        callBack: () => this.$router.push("/"),
      }).then((response) => { 
        if(!response) return;
        const responseTypes = {
          'Wrong password': 'password',
          'password too short or contains invalid characters': 'password',
          'Email not registered': 'email',
        };
        this.errors.push({ field: responseTypes[response?.errorMessage||''] || 'email', message: this.$t(response?.errorMessage||'')}); //response?.errorMessage });
      });
    },
    switchModeToRecovery() {
      this.$emit('switch-to-recovery');
    },
    validateEmail() {
      if (!this.$t) return;
      this.errors = this.errors.filter(error => error.field !== 'email');
      const checkEmpty = requiredRule(this.$t)(this.emailModel, 'email');
      if (checkEmpty === true) {
        this.emailRules.forEach(rule => {
          const result = rule(this.emailModel);
          if (result !== true) {
            this.errors.push({ field: 'email', message: result });
          }
        });
      } else {
        this.errors.push({ field: 'email', message: checkEmpty });
      }
    },
    validatePassword() {
      if (!this.$t) return;
      this.errors = this.errors.filter(error => error.field !== 'password');
      const checkEmpty = requiredRule(this.$t)(this.passwordModel, 'password');
      if (checkEmpty === true) {
        this.passwordRules.forEach(rule => {
          const result = rule(this.passwordModel);
          if (result !== true) {
            this.errors.push({ field: 'password', message: result });
          }
        });
      } else {
        this.errors.push({ field: 'password', message: checkEmpty });
      }
    },
    debouncedValidateEmail: debounce(function() {
      this.validateEmail();
    }, 300),
    debouncedValidatePassword: debounce(function() {
      this.validatePassword();
    }, 300),
    // validationAfterMount() {
    //   if(this.emailModel != '') this.debouncedValidateEmail();
    //   if(this.passwordModel != '') this.debouncedValidatePassword();
    // }
    validationAfterMount() {      
        // console.log('this.emailModel',this.emailModel)
        // console.log('this.passwordModel',this.passwordModel)
        if (this.emailModel) this.debouncedValidateEmail();
        if (this.passwordModel) this.debouncedValidatePassword();
     
    },
  },
  mounted() { 
     setTimeout(() => {
      this.validationAfterMount(); 
     }, 3000);  
  },
  watch: {
  isSubmitButtonDisabled(newValue) {
    if (newValue) {
      this.rememberModel = false;  
    }
  }
}

};
</script>

<style scoped>
.hyperlink-text {
  cursor: pointer;
  color: var(--text-primary, #F4F4F4);  
  font-family: "Source Sans 3";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.regular-text {
  color: var(--text-secondary, #9A9A9A);
 
  font-family: "Source Sans 3";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
}
</style>
