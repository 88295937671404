<template>
  <div style="height: 56; width: 100%" class="d-flex">
    <v-btn
      @click="handleGoBack"
      class="d-flex align-center text-none ma-2"
      size="small"
    >
      <svg
        class="mx-2"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.5 15.8333L6.66667 9.99996L12.5 4.16663"
          stroke="#F4F4F4"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <div style="color: #F4F4F4" class="semibold-14">{{ $t('pages.mistakes') }}</div>
    </v-btn>
    <v-spacer />
    <slot name="compare" />
    <slot name="preferences" />
    <slot name="filters" />
    <slot name="zoom" />
    <slot name="training" />
    <slot name="fen" />

    <slot name="board" />
  </div>
</template>

<script>
export default {
  props: ['goBackText', 'goBackTarget'],
  methods: {
    handleGoBack() {
      this.$router.push({
        name: this.goBackTarget || 'PreAnalysis',
        params: {
          heroid: this.$route.params.heroid,
          categories: this.$route.params.categories,
          posid: this.$route.params.posid,
        },
      });
    },
  },
};
</script>

<style scoped></style>
