<template>
  <g style="cursor: pointer">
    <path stroke="#9A9A9A" stroke-width="1" :d="`M${left},${top} h${24}z`" />
    <path
      fill="transparent"
      stroke="#9A9A9A"
      stroke-width="2"
      :d="`M${left + 32},${top - borderRadius} h${
        32 - borderRadius * 2
      } a${borderRadius},${borderRadius} 0 0 1 ${borderRadius},${borderRadius}
          v${
            16 - borderRadius * 2
          } a${borderRadius},${borderRadius} 0 0 1 -${borderRadius},${borderRadius}
          h-${
            32 - borderRadius * 2
          } a${borderRadius},${borderRadius} 0 0 1 -${borderRadius},-${borderRadius}
          v-${
            16 - borderRadius * 2
          } a${borderRadius},${borderRadius} 0 0 1 ${borderRadius},-${borderRadius}
          z`"
    />
    <text
        :x="left + 32"
      :y="top + borderRadius - 4"
      font-size="12px"
      fill="#9A9A9A"
    >
        +5
    </text>

  </g>
</template>

<script>
export default {
  props: ['top', 'left', 'status', 'borderRadius'],
};
</script>

<style scoped></style>
