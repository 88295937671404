<template>
  <div class="custom-password-field">
    <div class="input-container"> 
      <custom-icon v-if="prependIcon" class="icon prepend-icon" :id="prependIcon" /> 
      <input
        :type="visible ? 'text' : 'password'"
        :placeholder="placeholder"
        :value="localValue"
        v-model="localValue"
        @input="onInput"
        @blur="onBlur"
        @focus="onFocus"
        :style="{ height: height }"
        :class="[
          'text-input',
          { 'active-border': isActive, 'error-border': !isValid && !isActive, 'valid-border': (isValid && !isActive) || isUnclicked }
        ]"
      /> 
      <custom-icon @click="toggleVisibility" v-if="prependIcon" class="icon append-icon" :id="visible ? 'eye-opened' : 'eye-closed'" /> 
    </div>
  </div>
</template>

<script>
import DOMPurify from 'dompurify';
import CustomIcon from '../../assets/CustomIcon.vue'
export default {
  components:{
    CustomIcon
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    prependIcon: {
      type: String,
      default: 'mdi-lock-outline',
    },
    type: {
      type: String,
      default: 'password',
    },
    height: {
      type: String,
      default: 'auto',
    },
    // rules: {
    //   type: Array,
    //   default: () => [],
    // },
    isValid: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isActive: false,
      isUnclicked: true,
      visible: false,  
      localValue: this.modelValue,
    };
  },
  methods: { 
    // sanitizeInput(value) { 
    //   return value.replace(/<script[^>]*?>.*?<\/script>|<link[^>]*?>|<a[^>]*>.*?<\/a>/gi, '');
    // },
    sanitizeInput(value) {
      return DOMPurify.sanitize(value, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] });
    },
    applyFilter(value) {  
          return value.replace(/[^a-zA-Z0-9!@#$%^&*()_+{}[\]:;"'<>,.?/~`\\|=-]/g, '');
      },
    onInput(event) {
        // eslint-disable-next-line
      let newValue = this.sanitizeInput(event.target.value.replace(/[\u0000-\u001F\u007F-\u009F]/g, "")); 
      newValue=this.applyFilter(newValue) ;
     
      this.$emit('update:modelValue', newValue);
      this.$emit('validate', newValue);  
      this.localValue = newValue;
    },
    onBlur() {
      this.isActive = false;
      this.$emit('validate', this.modelValue);  
    },
    onFocus() {
      this.isUnclicked = false;
      this.isActive = true;
    },
    toggleVisibility() {
      this.visible = !this.visible;  
    },
  },
};
</script>

<style scoped>
.custom-password-field {
  display: flex;
  flex-direction: column;
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.icon {
  position: absolute;
  pointer-events: auto;  
}

.prepend-icon {
  left: 10px;
  color: gray; 
}

.append-icon {
  right: 10px;
  cursor: pointer;  
  color: gray; 
}

.text-input {
  flex: 1;
  padding: 8px 8px 8px 40px;  
  padding-right: 40px;  
  border: 1px solid #ccc;  
  border-radius: var(--rounded-m, 8px);
  outline: none;
  box-sizing: border-box;
  transition: border-color 0.3s;
  color: var(--button-loading, #797C84);
  font-family: "Source Sans 3";
  font-size: 16px;
  font-style: normal;
  font-weight: 400; 
}

.text-input.active-border {
  border-color:#1D85DD;
}
.text-input.error-border {
  border-color: #DD6464;
}

.text-input.valid-border {
  border-color: #3C3C3C;
}
</style>
