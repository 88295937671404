<template>
  <div class="custom-dropdown" ref="dropdown">
    <div class="dropdown-container">
      <div class="regular-14 dropdown-label">{{ label }}</div>
      <div class="dropdown-wrapper regular-14" @click="toggleDropdown">
        <div class="dropdown-selected">{{ selectedLabel }}</div>
        <div class="dropdown-arrow">
          <svg width="10.022461" height="5.960052" viewBox="0 0 10.0225 5.96005" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.27 0.29C0.45 0.12 0.68 0.02 0.93 0.02C1.18 0.02 1.42 0.12 1.59 0.29L4.99 3.69L8.39 0.29C8.48 0.2 8.58 0.13 8.7 0.08C8.81 0.02 8.94 0 9.06 0C9.19 -0.01 9.31 0.02 9.43 0.06C9.55 0.11 9.65 0.18 9.74 0.27C9.83 0.36 9.9 0.46 9.95 0.58C10 0.7 10.02 0.82 10.02 0.95C10.02 1.07 9.99 1.2 9.94 1.31C9.89 1.43 9.81 1.53 9.72 1.62L5.66 5.68C5.48 5.86 5.24 5.96 4.99 5.96C4.75 5.96 4.51 5.86 4.33 5.68L0.27 1.62C0.09 1.44 0 1.2 0 0.96C0 0.71 0.09 0.47 0.27 0.29Z" fill="#9A9A9A"/>
          </svg>
        </div>
      </div>
    </div>
    <div v-if="isOpen" class="dropdown-options" :style="{ left: dropdownLeft + 'px' }">
      <div
        class="dropdown-option regular-14"
        v-for="item in items"
        :key="item[itemValue]"
        @click="selectItem(item)"
      >
        {{ item[itemTitle] }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue';

export default {
  props: {
    label: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    itemTitle: {
      type: String,
      default: 'title'
    },
    itemValue: {
      type: String,
      default: 'value'
    },
    modelValue: {
      type: [String, Number],
      default: null
    }
  },
  setup(props, { emit }) {
    const isOpen = ref(false);
    const selected = ref(props.modelValue);
    const dropdown = ref(null);
    const dropdownLeft = ref(0);

    const selectedLabel = computed(() => {
      const selectedItem = props.items.find(item => item[props.itemValue] === selected.value);
      return selectedItem ? selectedItem[props.itemTitle] : 'Select an option';
    });

    const toggleDropdown = () => {
      isOpen.value = !isOpen.value;
      if (isOpen.value) {
        const rect = dropdown.value.getBoundingClientRect();
        dropdownLeft.value = rect.left+94; // Устанавливаем позицию слева
      }
    };

    const selectItem = (item) => {
      selected.value = item[props.itemValue];
      emit('update:modelValue', selected.value);
      emit('change');
      isOpen.value = false;
    };

    watch(() => props.modelValue, (newValue) => {
      selected.value = newValue;
    });

    const handleClickOutside = (event) => {
      if (dropdown.value && !dropdown.value.contains(event.target)) {
        isOpen.value = false;
      }
    };

    onMounted(() => {
      document.addEventListener('click', handleClickOutside);
    });

    onBeforeUnmount(() => {
      document.removeEventListener('click', handleClickOutside);
    });

    return {
      isOpen,
      selected,
      selectedLabel,
      toggleDropdown,
      selectItem,
      dropdown,
      dropdownLeft
    };
  }
};
</script>

<style scoped>
.custom-dropdown {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative; 
  padding: 10px 10px 0px 16px;
}

.dropdown-container {
  display: flex;
  width: 100%;
  align-items: center;
}

.dropdown-label {
  position: static;
  width: 116px;
  height: 20px;
  color: #9A9A9A;
}

.dropdown-wrapper {
  width: 234px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: rgb(30, 30, 30);
  color: #fff;
  padding: 8px;
  cursor: pointer;
}

.dropdown-selected {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.dropdown-arrow {
  margin-left: auto;
}

.dropdown-options {
  width: 234px;
  border-radius: 8px; 
  background: rgb(30, 30, 30);
  position: absolute;
  top: 100%;
  z-index: 10; 
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-option {
  padding: 8px;
  color: #fff;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown-option:hover {
  background-color: #444;
}
</style>
