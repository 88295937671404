<template>
  <CustomCheckbox
    :value="value"
    :modelValue="isChecked"
    @update:modelValue="handleCheckboxChange"
    :label="label"
  />
</template>

<script>
import CustomCheckbox from '../Common/CustomCheckbox.vue';

export default {
  name: 'TableCheckbox',
  components: {
    CustomCheckbox,
  }, 
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    value: {
      type: [Boolean, String, Number],
      required: true
    },
    label: {
      type: String,
      default: '',
    },  
  },
  computed: {
    isChecked() {
      return this.modelValue.includes(this.value);
    } 
  },
  methods: {
    handleCheckboxChange(checked) {
      let newModelValue = [...this.modelValue];
      
      if (checked) { 
        if (!newModelValue.includes(this.value)) {
          newModelValue.push(this.value);
        }
      } else { 
        newModelValue = newModelValue.filter(item => item !== this.value);
      }

      this.$emit('update:modelValue', newModelValue);
    }
  }
};
</script>
