<template>
  <div class="container" :style="{ height: mainSectionHeight }">
    <div
      class="sidebar"
      :style="style"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue';
import { mainSectionHeight } from '@/shared/constants/commonStyles';
const DEEFAULT_SIDEBAR_WIDTH = 240;

const props = defineProps<{ width?: number }>();

const style = {
  height: mainSectionHeight,
  width: props.width ? `width: ${props.width}px` : `width: ${DEEFAULT_SIDEBAR_WIDTH}px`,
};
</script>

<style scoped>
.container {
  position: fixed;
  bottom: 32px;
  left: 10px;
}
.sidebar {
  gap: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

:deep(div) {
  flex-shrink: 0;
}

:deep(div.shrinkable) {
  flex-shrink: 1;
}
</style>