<template>
  <g>
    <g v-if="showStartFenBoard && !item.nodethis">
      <rect
        width="48"
        height="48"
        :id="'block' + item.nodethis"
        fill="url(#pattern0_4474_36384)"
        x="20"
        y="10"
        rx="4"
      />
    </g>
    <g v-else>
      <g
        v-if="
          item.nodethis && (finishedBranches && (!item.children || item.children.length === 0))
        "
      >
        <path
          style="filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.2))"
          :id="'blockEnd' + item.nodethis"
          :d="`M${leftPadding + 96},${getTopPosition + padding} h${
            blockWidth - borderRadius * 2
          } a${borderRadius},${borderRadius} 0 0 1 ${borderRadius},${borderRadius}
          v${
            blockHeight - borderRadius * 2
          } a${borderRadius},${borderRadius} 0 0 1 -${borderRadius},${borderRadius}
          h-${
            blockWidth - borderRadius * 2
          } a${borderRadius},${borderRadius} 0 0 1 -${borderRadius},-${borderRadius}
          v-${
            blockHeight - borderRadius * 2
          } a${borderRadius},${borderRadius} 0 0 1 ${borderRadius},-${borderRadius}
          z`"
          :style="{
            strokeWidth: 1,
            stroke: 'rgb(231,231,231)',
          }"
          fill="#9A9A9A"
        />

        <path
          fill="none"
          :style="1"
          :d="`M${leftPadding},${
            getTopPosition + padding + blockHeight / 2
          } h82`"
          stroke="#9A9A9A"
          marker-end="url(#simpleArrowEnd)"
        />

        <image
          
          :x="leftPadding + 102"
          :y="getTopPosition + padding"
          width="20"
          height="20"
          :xlink:href="finishedBranches && finishedBranches[yIndex] && finishedBranches[yIndex].finished ? 'images/check.svg' : 'images/not-finished.svg'"

        />
      </g>

      <g
        class="tooltip-switcher"
        @mouseenter="handleOverBlock($event)"
        @mouseleave="handleMouseLeave"
        @click="handleBlockClick"
        @contextmenu="handleContextClick"
      >
         <path
          style="filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.2))"
          :id="'block' + item.nodethis"
          :d="`M${leftPadding},${getTopPosition + padding} h${
            blockWidth - borderRadius * 2
          } a${borderRadius},${borderRadius} 0 0 1 ${borderRadius},${borderRadius}
          v${
            blockHeight - borderRadius * 2
          } a${borderRadius},${borderRadius} 0 0 1 -${borderRadius},${borderRadius}
          h-${
            blockWidth - borderRadius * 2
          } a${borderRadius},${borderRadius} 0 0 1 -${borderRadius},-${borderRadius}
          v-${
            blockHeight - borderRadius * 2
          } a${borderRadius},${borderRadius} 0 0 1 ${borderRadius},-${borderRadius}
          z`"
          :class="{
            active_block: isSelected,
            hasOpening: !!item.correspondingMistake,
          }"
          :style="{
            strokeWidth: item.node_thickness,
            stroke: mode === 2 ? 'rgb(231,231,231)' : item.node_bordercolor,
          }"
          :fill="getDropShadow"
        />
        <path
          v-if="!simpleBlock"
          fill="white"
          :d="`M${leftPadding + 4},${getTopPosition + blockHeight / 2 + 2} h${
            36 - 6 * 2
          } a${6},${6} 0 0 1 ${6},${6}
          v${16 - 6 * 2} a${6},${6} 0 0 1 -${6},${6}
          h-${36 - 6 * 2} a${6},${6} 0 0 1 -${6},-${6}
          v-${16 - 6 * 2} a${6},${6} 0 0 1 ${6},-${6}
          z`"
        />
        <text
          v-if="!simpleBlock"
          :x="leftPadding + 16"
          :y="getTopPosition + blockHeight"
       
          fill="#232323"
          class="semibold-14"
          text-anchor="middle"
        >
          {{ getMoveText }}
        </text>

        <text
          style="user-select: none"
          :x="leftPadding + blockWidth / 2 - 8"
          :y="getTopPosition + blockHeight"
            class="semibold-14"
          fill="#232323"
         
        >
          {{ item.ev_text }}
        </text>
        <text
          style="user-select: none"
          v-if="
            (variant === 'training' )
          "
          :x="leftPadding - 2"
          :y="getTopPosition + blockHeight / 2 + 14"
            class="semibold-14"
          fill="#232323"
          
        >
          {{ (!showStartFenBoard && item?.position_id && yIndex !== undefined) ? 'Старт' : getMoveText }}
        </text>
        <text
          style="user-select: none"
          class="semibold-14"
          v-if="
            simpleBlock &&
            variant === 'preAnalysis'
          "
          :x="leftPadding"
          :y="getTopPosition + blockHeight - 2"
         
          fill="#232323"
        
        >
          <!-- {{
            (
              Math.round(item.total_shortage / (item.total_count || 1)) / 100
            ).toFixed(2)
          }} -->
           {{  item.nodethis }}
        </text>
 
      </g>
      <node-toggler
        v-if="item.iscrown === 0 && !simpleBlock"
        :borderRadius="borderRadius"
        :top="getTopPosition + padding + blockHeight / 2"
        :left="leftPadding + blockWidth + padding"
        :status="statusState"
        @click="onClick(item)"
      />
      <plus-five-toggler
        v-if="item.iscrown === 0 && !simpleBlock && !statusState"
        :borderRadius="borderRadius"
        :top="getTopPosition + padding + blockHeight / 2"
        :left="leftPadding + blockWidth + padding"
        :status="statusState"
        @click="onClick(item, false, 5)"
      />
      <sandwich-block
        v-if="!simpleBlock && item.iscrown === 0"
        :item="item"
        :top="
          item.iscrown === 0 && !simpleBlock && !statusState
            ? getTopPosition + 18
            : getTopPosition + padding + blockHeight / 2
        "
        :left="
          item.iscrown === 0 && !simpleBlock && !statusState
            ? leftPadding + blockWidth + padding + 18
            : leftPadding + blockWidth + padding
        "
        @click="handleSandwichClick"
      />
    </g>
  </g>
</template>

<script>
import NodeToggler from './NodeToggler.vue';
import PlusFiveToggler from './PlusFiveToggler.vue';

import SandwichBlock from './SandwichBlock.vue';

const pink = '#BB9BEE';
const blue = '#9EDDF8';
const grey = '#9A9A9A';
export default {
  inject: ['openedNodesArr'],
  props: [
    'userPreferences',
    'isAnchor',
    'item',
    'blockWidth',
    'blockHeight',
    'topPadding',
    'leftPadding',
    'onClick',
    'onContextClick',
    'setTooltip',
    'handleSelectOpening',
    'isSelected',
    'mode',
    'rootColor',
    'simpleBlock',
    'getBg',
    'customPadding',
    'setCoordsToDict',
    'getCoordsFromDict',
    'onDblClick',
    'blockGap',
    'onSandwichClick',
    'showStartFenBoard',
    'finishedBranches',
    'yIndex',
    'variant'
  ],
  data: () => ({
    borderRadius: 8,
    defaulPadding: 10,
    timeoutId: null,
  }),
  components: {
    NodeToggler,
    PlusFiveToggler,
    SandwichBlock,
    // BarChart,
  },
  computed: {
    padding() {
      return this.customPadding || this.defaulPadding;
    },
    getMoveText() {
      if (!this.userPreferences) return '';

      const { lang_pieces } = this.userPreferences;
      const move_lang = this.item?.move_lang || [];
      if (!move_lang) {
        return 'Root';
      }
      if (this.item.hideText === true) return '';
      if (move_lang && move_lang[lang_pieces]) {
        return move_lang[lang_pieces];
      }
      return move_lang[0] || '';
    },
    getEvText() {
      return (key) => this.$t(`path.to.smth.${key}`);
    },
    getDropShadow() {
      const item = this.item;
      if (this.getBg) {
        return this.getBg(item.nodethis, item.hm, item);
      }

      if (!item || !item.showBg) {
        return grey;
      }

      // mlabels
      // если 4 и ход героя - показываем
      // есть 5 и ход поля - показываем
      // если 6 и ход героя- не показываем
      // если 7 и ход поля - не показываем

      // default_training
      // если 0 - не показываем, если 1 - показываем
      const { status, default_training } = item;

      if (status === 1) {
        return grey;
      }

      if (this.rootColor === 'black') {
        if (item.hm % 2 === 0) {
          if (status === 0 || (status === 2 && default_training === 1)) {
            return pink;
          }

          return grey;
        } else {
          if (status === 0 || (status === 2 && default_training === 1)) {
            return blue;
          }

          return grey;
        }
      } else {
        if (item.hm % 2 !== 0) {
          if (status === 0 || (status === 2 && default_training === 1)) {
            return pink;
          }

          return grey;
        } else {
          if (status === 0 || (status === 2 && default_training === 1)) {
            return blue;
          }

          return grey;
        }
      }

      // console.log(item.numdec_hero > 0)

      // const mapper = [
      //   blue,
      //   pink,
      //   'rgba(134, 138, 105, .3)',
      //   'rgba(	33, 230, 79, .2 )',
      // ];

      // return mapper[item.node_backlight] || blue;
    },
    getTopPosition() {
      return this.topPadding;
      // const { topPadding, parentCoords,blockHeight } = this;
      // if(!parentCoords) return topPadding
      // const fixedTopPosition = parentCoords[1] - (blockHeight / 2)
      // return fixedTopPosition > topPadding ? fixedTopPosition : topPadding
      // return parentCoords ? parentCoords[1] - (blockHeight / 2) : topPadding
    },
    statusState() {
      const openedNodesArr = this.openedNodesArr();

      return (
        openedNodesArr.findIndex((i) => i.nodethis === this.item.nodethis) > -1
      );
    },
  },
  methods: {
    handleSandwichClick(e) {
      if (this.onSandwichClick) {
        e.stopPropagation();
        this.onSandwichClick(e, this.item);
      }
    },
    handleOverBlock(event) {
      let { target } = event;
      if (!target.classList.contains('tooltip-switcher')) {
        target = target.closest('.tooltip-switcher');
      }
      const { top, right } = target.getBoundingClientRect();

      this.setTooltip({
        top,
        left: right,
        fen: this.item.fen,
        move_uci: this.item.move_uci,
        arrowColor: this.item.b_arrow_color || 'black',
        tree: true,
        herowhite: this.rootColor === 'black' ? 0 : 1,
        item: this.item,
      });
    },
    handleMouseLeave() {
      this.setTooltip(null);
    },
    handleBlockClick(e) {
      if (!this.timeoutId) {
        this.timeoutId = setTimeout(() => {
          this.handleSelectOpening(this.item, e);
          this.timeoutId = null;
        }, 500); //tolerance in ms
      } else {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
        if (this.onDblClick) {
          this.onDblClick(this.item);
        }
      }
    },
    handleContextClick(e) {
      e.preventDefault();
      if (
        this.onContextClick &&
        this.item?.l_node !== 'Root' &&
        this.item.nodeparent !== this.item.nodethis
      ) {
        this.onContextClick({ item: this.item, e });
      }
      // const heroid = this.$route.params.heroid
      // this.$router.push({
      //   name: "ExercisePage",
      //   params: {
      //     heroid ,
      //     posid: Number(this.item.id),
      //     categories: this.$route.params.categories,
      //     herowhite: 0
      //   },
      // });
    },
  },
  mounted() {
    this.setCoordsToDict({
      nodethis: this.item.nodethis,
      coords: [
        this.leftPadding + this.blockWidth,
        this.getTopPosition + this.blockHeight / 2,
      ],
    });
  },
};
</script>

<style scoped>
.active_block {
  stroke: white !important;
  stroke-dasharray: 4px;
  stroke-dashoffset: 8px;
  animation: stroke 0.2s linear infinite;
}

.hasOpening {
  filter: drop-shadow(3px 5px 2px rgb(184, 30, 30, 1)) !important;
}

@keyframes stroke {
  to {
    stroke-dashoffset: 0;
  }
}
</style>
