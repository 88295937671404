const deepObjectSearch = ({
  target,
  key,
  value,
  parentNodes = [],
  parentKey = null,
  cb,
}) => {
  const defaultCb = (a, b) => a === b;
  let result = [];
  const keys = Object.keys(target);
  
  if (cb) {
    if (cb(target)) {
      result.push({ target, parentKey, parentNodes });
    }
  }
  
  for (let i = 0; i < keys.length; i++) {
    const objectKey = keys[i];
   

    if (target[objectKey] !== null && typeof target[objectKey] === 'object' && objectKey !== 'parentObject') {
      result = result.concat(
        deepObjectSearch({
          target: target[objectKey],
          key,
          value,
          parentNodes: [target, ...parentNodes],
          parentKey: objectKey,
          cb,
        }),
      );
    }

    if (objectKey !== key) continue;
  
    
    {
      if (objectKey === key && defaultCb(target[objectKey], value)) {
        result.push({ target, parentKey, parentNodes });
      }
    }
  }
  return result;
};

export default deepObjectSearch;
