<template>
  <v-container>
    <div v-if="isRegistered">
      <v-card-title class="d-flex justify-center text-h4">
        <div>{{ $t('registerConfirmation.title') }}</div>
      </v-card-title>
      <div class="pt-8">
        <span class="regular-text">{{ $t("registerConfirmation.text") }}</span>
        <span class="hyperlink-text pl-2">
          {{ emailModel }}
        </span>
        <span class="regular-text">
          {{ '.' }}
        </span>
      </div>
      <div class="pt-8">
        <CustomButton
          :label="$t('loginPage.signin')"
          backgroundColor="#1D85DD"
          textColor="white"
          customHeight="52px"
          iconClass="mr-2"
          rounded="medium"
          block
          :disabled="false"
          size="large"
          @click="switchModeToLogin"
        />
      </div>
      <div class="d-flex flex-row justify-center pt-8">
        <div style="text-align: right" class="regular-text">
          {{ $t("registerConfirmation.incorrectEmail") }}
        </div>
        <div
          class="hyperlink-text pl-2"
          style="text-align: left; cursor: pointer"
          @click="()=>isRegistered=false"
        >
          {{ $t("registerConfirmation.backToSign") }}
        </div>
      </div>
      <div class="d-flex flex-row justify-center pt-8">
        <div style="text-align: right" class="regular-text">
          {{ $t("registerConfirmation.noMail") }}
        </div>
        <div
          class="hyperlink-text pl-2"
          style="text-align: left; cursor: pointer"
          @click="resendConfirmation"
        >
          {{ $t("registerConfirmation.resend") }}
        </div>
      </div>
    </div>
    <div v-else>
      <v-card-title class="d-flex justify-center text-h4">
        <div>{{ $t('register.title') }}</div>
      </v-card-title>
      <div v-if="errorText" class="text-red">
        {{ errorText }}
      </div>
      <CustomTextInput
        v-model="nameModel"
        :placeholder="$t('register.enterName')"
        :isValid="isNameValid"
        @validate="debouncedValidateName"
        prependIcon="account"
        height="48px"
        class="mb-2 pt-8"
      />
      <CustomTextInput
        v-model="emailModel"
        :placeholder="$t('register.enterEmail')"
        :isValid="isEmailValid"
        @validate="debouncedValidateEmail"
        prependIcon="at"
        height="48px"
        class="mb-2"
      />
      <CustomTextInput
        v-model="phoneModel"
        :placeholder="$t('register.enterPhone')"
        :isValid="isPhoneValid"
        @validate="validatePhone"
        prependIcon="phone"
        height="48px"
        class="mb-2"
        filter="digits"
      />
      <CustomPasswordField
        v-model="newPasswordModel"
        :placeholder="$t('register.enterPassword')"
        :isValid="isNewPasswordValid"
        @validate="debouncedValidatePassword"
        prependIcon="lock"
        height="48px"
        class="mb-2"
      />
      <CustomPasswordField
        v-model="confirmPasswordModel"
        :placeholder="$t('register.confirmPassword')"
        :isValid="isConfirmPasswordValid"
        @validate="debouncedValidateConfirmPassword"
        prependIcon="lock"
        height="48px"
        class="mb-2"
      />
      <div class="pb-2">
        <div
          v-if="errors.length==0"
          v-html="$t('register.tip')"
          class="checkbox-label"
        ></div>

        <top-three-list
          v-if="errors.length>0"
          :records="errors.map(item=>item.message)"
          :rowsQty="4"
        />
      </div>

      <CheckboxWithLabel
        v-model="isChecked"
        font="Source Sans 3"
        fontSize="12px"
        @check="handleCheck"
        @uncheck="handleUncheck"
      />
      <div class="pt-8">
        <CustomButton
          :label="$t('register.signup')"
          backgroundColor="#1D85DD"
          textColor="white"
          customHeight="52px"
          iconClass="mr-2"
          rounded="medium"
          block
          :disabled="isSubmitButtonDisabled"
          size="large"
          @click="onSubmitRegister"
        />
        <CustomButton
          :label="$t('register.signupGoogle')"
          backgroundColor="#4A4A4A"
          textColor="white"
          customHeight="52px"
          iconClass="mr-2"
          rounded="medium"
          block
          class="text-subtitle-1 my-2"
          googleIcon="true"
          :disabled="false"
          @click="()=>{}"
          size="large"
        />
      </div>
      <div class="d-flex flex-row justify-center pt-8">
        <div style="text-align: right" class="regular-text">
          {{ $t("register.alreadyHaveAccout") }}
        </div>
        <div
          class="hyperlink-text pl-2"
          style="text-align: left; cursor: pointer"
          @click="$emit('switch-to-login')"
        >
          {{ $t("login.signin") }}
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
// import SuccessfullyRegisteredComponent from './SuccessfullyRegisteredComponent.vue';
// import WaitingForConfirmationComponent from './WaitingForConfirmationComponent.vue';
import { mapActions, mapMutations } from "vuex";
import TopThreeList from './TopThreeList.vue';
import { debounce } from 'lodash';
// import googleIcon from '../../assets/googleLogo.vue';
import { requiredRule, emailFormatRule, minLengthRule, phoneTooLongRule,phoneInvalidCharsRule,uppercaseRule,numberRule,matchesRule } from './rules';
import CheckboxWithLabel from './CheckboxWithLabel.vue';
import CustomTextInput from './CustomTextInput.vue';
import CustomPasswordField from './CustomPasswordField.vue';
import CustomButton from './CustomButton.vue';

export default {
   components: {
    TopThreeList,
    CheckboxWithLabel,
    CustomTextInput,
    CustomPasswordField,
    CustomButton,
    // WaitingForConfirmationComponent,
    // SuccessfullyRegisteredComponent,
  },
  data() {
    return {
      isRegistered: false,
      isChecked:false,
      registerForm: false,
      nameModel: '',
      emailModel: '',
      phoneModel: '',
      newPasswordModel: '',
      confirmPasswordModel: '',
      visibleNewPw: false,
      visibleConfPw: false,
      errorText: '',
      errors: [],
      isEmailValid:false,
      isNameValid:false,
      isPhoneValid:false,
      isNewPasswordValid:false,
      isConfirmPasswordValid:false,
    };
  },
  computed: {
    isSubmitButtonDisabled() {
      return (!this.isEmailValid || !this.isNameValid || !this.isPhoneValid || !this.isNewPasswordValid|| !this.isConfirmPasswordValid || !this.isChecked);
    },
     nameRules() {
       return [ value => requiredRule(this.$t)(value, 'name'),];
     },
     emailRules() {
      return [
        value => requiredRule(this.$t)(value, 'email'),
        value => emailFormatRule(this.$t)(value, 'email'),
        value => minLengthRule(this.$t)(value, 'email',4),
      ];
    },
    passwordRules() {
      return [
        value => requiredRule(this.$t)(value, 'password'),
        value => minLengthRule(this.$t)(value, 'password', 8),
        value => uppercaseRule(this.$t)(value, 'password'),
        value => numberRule(this.$t)(value, 'password'),
      ];
    },
    confirmPasswordRules(){
        return[
           value => matchesRule(this.$t)(value, this.newPasswordModel),
        ]
    },
    phoneRules(){
        // Check if the phone number is not empty
        if (this.phoneModel.trim() === '') {
           return []; // No rules if the phone number is empty
        }
       return [
        value => minLengthRule(this.$t)(value, 'phone', 4),
        value => phoneTooLongRule(this.$t)(value,15),
        value => phoneInvalidCharsRule(this.$t)(value),
        // value => minLengthRule(value, 'password'),
        // value => uppercaseRule(value, 'password'),
        // value => numberRule(value, 'password'),
      ];
    }
  },
  methods: {
    ...mapActions("ui", ["registerUser"]),
    ...mapMutations(['SET_DATA_BY_KEY']),
    switchModeToLogin(){
     this.$emit('switch-to-login')
    },
    resendConfirmation() {
      // handle resend confirmation logic
      console.log('Letter sent to ',this.emailModel)
    },
    async onSubmitRegister() {
      this.isRegistered = true;
      const regResponse = {data:{access_token:true}}
      await this.registerUser({
        name: this.nameModel,
        email: this.emailModel,
        password: this.newPasswordModel,
        language: this.$i18n.locale
      });
      if(regResponse && regResponse.data?.access_token) {
        this.isRegistered = true;
        localStorage.setItem('token', regResponse.data.access_token)
        this.SET_DATA_BY_KEY({key: 'isAuthenticated', value: true})
        this.$router.push("/")
      } else {
        this.errors.push({ field: 'email', message: regResponse?.errorMessage })
        this.isRegistered = false; // reset loading on error
      }
    },
    handleCheck () {
    this.isChecked = true
    },

    handleUncheck () {
    this.isChecked = false
    },
    validateEmail() {
      if (!this.$t) return ;
      this.errors = this.errors.filter(error => error.field !== 'email');
      let isValid = true;
      const checkEmpty = requiredRule(this.$t)(this.emailModel, 'email')
      if ( checkEmpty== true ){
            this.emailRules.forEach(rule => {
                const result = rule(this.emailModel);
                if (result !== true) {
                this.errors.push({ field: 'email', message: result});
                isValid = false;
                }
            });
      } else {
         this.errors.push({ field: 'email', message: checkEmpty});
          isValid = false;
      }

      this.isEmailValid = isValid;
    },
    validateName() {
      if (!this.$t) return ;
      this.errors = this.errors.filter(error => error.field !== 'name');
      let isValid = true;
      const checkEmpty = requiredRule(this.$t)(this.nameModel, 'name')
      if ( checkEmpty== true ){
            this.nameRules.forEach(rule => {
                const result = rule(this.nameModel);
                if (result !== true) {
                this.errors.push({ field: 'name', message: result});
                isValid = false;
                }
            });
      } else {
         this.errors.push({ field: 'name', message: checkEmpty});
          isValid = false;
      }

      this.isNameValid = isValid;
    },
    validatePhone() {
      if (!this.$t) return ;
      this.errors = this.errors.filter(error => error.field !== 'phone');
      let isValid = true;

      this.phoneRules.forEach(rule => {
        const result = rule(this.phoneModel);
        if (result !== true) {
            this.errors.push({ field: 'phone', message: result});
            isValid = false;
        }
      });

      this.isPhoneValid = isValid;
    },
    validatePassword() {
      if (!this.$t) return ;
      this.errors = this.errors.filter(error => error.field !== 'password');
      let isValid = true;
      const checkEmpty = requiredRule(this.$t)(this.newPasswordModel, 'password')
      if ( checkEmpty== true ){
            this.passwordRules.forEach(rule => {
                const result = rule(this.newPasswordModel);
                if (result !== true) {
                this.errors.push({ field: 'password', message: result});
                isValid = false;
                }
            });
      } else {
         this.errors.push({ field: 'password', message: checkEmpty });
         isValid = false;
      }

      this.isNewPasswordValid = isValid;
    },
    validateConfirmPassword() {
      if (!this.$t) return ;
      this.errors = this.errors.filter(error => error.field !== 'cofirmPassword');
      let isValid = true;
      if (this.newPasswordModel && !this.confirmPasswordModel){
         this.errors.push({ field: 'cofirmPassword', message: this.$t('rules.confirmpwd') })
         isValid = false;

      }  else {
        this.confirmPasswordRules.forEach(rule => {
            const result = rule(this.confirmPasswordModel);
                if (result !== true) {
            this.errors.push({ field: 'cofirmPassword', message: result });
            isValid = false;
         }
         });
      }

       this.isConfirmPasswordValid = isValid;
    },

    debouncedValidateEmail: debounce(function() {
      this.validateEmail();
    }, 300),
    debouncedValidateName: debounce(function() {
      this.validateName();
    }, 300),
    debouncedValidatePhone: debounce(function() {
      this.validatePhone();
    }, 300),
    debouncedValidatePassword: debounce(function() {
      this.validatePassword();
    }, 300),
    debouncedValidateConfirmPassword: debounce(function() {
      this.validateConfirmPassword();
    }, 300),
  }
};
</script>

<style scoped>
.clickable-link {
  color: white;
  text-decoration: underline;
}

.clickable-link:hover {
  color: #dcdcdc;
}

.checkbox-label {
  color: var(--text-secondary, #9a9a9a);
  height: 64px;
  font-family: "Source Sans 3";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.hyperlink-text {
  color: var(--text-primary, #f4f4f4);
  font-family: "Source Sans 3";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.regular-text {
  color: var(--text-secondary, #9a9a9a);
  font-family: "Source Sans 3";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
</style>
