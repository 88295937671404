<!-- CustomTextInput.vue -->
<template>
  <div class="custom-text-field">
    <div class="input-container"> 
      <custom-icon v-if="prependIcon" class="icon prepend-icon" :id="prependIcon" />
      <input
        :type="type"
        :placeholder="placeholder"
        :value="localValue"
        v-model="localValue"
        @input="onInput"
        @blur="onBlur"
        @focus="onFocus"
        :style="{ height: height }"
        :class="[
          'text-input',
          { 'active-border': isActive, 'error-border': (!isValid && !isActive), 'valid-border ': (isValid && !isActive) || isUnclicked }
        ]"
      /> 
       <custom-icon  v-if="isValid && localValue!='' && !isUnclicked" class="icon append-icon" id="valid" />  
    </div>
  </div>
</template>

<script>
import DOMPurify from 'dompurify';
import CustomIcon from '../../assets/CustomIcon.vue'
export default {
  components:{
    CustomIcon
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    prependIcon: {
      type: String,
      default: '',
    },
    appendIcon: {
      type: String,
      default: 'mdi-check',
    },
    type: {
      type: String,
      default: 'text',
    },
    height: {
      type: String,
      default: 'auto',
    },
    // rules: {
    //   type: Array,
    //   default: () => [],
    // },
    isValid: {   
      type: Boolean,
      default: true,
    },
    filter: {
    type: String,
    default: 'none',  
    },
  },
  data() {
    return {
      isActive: false,
      isUnclicked: true,
      localValue: this.modelValue,
    };
  },
  methods: { 
    // sanitizeInput(value) { 
    //   return value.replace(/<script[^>]*?>.*?<\/script>|<link[^>]*?>|<a[^>]*>.*?<\/a>/gi, '');
    // },
    sanitizeInput(value) {
      return DOMPurify.sanitize(value, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] });
    },
    applyFilter(value) {
            switch (this.filter) {
        case 'digits':
          return value.replace(/\D/g, ''); // Keep only digits
        case 'letters':
          return value.replace(/[^a-zA-Z]/g, ''); // Keep only letters
        case 'alphanumeric':
          return value.replace(/[^a-zA-Z0-9]/g, ''); // Keep only alphanumeric characters
        case 'password':
          // Password filter: Allow letters, digits, and specific special characters
        return value.replace(/[^a-zA-Z0-9!@#$%^&*()_+{}[\]:;"'<>,.?/~`\\|=-]/g, '');;
        default:
          return value; // No filtering
      }
    },
    onInput(event) {
        // eslint-disable-next-line
      let newValue = this.sanitizeInput(event.target.value.replace(/[\u0000-\u001F\u007F-\u009F]/g, "")) 
      newValue=this.applyFilter(newValue) ;
     
      this.$emit('update:modelValue', newValue);
      this.$emit('validate', newValue);  
      this.localValue = newValue;
    },
    onBlur() {
      this.isActive = false;
      this.$emit('validate', this.modelValue);  
    },
    onFocus() {
      this.isUnclicked = false,
      this.isActive = true;
    },
  },
};
</script>

<style scoped>
.custom-text-field {
  display: flex;
  flex-direction: column;
}
.input-container {
  position: relative;
  display: flex;
  align-items: center;
}
.icon {
  position: absolute;
  pointer-events: none;  
}
.prepend-icon {
  left: 10px;
  color: #797C84; 
}
.append-icon {
  right: 10px;
  color: #06D295; 
}

.text-input {
  flex: 1;
  padding: 8px 8px 8px 40px; 
  padding-right: 30px;  
  border: 1px solid #ccc;
  border-radius: var(--rounded-m, 8px);
  outline: none;
  box-sizing: border-box;
  transition: border-color 0.3s;
  color: var(--button-loading, #797C84);
  font-family: "Source Sans 3";
  font-size: 16px;
  font-style: normal;
  font-weight: 400; 
}
.text-input.active-border {
  border-color:#1D85DD;
}
.text-input.error-border {
  border-color: #DD6464;
}

.text-input.valid-border {
  border-color: #3C3C3C;
}

</style>
