<template>
  <div class="custom-slider">
    <div class="slider-container">
      <label class="regular-14 slider-label">{{ label }}</label>
      <input
        type="number"
        v-model.number="minValue"
        @input="updateMin"
        class="slider-input regular-14"
      />
      <div class="slider-track" @click="updateRange($event)" ref="track">
        <div class="slider-range" :style="normalRangeStyle"></div>
        <div
          class="left-handle"
          :style="minHandleStyle"
          @mousedown.prevent="startDrag('min')"
        ></div>
        <div
          class="right-handle"
          :style="maxHandleStyle"
          @mousedown.prevent="startDrag('max')"
        ></div>
      </div>
      <input
        type="text"
        v-model="modelMax"
        @input="updateMaxFromInput"
        class="slider-input regular-14"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      default: Infinity,
    },
    value: {
      type: Array,
      required: true,
    },
    steps: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: 'Range',
    },
  },
  data() {
    return {
      minValue: this.value[0],
      maxValue: this.value[1],
      dragging: null,
      trackWidth: 100, // Ширина слайдера в пикселях
      handleWidth: 16,  // Ширина ручки
      trackPadding: 8,  // Отступы по краям
      modelMax: this.value[1] === Infinity ? '∞' : this.value[1],
    };
  },
  computed: {
    minIndex() {
      return this.steps.indexOf(this.minValue);
    },
    maxIndex() {
      return this.maxValue === Infinity ? this.steps.length - 1 : this.steps.indexOf(this.maxValue);
    },
    minHandleStyle() {
      return {
        left: `${(this.minIndex / (this.steps.length - 1)) * (this.trackWidth - this.handleWidth) }px`,
      };
    },
    maxHandleStyle() {
      return {
        left: `${(this.maxIndex / (this.steps.length - 1)) * (this.trackWidth - this.handleWidth) + this.trackPadding}px`,
      };
    },
    normalRangeStyle() {
      return {
        width: `${((this.maxIndex - this.minIndex) / (this.steps.length - 1)) * (this.trackWidth - this.handleWidth) + this.trackPadding * 2}px`,
        left: `${(this.minIndex / (this.steps.length - 1)) * (this.trackWidth - this.handleWidth) + this.trackPadding}px`,
      };
    },
  },
  methods: {
    updateMin() {
      const index = this.steps.indexOf(this.minValue);
      if (index === -1) {
        const closestStep = this.steps.reduce((prev, curr) => {
          return (Math.abs(curr - this.minValue) < Math.abs(prev - this.minValue) ? curr : prev);
        });
        this.minValue = closestStep;
      }
      this.emitValues();
    },
    updateMax() {
      const index = this.steps.indexOf(this.maxValue);
      if (index === -1) {
        const closestStep = this.steps.reduce((prev, curr) => {
          return (Math.abs(curr - this.maxValue) < Math.abs(prev - this.maxValue) ? curr : prev);
        });
        this.maxValue = closestStep;
      }
      this.emitValues();
    },
    updateMaxFromInput() {
      if (this.modelMax === '∞') {
        this.maxValue = Infinity;
      } else {
        this.maxValue = parseFloat(this.modelMax);
      }
      this.updateMax();
    },
    emitValues() {
        this.$emit('input', [this.minValue, this.maxValue]);
        this.modelMax = this.maxValue === Infinity ? '∞' : this.maxValue;
    },
    updateRange(event) {
      const rect = this.$refs.track.getBoundingClientRect();
      const offsetX = event.clientX - rect.left - this.trackPadding;
      const percentage = offsetX / (rect.width - this.trackPadding * 2);
      const newValueIndex = Math.round(percentage * (this.steps.length - 1));

      if (this.dragging === 'min') {
        if (newValueIndex < this.maxIndex) {
          this.minValue = this.steps[Math.max(0, newValueIndex)];
          this.updateMin();
        }
      } else if (this.dragging === 'max') {
        if (newValueIndex > this.minIndex) {
          this.maxValue = newValueIndex === this.steps.length - 1 ? Infinity : this.steps[Math.min(newValueIndex, this.steps.length - 1)];
          this.updateMax();
        }
      }

      // Синхронизация значений при перетаскивании
      if (this.minValue >= this.maxValue) {
        this.maxValue = this.minValue;
      }
    },
    startDrag(type) {
      this.dragging = type;
      window.addEventListener('mousemove', this.onDrag);
      window.addEventListener('mouseup', this.stopDrag);
    },
    onDrag(event) {
      this.updateRange(event);
    },
    stopDrag() {
      this.dragging = null;
      window.removeEventListener('mousemove', this.onDrag);
      window.removeEventListener('mouseup', this.stopDrag);
    },
  },
  mounted() {
    this.emitValues();
  },
};
</script>

<style scoped>
.custom-slider {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 16px 16px;
}

.slider-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.slider-label {
  position: static;
  width: 124px;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #9A9A9A;
}

.slider-track {
  position: relative;
  height: 28px;
  flex: 1;
  margin: 0 4px; 
  cursor: pointer;
  border-radius: 8px;
  background: rgb(74, 74, 74);
}

.slider-range {
  position: absolute;
  height: 28px;
  border-radius: 8px;
  background: rgb(60, 60, 60);
}

.left-handle,
.right-handle {
  position: absolute;
  width: 16px;
  height: 28px;
  border-radius: 8px;
  background: rgb(82, 84, 90);
  cursor: pointer;
  top: 0;
  box-shadow: 0px 1px 2px 0px rgba(116, 5, 5, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}

.slider-input {
  width: 40px;
  height: 28px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 28px;
  box-sizing: border-box;
  border: 1px solid rgb(60, 60, 60);
  border-radius: 8px;
  background: rgb(30, 30, 30);
  color: rgb(121, 124, 132);
  -moz-appearance: textfield;
}

.slider-input::-webkit-inner-spin-button,
.slider-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
