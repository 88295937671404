<template>
  <v-menu :close-on-content-click="false" v-model="menu">
    <template v-slot:activator="{ props }">
      <div v-bind="props" class="d-flex align-center">
        <div v-bind="props" class="d-flex align-center">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.60386 3.59776C8.95919 2.13408 11.0408 2.13408 11.3961 3.59776C11.6257 4.54327 12.709 4.99198 13.5398 4.48571C14.8261 3.70199 16.298 5.17392 15.5143 6.46015C15.008 7.29105 15.4567 8.37431 16.4022 8.60386C17.8659 8.95919 17.8659 11.0408 16.4022 11.3961C15.4567 11.6257 15.008 12.709 15.5143 13.5398C16.298 14.8261 14.8261 16.298 13.5398 15.5143C12.709 15.008 11.6257 15.4567 11.3961 16.4022C11.0408 17.8659 8.95919 17.8659 8.60386 16.4022C8.37431 15.4567 7.29105 15.008 6.46016 15.5143C5.17392 16.298 3.70199 14.8261 4.48571 13.5398C4.99198 12.709 4.54327 11.6257 3.59776 11.3961C2.13408 11.0408 2.13408 8.95919 3.59776 8.60386C4.54327 8.37431 4.99198 7.29105 4.48571 6.46015C3.70199 5.17392 5.17392 3.70199 6.46015 4.48571C7.29105 4.99198 8.37431 4.54327 8.60386 3.59776Z"
              stroke="#9A9A9A"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12.5 10C12.5 11.3807 11.3807 12.5 10 12.5C8.61929 12.5 7.5 11.3807 7.5 10C7.5 8.61929 8.61929 7.5 10 7.5C11.3807 7.5 12.5 8.61929 12.5 10Z"
              stroke="#9A9A9A"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <div class="ml-2 semibold-14">{{ $t('accountMenu.preferences') }}</div>

          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.84978 8.51667C6.96696 8.39963 7.12582 8.33389 7.29144 8.33389C7.45707 8.33389 7.61592 8.39963 7.73311 8.51667L9.99978 10.7833L12.2664 8.51667C12.3237 8.45527 12.3927 8.40601 12.4693 8.37185C12.546 8.33769 12.6288 8.31933 12.7127 8.31785C12.7966 8.31637 12.88 8.3318 12.9578 8.36324C13.0356 8.39467 13.1063 8.44146 13.1656 8.50081C13.225 8.56016 13.2718 8.63085 13.3032 8.70867C13.3346 8.7865 13.3501 8.86986 13.3486 8.95377C13.3471 9.03769 13.3288 9.12045 13.2946 9.19712C13.2604 9.27379 13.2112 9.34279 13.1498 9.40001L10.4414 12.1083C10.3243 12.2254 10.1654 12.2911 9.99978 12.2911C9.83415 12.2911 9.6753 12.2254 9.55811 12.1083L6.84978 9.40001C6.73273 9.28282 6.66699 9.12396 6.66699 8.95834C6.66699 8.79271 6.73273 8.63386 6.84978 8.51667Z"
              fill="#9A9A9A"
            />
          </svg>
        </div>
      </div>
    </template>
    <v-card class="regular-14">
      <v-card-text>
        <div v-if="localModel">
          <div style="width: 300px">
            <div class="regular-14 my-2">
              {{ $t('preferences.piecesDisplayedAs') }}
            </div>
            <v-select
              v-model="localModel.lang_pieces"
              :items="moveOptions"
              item-title="title"
              :rules="[required]"
              variant="outlined"
              item-value="id"
              hide-details
              density="compact"
              style="width: 122px; text-wrap: nowrap; text-overflow: ellipsys"
            >
              <template v-slot:selection="{ item }">
                <span>
                  {{ item.title.replaceAll(' ', '').substring(0, 4) }}</span
                >
              </template>
            </v-select>
          </div>

          <div style="width: 300px">
            <div class="regular-14  mb-2 mt-4">Граница хороших ходов</div>
            <v-text-field
              type="number"
              v-model="localModel.showmove_deviation"
              variant="outlined"
              hide-details
              density="compact"
              style="width: 122px"
            />
          </div>

          <div style="width: 300px">
            <div class="regular-14  mb-2 mt-4">Чувствительность к ошибкам</div>
            <v-text-field
              type="number"
              v-model="localModel.mistakes_deviation"
              variant="outlined"
              hide-details
              density="compact"
              style="width: 122px"
            />
          </div>

          <div style="width: 300px">
            <div class="d-flex regular-14 mb-2 mt-4">
              <div style="width: 50px">
                <v-switch
                  v-model="flip_board_black_model"
                  :rules="[]"
                  variant="outlined"
                  color="blue"
                  class="regular-14"
                />
              </div>
              <div style="width: 140px">{{$t('preferences.flipWhenBlack')}}</div>
            </div>
          </div>
        </div>
      </v-card-text>
      <div
        class="d-flex align-center justify-center mb-4 ml-2"
        style="gap: 1em"
      >
        <v-btn @click="menu = false">{{ $t('common.cancel') }}</v-btn>
        <v-btn color="blue" :disabled="!localModel" @click="handleSave">{{
          $t('common.ok')
        }}</v-btn>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import i18n from '@/i18n';
export default {
  emits: ['close'],
  data: () => ({
    localModel: null,
    menu: false,
  }),
  computed: {
    flip_board_black_model: {
      get() {
        return this.localModel.flip_board_black === 1;
      },
      set(val) {
        this.localModel.flip_board_black = val ? 1 : 0;
      },
    },
    use_short_notation_model: {
      get() {
        return this.localModel.use_short_notation === 1;
      },
      set(val) {
        this.localModel.use_short_notation = val ? 1 : 0;
      },
    },
    moveOptions() {
      // const locale = this.$i18n.locale - has to commit actual lang_interface
      // const lang_interface = this.localModel.lang_interface

      const allOptions = [
        {
          id: 0,
          title: '♘♗♖♕♔♞♝♜♛♚',
        },
        {
          id: 1,
          title: 'N B R Q K',
        },

        {
          id: 2,
          title: 'S L T D K',
        },
        {
          id: 3,
          title: 'C F T D R',
        },

        {
          id: 4,
          title: 'К С Л Ф Кр',
        },
        {
          id: 5,
          title: '马象车后王',
        },

        {
          id: 6,
          title: 'न ब र क्यू क',
        },
        {
          id: 7,
          title: 'C A T D R',
        },
        {
          id: 8,
          title: 'C B T D R',
        },
        {
          id: 9,
          title: 'К С Т Ф Кр',
        },
      ];

      return allOptions;
      //  const locale = i18n?.global?.locale

      //   const mainOptions = [
      //     {
      //       id: 0,
      //       title: '♘♗♖♕♔♞♝♜♛♚',
      //     },
      //     {
      //       id: 1,
      //       title: 'N B R Q K',
      //     },
      //   ];

      //   if(locale === 'en') {
      //     return mainOptions
      //   }
      //   const appendix = [
      //     {
      //       id: 2, //if this number saves to preferences directly, then it has to set lang_interface, but not "2"
      //       title: this.$t('preferences.langPiecesNational'),
      //     },
      //   ];

      //   return [...mainOptions, ...appendix];
    },
    depthfull: {
      get() {
        return localStorage.getItem('depthfull') || 3;
      },
      set(val) {
        localStorage.setItem('depthfull', val);
      },
    },
    depthfilter: {
      get() {
        return localStorage.getItem('depthfilter') || 11;
      },
      set(val) {
        localStorage.setItem('depthfilter', val);
      },
    },
  },
  methods: {
    ...mapActions('ui', ['setUserPreferences']),
    handleSave() {
      this.setUserPreferences(this.localModel);
      this.menu = false;
    },
  },
  mounted() {
    this.localModel = cloneDeep(this.$store.state.ui.userPreferences);
  },
};
</script>

<style scoped></style>
