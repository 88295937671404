const DB_NAME = 'test',
  DB_VERSION = 1;

export const getDb = async () => {
  return new Promise((resolve, reject) => {

    if(localStorage.getItem("useIDB") == 1) {
      let request = window.indexedDB.open(DB_NAME, DB_VERSION);

      request.onerror = (e) => {
        console.log('Error opening db', e);
        reject('Error');
      };
  
      request.onsuccess = (e) => {
        resolve(e.target.result);
      };
  
      request.onupgradeneeded = (e) => {
        console.log('onupgradeneeded');
        let db = e.target.result;
        let objectStore = db.createObjectStore('tree', {
          keyPath: 'position_id',
        });
  
        objectStore.createIndex('position_id','position_id',{unique: true})
      };
    } 
  
  });
};
