<template>
  <v-card>
    <v-card-title>{{
      isOld ? $t('cardSummary.editAccount') : $t('cardSummary.addAccount')
    }}</v-card-title>
    <v-card-text>
      <div class="d-flex align-center" style="gap: 1em">
        <div style="flex: 1">
          <v-text-field
            v-model="nickNameModel"
            :rules="[required]"
            :label="$t('cardSummary.name')"
            variant="outlined"
          />
        </div>
        <div style="flex: 1">
          <v-select
            variant="outlined"
            :label="$t('playground.site')"
            :items="playgroundList"
            item-title="title"
            item-value="playground_id"
            v-model="siteModel"
            :readonly="isOld"
          />
        </div>
      </div>
    </v-card-text>
    <v-card-text
      v-if="errorMessage"
      style="color: red"
      >{{errorMessage}}</v-card-text
    >

    <div class="d-flex align-center justify-center mb-4 ml-2" style="gap: 1em">
      <v-btn @click="$emit('close')">{{ $t('common.cancel') }}</v-btn>
      <v-btn color="blue" :disabled="!nickNameModel" @click="handleAdd">{{
        $t('common.ok')
      }}</v-btn>
    </div>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import axios from '@/plugins/axiosWrapper';

export default {
  emits: ['close'],
  props: ['heroId', 'editingNickname'],
  data: () => ({
    nickNameModel: '',
    siteModel: 3,
    errorMessage: ''
  }),
  computed: {
    ...mapState('data', ['playgroundList','heroNicknamesRaw']),
    isOld() {
      const editingNickname = this.editingNickname;
      if (!editingNickname) return false;
      return editingNickname.nickname_id ? true : false;
    },
  },
  methods: {
    ...mapActions('data', ['addHeroNickName','unzipNickname','reassignNickname']),
    required(v) {
      return !!v || this.$t('common.fieldRequired');
    },
    async handleAdd() {
      const { nickNameModel, siteModel, heroId } = this;
      const existingNickNames = this.heroNicknamesRaw.filter(item=>(item.nick===nickNameModel && item.playground ===siteModel && item.shown ===0))

      if (existingNickNames.length>0){
        await this.unzipNickname(existingNickNames[0]);
        if(existingNickNames[0].hero_id != heroId) {
          this.reassignNickname({nickid:existingNickNames[0].nickname_id,hid:heroId })
        }
        this.$emit('close');
      }
      else {
            const nameCheckMapper = {
            3: async () => {
              const resp = await axios({
                method: 'get',
                url: `https://lichess.org/api/user/${nickNameModel
                  .trimStart()
                  .trimEnd()}`,
              }).catch((e) => {
                return { errorMessage: e };
              });
              if (resp && resp.data?.username) {
                return { fixedName: resp.data.username };
              }
              return { errorMessage: 'Nickname not found' };
            },
            4: async () => {
              nickNameModel.trimStart().trimEnd();
              const resp = await axios({
                method: 'get',
                url: `https://api.chess.com/pub/player/${nickNameModel}`,
              });

              if (resp?.data?.url) {
                const url = resp.data.url;
                let res = nickNameModel.trimStart().trimEnd();
                try {
                  const spilitted = url.split('/');
                  return { fixedName: spilitted.pop() };
                } catch (e) {
                  return { errorMessage: e };
                }
              } else if(resp.errorMessage) {
              return { errorMessage: resp.errorMessage };

              }
              return { errorMessage: 'Nickname not found' };
            },
            2: () => ({ fixedName: nickNameModel.trimStart().trimEnd() }),
          };
          let correctNickName = nickNameModel.trimStart().trimEnd();
          if (nameCheckMapper[siteModel]) {
            this.errorMessage = ''

            const r = await nameCheckMapper[siteModel]();
            if (r.fixedName) {
              correctNickName = r.fixedName;
            }

            if (!r.errorMessage) {
              const addResp = await this.addHeroNickName({
                nickNameModel: correctNickName,
                siteModel,
                heroId,
              });

              if(addResp?.errorMessage) { 
                this.errorMessage = addResp.errorMessage
              } else {
              this.$emit('close');

              }


            } else {
              // console.log(r.errorMessage);
              this.errorMessage = r.errorMessage
            }
          }
        }

    },
  },
  watch: {
    editingNickname: {
      immediate: true,
      deep: true,
      handler: function (val) {
        if (!val || !val.nickname_id) {
          this.nickNameModel = '';
          this.siteModel = 3;
        } else {
          this.nickNameModel = val.nick;
          this.siteModel =
            this.playgroundList.find((pg) => pg.title === val.playground)
              ?.playground_id || 3;
        }
      },
    },
  },
};
</script>

<style scoped></style>
