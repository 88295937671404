<template>
  <v-card class="rounded-card" elevation="2" style="overflow: visible">
    <div style="height: 32px; margin-bottom: 50px; margin-top: 24px" class="px-4 pt-4 d-flex align-center">
      <div class="semibold-24">{{ $t('opening1.filters') }}</div>
      <v-spacer/>
      <div class="semibold-14">
        <v-btn
          color="blue"
          @click="applyFilters"
          :disabled="!filtersChanged"
          size="small"
        >
          {{ $t('common.apply') }}
        </v-btn>
      </div>
    </div>
  
    <v-divider class="mb-1" />
    <div>
      <div>
        <ButtonSelector
          :label="$t('opening1.side')"
          :options="sideOptions"
          v-model="localSideModel"
          @update:filtersChanged="updateFiltersChanged"
        />
      </div>
      <div>
        <div v-if="getMistakesMinMax && getMistakesMinMax.length > 0">
          <CustomSliderSteps
            :label="$t('opening1.moveNum')"
            :value="[minModel, maxModel]"
            @input="updateRange"
            @change="updateFiltersChanged"
            :steps="getSteps(getMistakesMinMax[0], getMistakesMinMax[1], 1)"
          />
        </div>
      </div>
      <div>
        <div v-if="getMistakesMinMax && getMistakesMinMax.length > 0">
          <CustomSliderSteps
            :label="$t('opening1.mistakesdEvalLong')"
            :value="[minDeltaModel, maxDeltaModel]"
            @input="updateDelta"
            @change="updateFiltersChanged"
            :steps="steps"
          />
        </div>
      </div>

      <div class="pa-2">
        <CustomDropdown
          v-if="extendedOpeningsVariants && extendedOpeningsVariants.length > 1"
          :label="$t('opening1.opening')"
          v-model="localOpeningModel"
          :items="extendedOpeningsVariants"
          item-title="text"
          item-value="id"
          @change="updateFiltersChanged"
        />
      </div>

      <v-divider class="mb-1" />

      <!-- <div>
        <ButtonSelector
          :label="'td Избранное'"
          :options="favOptions"
          v-model="favModel"
          @update:filtersChanged="updateFiltersChanged"
        />
      </div> -->
      <div class="pa-2">
        <CustomDropdown
          :label="$t('opening1.mistakesStudiedStatus')"
          v-model="positionStatusModel"
          :items="positionStatusVariants"
          item-title="text"
          item-value="id"
          @change="updateFiltersChanged"
        />
      </div>

      <v-divider />
    </div>
    <div>
    <slot name="table"/>

    </div>
  </v-card>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import ButtonSelector from '../components/Common/ButtonSelector.vue';
import CustomSliderSteps from './Common/CustomSliderSteps.vue';
// import CustomSlider from './Common/CustomSlider.vue';
import CustomDropdown from '../components/Common/CustomDropdown.vue';

export default {
  data() {
    return {
      filtersChanged: false,
      localSideModel: null,
      localStudiedModel: null,
      localWatchlistModel: null,
      localOpeningModel: null,
      localSortByModel: null,
      favModel: null,
      positionStatusModel: null,
      minModel: 0,
      maxModel: 0,
      minDeltaModel: 0,
      maxDeltaModel: 0,
      steps: [0.1, 0.3, 0.6, 1.2, 2.4, 4.8, 6, Infinity],
    };
  },
  components: {
    ButtonSelector,
    CustomSliderSteps,
    // CustomSlider,
    CustomDropdown,
  },
  computed: {
    ...mapGetters('data', ['getOpeningsVariants', 'getMistakesMinMax']),
    ...mapState('data', [
      'sideOptionsFilter',
      'studiedOptionsFilter',
      'watchListOptionsFilter',
      'openingOptionsFilter',
      'sortByOptionsFilter',
      'favOptionsFilter',
      'hmRange',
      'positionStatusFilter',
      'deltaRange',
    ]),
    extendedOpeningsVariants() {
      return this.getOpeningsVariants(this.$t);
    },
    positionStatusVariants() {
      return [
        { id: 1, text: this.$t('opening1.mistakesNotStudied') },
        { id: 2, text: this.$t('opening1.mistakesStudied') },
        { id: 3, text: this.$t('opening1.mistakesReFail') },
      ];
    },
    favOptions() {
      return [
        { id: 1, text: this.$t('common.allCapital') },
        { id: 2, text: this.$t('opening1.watchlistListed') },
      ];
    },
    sortByOptions() {
      return [
        { id: 1, text: this.$t('opening1.sortByTotalShortage') },
        { id: 2, text: this.$t('opening1.sortByAverageShortage') },
        { id: 3, text: this.$t('opening1.sortByNumMistakes') },
        { id: 4, text: this.$t('opening1.sortByMoveDesc') },
        { id: 5, text: this.$t('opening1.sortByMoveAsc') },
      ];
    },
    sideOptions() {
      return [
        { id: 2, text: this.$t('opening1.sideWhite') },
        { id: 3, text: this.$t('opening1.sideBlack') },
      ];
    },

    studiedOptions() {
      return [
        { id: 1, text: this.$t('common.allCapital') },
        { id: 2, text: this.$t('opening1.studiedYes') },
        { id: 3, text: this.$t('opening1.studiedNo') },
      ];
    },
    watchListOptions() {
      return [
        { id: 1, text: this.$t('common.allCapital') },
        { id: 2, text: this.$t('opening1.watchlistListed') },
      ];
    },
  },
  watch: {
    getMistakesMinMax: {
      deep: true,
      handler(val) {
        this.SET_DATA_BY_KEY({ key: 'hmRange', value: val });
        this.minModel = val[0];
        this.maxModel = val[1];
      },
      immediate: true,
    },
  },
  created() {
    this.localSideModel = this.sideOptionsFilter;
    this.localStudiedModel = this.studiedOptionsFilter;
    this.localWatchlistModel = this.watchListOptionsFilter;
    this.localOpeningModel = this.openingOptionsFilter;
    this.localSortByModel = this.sortByOptionsFilter;
    this.favModel = this.favOptionsFilter;
    this.filtersChanged = false;
    this.minModel = this.hmRange[0];
    this.maxModel = this.hmRange[1];
    this.positionStatusModel = this.positionStatusFilter;
    this.minDeltaModel = this.deltaRange[0];
    this.maxDeltaModel = this.deltaRange[1];
  },
  methods: {
    ...mapMutations('data', ['SET_DATA_BY_KEY']),
    applyFilters() {
      const key = [
        'sideOptionsFilter',
        'studiedOptionsFilter',
        'watchListOptionsFilter',
        'hmRange',
        'openingOptionsFilter',
        'sortByOptionsFilter',
        'favOptionsFilter',
        'deltaRange',
        'positionStatusFilter'
      ];

      const value = [
        this.localSideModel,
        this.localStudiedModel,
        this.localWatchlistModel,
        [this.minModel, this.maxModel],
        this.localOpeningModel,
        this.localSortByModel,
        this.favModel,
         [this.minDeltaModel, this.maxDeltaModel],
         this.positionStatusModel
      ];

      this.SET_DATA_BY_KEY({
        key,
        value,
      });

      // this.SET_DATA_BY_KEY({
      //   key: 'sideOptionsFilter',
      //   value: this.localSideModel,
      // });
      // this.SET_DATA_BY_KEY({
      //   key: 'studiedOptionsFilter',
      //   value: this.localStudiedModel,
      // });
      // this.SET_DATA_BY_KEY({
      //   key: 'watchListOptionsFilter',
      //   value: this.localWatchlistModel,
      // });
      // this.SET_DATA_BY_KEY({
      //   key: 'hmRange',
      //   value: [this.minModel, this.maxModel],
      // });
      // this.SET_DATA_BY_KEY({
      //   key: 'openingOptionsFilter',
      //   value: this.localOpeningModel,
      // });
      // this.SET_DATA_BY_KEY({
      //   key: 'sortByOptionsFilter',
      //   value: this.localSortByModel,
      // });

      this.filtersChanged = false;
    },
    getSteps(min, max, step) {
      const stepsArray = [];
      for (let i = min; i <= max; i += step) {
        stepsArray.push(i);
      }
      return stepsArray;
    },
    updateRange(newRange) {
      if (this.minModel !== newRange[0] || this.maxModel !== newRange[1]) {
        this.minModel = newRange[0];
        this.maxModel = newRange[1];
        this.updateFiltersChanged();
      }
    },
    updateDelta(newRange) {
      if (
        this.minDeltaModel !== newRange[0] ||
        this.maxDeltaModel !== newRange[1]
      ) {
        this.minDeltaModel = newRange[0];
        this.maxDeltaModel = newRange[1];
        this.updateFiltersChanged();
      }
    },
    updateFiltersChanged() {
      this.filtersChanged = true;
    },
  },
};
</script>

<style scoped>
.rounded-card {
  border-radius: 16px;
  width: 100%;
  background: rgb(35, 35, 35);

}
</style>
