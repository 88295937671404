import { createRouter, createWebHashHistory } from "vue-router";
import store from "@/store";

import AboutPage from "@/pages/AboutPage.vue";
import ConfirmEmailPage from '@/pages/ConfirmEmailPage.vue'
import LoginPage from "@/pages/LoginPage.vue";
import DevelopPage from "@/pages/DevelopPage.vue";
import CardsPage from "@/pages/CardsPage.vue";
import AnalysisPage from "@/pages/AnalysisPage.vue";
import PreAnalysisPage from "@/pages/PreAnalysisPage.vue";

import ExercisePage from "@/pages/ExercisePage.vue"

const routes = [
    // { path: "/", component: HomePage, name: 'Home' },
    { path: "/confirmemail", component: ConfirmEmailPage, name: "Email" },
    { path: "/", component: CardsPage, name: "Cards" },
    { path: "/about", component: AboutPage, name: "About" },
    { path: "/login", component: LoginPage, name: "Login" },
    { path: "/change-password", component: LoginPage, name: "ChangePassword" }, 
    { path: "/finish-registration", component: LoginPage, name: "FinishRegistration" }, 
    { path: "/dev", component: DevelopPage, name: "DevelopPage" },
    { path: "/exercise/:heroid/:posid/:categories/:herowhite/", component: ExercisePage, name: "ExercisePage" },
    { path: "/analysis0/:heroid/:categories/:posid?", component: PreAnalysisPage, name: "PreAnalysis" },
    { path: "/analysis1/:heroid/:categories/:posid?", component: AnalysisPage, name: "Analysis" },
  ];
  
  const router = createRouter({
    history: createWebHashHistory(),
    routes,
  });

  router.beforeEach(async (to, from) => {
    if(to.name === 'About') return
    if ((to.name !== 'Email') && (to.name !== 'ChangePassword') && (to.name !== 'FinishRegistration')  && (!store.state.isAuthenticated && to.name !== "Login")) {
      return "/login";
    }

    // if(to?.name !== from?.name) {
    //   store.commit('data/RESET')
    // }
  });

  export default router