<template>
  <v-card>
    <v-card-title>{{ $t('feedback.title') }}</v-card-title>
    <v-card-text>
      <div class="d-flex align-center" style="gap: 1em">
        <div style="flex: 1">
          <v-textarea
            v-model="textModel"
            :rules="[required]"
            :label="$t('feedback.description')"
            variant="outlined"
          />
        </div>
      </div>
    </v-card-text>
    <v-card-text v-if="errorMessage" style="color: red">{{
      errorMessage
    }}</v-card-text>

    <div class="d-flex align-center justify-center mb-4 ml-2" style="gap: 1em">
      <v-btn @click="$emit('close')">{{ $t('common.cancel') }}</v-btn>
      <v-btn color="blue" :disabled="!textModel" @click="takeSS">{{
        $t('common.ok')
      }}</v-btn>
    </div>
  </v-card>
</template>

<script>
import domtoimage from 'dom-to-image/dist/dom-to-image.min.js';
import { saveAs } from 'file-saver/dist/FileSaver.min.js';
import axios from '@/plugins/axiosWrapper';
export default {
  data: () => ({
    textModel: '',
    errorMessage: '',
    image: null,
  }),
  methods: {
    required(v) {
      return !!v || this.$t('common.fieldRequired');
    },
    takeSS() {

      const node = document.getElementById('app');
      const sendData = async (blob) => {
  
        const file = new File([blob], 'img.png', { type: 'image/png' });
        const formData = new FormData();

        formData.append('files', file);
        const resp = await axios({
          method: 'post',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
            'x-forwarded-for': localStorage.getItem('ip-from'),
          },
          url: `${process.env.VUE_APP_DEV_API}/api/files-upload`,
          data: formData,
        }).catch((e) => console.log(e));

        if (resp && resp.data) {
          axios({
            method: 'post',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'x-forwarded-for': localStorage.getItem('ip-from'),
            },
            url: `${process.env.VUE_APP_DEV_API}/api/`,
            data: {
              mtd: 'feedback',
              msg: this.textModel + '\n' + resp.data,
            },
          }).catch((e) => console.log(e));
        }
        // console.log(resp);
      };

      domtoimage
        .toBlob(node, {
          style: { background: 'white' },
          //   height: maxOffsetHeight
        })

        .then((blob) => {
          sendData(blob);
        })
        .catch(async () => {
          console.log('Ошибка выгрузки изображения');
        });

      this.$emit('close');
    },
  },
  mounted() {
    this.textModel = '';
    this.errorMessage = '';
    this.image = null;
  },
};
</script>

<style scoped></style>
