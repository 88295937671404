<template>
  <v-dialog
    v-model="editAccountDialogModel"
    persistent
    width="40vw"
    height="40vh"
  >
    <edit-account-dialog
      @close="editingNickname = false"
      @delete="handleDeleteHero"
      :hero="editingNickname"
    />
  </v-dialog>

  <div class="main-container">
    <portal to="app-bar-portal-user">
      <div v-if="isAuthenticated">
        <!-- Placeholder for authenticated user menu if needed -->
      </div>
    </portal>

    <div class="content">
         <v-tabs v-model="tabsModel" hide-slider class="tabs-container">
            <v-btn
              prepend-icon="mdi-plus-circle-outline"
              @click="openEditNickDialog()"
              class="add-person-btn"
            >
              {{ $t('card.addPerson') }}
            </v-btn>

            <draggable v-model="heroesList" @end="onDrop" class="draggable-tabs" :handle="'.drag-handle'">
              <template #item="{ element }">
                <v-tab
                  :key="element.hero_id"
                  :value="element.hero_id"
                  class="custom-tab drag-handle"
                  :class="{ 'active-tab': tabsModel === element.hero_id }"
                >
                  <span class="tab-text">{{ element.name }}</span>
                  <v-btn
                    class="custom-btn"
                    @click.stop="openEditNickDialog(element.hero_id)"
                    icon="mdi-square-edit-outline"
                  ></v-btn>
                </v-tab>
              </template>
            </draggable>
          </v-tabs>

          
      <!-- <v-tabs
        v-model="tabsModel"
        v-if="heroesList"
        hide-slider
        class="tabs-container"
      >
        <v-btn
          prepend-icon="mdi-plus-circle-outline"
          @click="openEditNickDialog()"
          class="add-person-btn"
        >
          Add person
        </v-btn>

        <v-tab
          v-for="(hero) in heroesList"
          :key="hero.hero_id"
          :value="hero.hero_id"
          class="custom-tab"
          :class="{ 'active-tab': tabsModel === hero.hero_id }"
        >
          <span class="tab-text">{{ hero.name }}</span>
          <v-btn
            class="custom-btn"
            @click.stop="openEditNickDialog(hero.hero_id)"
            icon="mdi-square-edit-outline"
          ></v-btn>
        </v-tab>
      </v-tabs> -->

      <div class="card-summary-container">
        <cards-summary
          v-if="getItem"
          :hero="getItem"
          @addNickname="addAccountDialogModel = true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LogoSvg from '/src/assets/LogoSvg.vue';
import CardsSummary from "@/components/Cards/CardsSummary.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import EditAccountDialog from './EditHeroDialog.vue';
import draggable from 'vuedraggable';
const defaultNickname = {
  hero_id: null,
  playground: 0,
  nick: '',
};

export default {
  data: () => ({
    tabsModel: "",
    editingNickname: null,
  }),
  components: {
    CardsSummary, 
    EditAccountDialog,   
    draggable,
  },
  computed: {
    ...mapState("data", ["heroesList"]),
    ...mapState(['isAuthenticated']),
    ...mapGetters('data', ['getCurrentHeroNicknames']),
    getItem() {
      const { tabsModel, heroesList } = this;
      if (!heroesList || heroesList.length === 0 || !tabsModel) return null;
      return heroesList.find((item) => item.hero_id === tabsModel);
    },
    editAccountDialogModel: {
      get() {
        return this.editingNickname ? true : false;
      },
      set(val) {
        if (!val) {
          this.editingNickname = null;
        }
      },
    },
    currentHeroNicknames() {
      const hero = this.hero;
      const hero_id = hero.hero_id;
      return this.getCurrentHeroNicknames(hero_id);
    },
  },
  methods: {
    ...mapActions("data", ["getHeroesList","relocateHero"]),
    openEditNickDialog(nickId) {
      const { tabsModel, heroesList } = this;
      if (!heroesList || heroesList.length === 0 || !tabsModel) return null;
      const currHero = heroesList.find((item) => item.hero_id === nickId);
      // console.log('currHero',currHero)
      if (currHero){
        currHero.description = currHero?.info?.filter(item=>item.param === "Description")[0]?.value || ''
      }
      
      this.editingNickname = currHero || defaultNickname;
    },
   handleDeleteHero(deletedHeroId) {
   if (this.heroesList?.length > 0) {
      const firstHeroId = this.heroesList[0].hero_id;
         if (this.tabsModel === deletedHeroId) {
        this.tabsModel = firstHeroId;
      }
    }
    
  },
  async onDrop(event) { 
      const draggedItem = this.heroesList[event.oldIndex];  
      const heroId = draggedItem.hero_id
      const afterId = event.newIndex > event.oldIndex ? this.heroesList[event.newIndex]?.hero_id : this.heroesList[event.newIndex-1]?.hero_id ;
      await this.relocateHero({ 
                heroId,
                afterId,
              }); 
    },
  async fetchHeroesList() { 
    await this.getHeroesList();
 
    if (this.heroesList?.length > 0) {
      this.tabsModel = this.heroesList[0].hero_id;  
    }
  },
  },
  mounted() {
     this.fetchHeroesList();
    // if (!this.heroesList) {
    //   this.getHeroesList();
    // }
  },
  watch: {
    heroesList: {
      deep: true,
      handler: function() {}
    }
  }
};
</script>

<style scoped>
 @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600&display=swap');

 .draggable-tabs {
  display: flex; /* Ensure the tabs are displayed in a row */
  flex-direction: row; /* Make sure to lay them out horizontally */
}

/* .drag-handle {
  cursor: grab;  
} */

.main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-left: 50px; /* Apply padding to the entire container */
  padding-top: 16px;
}

.content {
  margin-left: 50px; /* Apply left margin to content */
  background-color:#171717;
}

.tabs-container {
  display: flex;
  align-items: center;
  margin-left: 50px;
  height: 32px; /* Set height of v-tabs */
}

.add-person-btn {
  margin-right: 36px; /* Space between v-btn and tabs */
  text-overflow: ellipsis;
  height: 32px; /* Ensure v-btn matches tab height */
}

.custom-tab {
  border: 2px solid transparent; /* Default border color for tabs */
  border-radius: 16px; /* Rounded corners */
  position: relative;
  background-color: transparent; /* Transparent background for default state */
  transition: border-color 0.3s ease; /* Smooth transition for border color */
  height: 32px; /* Match tab height */
  display: flex;
  align-items: center; /* Center content vertically */
  padding: 0 8px; /* Adjust padding as needed */
}

.custom-tab.active-tab {
  border-color: #42a5f5; /* Blue border for the active tab */
}

.tab-text {
  margin-left: 16px; /* Space on the left of the hero name */
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600; /* Semi-bold */
  font-size: 14px;
  line-height: 32px; /* Center text vertically */
}

.custom-btn {
  border-radius: 0; /* Remove rounded corners */
  background-color: transparent; /* Transparent background */
  box-shadow: none; /* Remove box shadow */
  border: 1px solid transparent; /* Optional: Remove any border */
  outline: none; /* Remove outline */
  transition: none; /* Disable transitions for hover effects */
  height: 32px; /* Match button height */
  display: flex;
  align-items: center; /* Center icon vertically */
  margin-left: 8px; /* Space between text and icon */
}

.custom-btn:hover,
.custom-btn:focus {
  background-color: transparent; /* Ensure transparent background on hover and focus */
  border-color: transparent; /* Ensure no border color on hover and focus */
  box-shadow: none; /* Remove box shadow on hover and focus */
}

.card-summary-container {
  margin-left: 50px; /* Apply left margin to card-summary */
  margin-top: 40px;
}
</style>
