<template>
  <v-app class="bg-darkGrey">
    <v-layout>
      <v-dialog
        persistent
        v-model="autoLoading"
        fullscreen
        class="loader-dialog"
      >
        <div
          style="width: 100%; height: 100%"
          class="d-flex align-center justify-center"
        >
          <v-progress-circular
            :indeterminate="!autoLoadingProgressModel"
            :model-value="autoLoadingProgressModel"
            :rotate="360"
            :size="80"
            :width="8"
            color="primary"
          >
            <template v-slot:default>
              <div v-if="autoLoadingProgressModel" class="semibold-14">
                {{ autoLoadingProgressModel }}%
              </div>
            </template>
          </v-progress-circular>
          <!-- <v-progress-circular indeterminate class="mb-2"></v-progress-circular> -->
          <!-- <div>
             
            {{autoLoadingProgress}}%
          </div> -->
        </div>
      </v-dialog>
      <v-dialog v-model="bugReportDialogModel" persistent fullscreen>
        <bug-report-dialog @close="bugReportDialogModel = false" />
      </v-dialog>
      <v-snackbar
        v-model="toastModel"
        :timeout="5000"
        rounded="pill"
        color="red"
        class="regular-14"
      >
        {{ appToast }}

        <template v-slot:actions>
          <v-btn
            color="darkGrey"
            @click="toastModel = false"
            size="small"
            class="semibold-14 text-none"
          >
            Скрыть
          </v-btn>
        </template>
      </v-snackbar>
      <v-app-bar
        :color="'#171717'"
        class="custom-app-bar"
        v-if="isAuthenticated"
        app
        style="border: none"
      >
        <logo-svg
          @click="handleLogoClick"
          style="cursor: pointer"
          class="ma-4"
        />
        <!-- <v-btn variant="tonal" color="white" class="ml-4 font-weight-bold" @click="handleAboutClick">
          О компании
        </v-btn> -->
        <v-spacer></v-spacer>
        <portal-target name="app-bar-portal" />

        <!-- <div class="mr-2" >
          <v-icon>mdi-bug</v-icon>
        </div> -->
        <!-- <div class="mr-2">
          <v-icon>mdi-email-outline</v-icon>
          <a v-bind:href="'mailto:' + getMail">{{ getMail }}</a>
        </div> -->
        <!-- <div class="mr-2">
          <v-icon>mdi-tune-vertical</v-icon>
        </div>
        <div class="mr-2">Settings</div> -->
        <div class="mx-2 pointer" v-if="isAuthenticated && userSettings">
          <v-menu class="regular-14">
            <template v-slot:activator="{ props }">
              <div v-bind="props" class="d-flex align-center">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.6663 4.66667C10.6663 6.13943 9.47243 7.33333 7.99967 7.33333C6.52692 7.33333 5.33301 6.13943 5.33301 4.66667C5.33301 3.19391 6.52692 2 7.99967 2C9.47243 2 10.6663 3.19391 10.6663 4.66667Z"
                    stroke="#9A9A9A"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.99967 9.33333C5.42235 9.33333 3.33301 11.4227 3.33301 14H12.6663C12.6663 11.4227 10.577 9.33333 7.99967 9.33333Z"
                    stroke="#9A9A9A"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div class="ml-2 semibold-14">
                  {{ userSettings.displayed_name }}
                </div>
              </div>
            </template>
            <v-list style="cursor: pointer">
              <v-list-item @click="handleLogOut">{{
                $t('accountMenu.logout')
              }}</v-list-item>
            </v-list>
          </v-menu>
        </div>

        <div
          class="mx-2 d-flex align-center pointer"
          @click="bugReportDialogModel = true"
          v-if="isAuthenticated"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.3333 5.33329H12.6667C13.403 5.33329 14 5.93025 14 6.66663V10.6666C14 11.403 13.403 12 12.6667 12H11.3333V14.6666L8.66667 12H6C5.63181 12 5.29848 11.8507 5.05719 11.6094M5.05719 11.6094L7.33333 9.33329H10C10.7364 9.33329 11.3333 8.73634 11.3333 7.99996V3.99996C11.3333 3.26358 10.7364 2.66663 10 2.66663H3.33333C2.59695 2.66663 2 3.26358 2 3.99996V7.99996C2 8.73634 2.59695 9.33329 3.33333 9.33329H4.66667V12L5.05719 11.6094Z"
              stroke="#9A9A9A"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <div class="ml-2 semibold-14">{{ $t('common.feedback') }}</div>
        </div>
        <div class="mx-2">
          <div>
            <v-menu class="regular-14">
              <template v-slot:activator="{ props }">
                <div class="d-flex align-center" v-bind="props">
                  <div class="ml-2 semibold-14">{{ currentLangName }}</div>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.48021 6.81331C5.57396 6.71968 5.70104 6.66709 5.83354 6.66709C5.96604 6.66709 6.09313 6.71968 6.18688 6.81331L8.00021 8.62665L9.81355 6.81331C9.85932 6.76419 9.91452 6.72479 9.97585 6.69746C10.0372 6.67013 10.1034 6.65544 10.1705 6.65425C10.2377 6.65307 10.3044 6.66542 10.3666 6.69057C10.4289 6.71571 10.4854 6.75314 10.5329 6.80062C10.5804 6.8481 10.6178 6.90466 10.643 6.96692C10.6681 7.02917 10.6805 7.09586 10.6793 7.163C10.6781 7.23013 10.6634 7.29634 10.6361 7.35767C10.6087 7.41901 10.5693 7.47421 10.5202 7.51998L8.35354 9.68665C8.25979 9.78028 8.13271 9.83287 8.00021 9.83287C7.86771 9.83287 7.74063 9.78028 7.64688 9.68665L5.48021 7.51998C5.38658 7.42623 5.33398 7.29915 5.33398 7.16665C5.33398 7.03415 5.38658 6.90706 5.48021 6.81331Z"
                      fill="#9A9A9A"
                    />
                  </svg>
                </div>
              </template>
              <v-list density="compact" class="regular-14">
                <v-list-item
                  v-for="item in localeItems"
                  :key="item.id"
                  @click="selectLanguage(item)"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>

        <portal-target name="app-bar-portal-user" />
      </v-app-bar>
      <v-main>
        <div class="content">
          <router-view></router-view>
        </div>
        <!-- <div
          style="
            position: fixed;
            bottom: 0px;
            left: 0px;
            width: 100vw;
            height: 24px;
          "
          class="bg-white text-center"
        >
          <div class="text-blue">
            {{ `ООО Интеллектуальные системы, ${new Date().getFullYear()} г ` }}
          </div>
        </div> -->
      </v-main>
    </v-layout>
    <v-footer class="semibold-14 align-end justify-center"
      >2024. ООО "Интеллектуальные системы". Все права защищены</v-footer
    >
  </v-app>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import BugReportDialog from './components/BugReportDialog.vue';
import LogoSvg from '/src/assets/LogoSvg.vue';
import orch from '@/plugins/requestOrchestrator';


const defaultFilters = {
  pgid: 3,
  avelo: [2100, 2500, 2900],
  categories: [3],
  elodiff: '2,3,4,5,6',
};

export default {
  name: 'App',
  data: () => ({
    dialog: true,
    menuVisible: false,
    localeItems: [
      { title: 'en', id: '1', name: 'English' },
      { title: 'ru', id: '4', name: 'Русский' },
    ],
    bugReportDialogModel: false,
  }),
  computed: {
    ...mapState(['isAuthenticated']),
    ...mapState('ui', ['userPreferences', 'userSettings']),
    ...mapState('data', [
      'selectedMistake',
      'autoLoading',
      'autoLoadingProgress',
      'appToast',
    ]),
    autoLoadingProgressModel() {
      const autoLoadingProgress = this.autoLoadingProgress;

      if (autoLoadingProgress === null) {
        return '';
      }
      return autoLoadingProgress;
    },
    toastModel: {
      get() {
        return this.appToast ? true : false;
      },
      set() {
        this.SET_DATA_BY_KEY({ key: 'appToast', value: null });
      },
    },
    currentLangName() {
      const lang = this.localeItems.find((i) => i.id === this.langModel.id);
      return lang ? lang.name : 'English';
    },
    langModel: {
      get() {
        const lang_interface = this.userPreferences?.lang_interface;
        if (lang_interface === undefined) return { title: 'en', id: '1' };
        return (
          this.localeItems.find((i) => Number(i.id) === lang_interface) || {
            title: 'en',
            id: 1,
          }
        );
      },
      set(val) {
        if (!this.isAuthenticated) {
          this.SET_DATA_BY_KEY({
            key: 'userPreferences',
            value: { lang_interface: Number(val.id) },
          });
          localStorage.setItem('rememberLocaleOnLogin', true);
        } else {
          this.setUserPreferences({
            ...this.userPreferences,
            lang_interface: Number(val.id),
          });
        }
      },
    },
    getMail() {
      return process.env.VUE_APP_MAIL;
    },
  },
  components: {
    LogoSvg,
    BugReportDialog,
  },
  methods: {
    ...mapActions('ui', ['setUserPreferences']),
    ...mapActions('data', ['setAutoLoading']),
    ...mapActions(['logOutUser']),
    ...mapMutations('data', ['SET_DATA_BY_KEY']),
    // toggleLoading: throttle(function (val) {
    //   this.SET_DATA_BY_KEY({ key: 'autoLoading', value: val });
    // }, 1000),
    handleLogoClick() {
      this.$router.push('/');
    },
    handleReportBug() {
      window.location.href = `mailto:${process.env.VUE_APP_MAIL}`;
    },
    handleAboutClick() {
      this.$router.push('/about');
    },
    handleLogOut() {
      const cb = () => {
        this.$router.push('/login');
      };
      this.logOutUser(cb);
    },
    selectLanguage(item) {
      this.langModel = item;
      this.menuVisible = false;
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
  },
  mounted() {
    const token = localStorage.getItem('token');

    if (token) {
      this.$store.commit('SET_DATA_BY_KEY', {
        key: 'isAuthenticated',
        value: true,
      });
    }
    this.$store.commit('SET_DATA_BY_KEY', {
      key: 'sizes',
      value: {
        vh:
          window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight,
        vw:
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth,
      },
    });

    window.addEventListener('resize', () => {
      this.$store.commit('SET_DATA_BY_KEY', {
        key: 'sizes',
        value: {
          vh:
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight,
          vw:
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth,
        },
      });
    });

    fetch('https://api.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => {
        localStorage.setItem('ip-from', data.ip);
      })
      .catch((error) => {
        console.error('Error fetching IP:', error);
      });
  },
  watch: {
    isAuthenticated: {
      immediate: true,
      handler: async function (val) {
        if (val === true) {
          this.$store.dispatch('ui/getSettingsAndPreferences');
          // this.$store.dispatch('ui/getUserSettings');
          // this.$store.dispatch('ui/getUserPreferences');
        }
      },
    },
    userPreferences: {
      deep: true,
      immediate: true,
      handler: function (val) {
        if (val && val.lang_interface !== undefined) {
          const targ = this.localeItems.find(
            (i) => Number(i.id) === val.lang_interface,
          );
          this.$i18n.locale = targ?.title || 'en';
        }
      },
    },
  },
  created() {
    orch.setCb(val => this.setAutoLoading( Object.keys(val).length > 0));
  },
};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

svg {
  scroll-behavior: smooth !important;
}

svg text {
  pointer-events: none;
}

html {
  font-family: 'Century Gothic', sans-serif !important;
  scroll-behavior: smooth !important;
  overflow: hidden !important;
}
.pointer {
  cursor: pointer;
}
.custom-input input {
  padding-left: 50px;
}

.custom-app-bar {
  border-bottom: 1px solid #3c3c3c;
}

.v-select .v-select__append-inner {
  display: none;
}

.content {
  background-color: #171717;
}

.justify-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.custom-disabled-btn.v-btn--disabled {
  background-color: rgba(43, 44, 49, 1) !important;
  color: rgba(255, 255, 255, 1) !important;
  border-color: #007bff !important;
  opacity: 0.1 !important;
  filter: none !important;
}

.custom-disabled-btn.--v-theme-overlay-multiplier {
  background-color: rgba(43, 44, 49, 1) !important;
}

/* width */
::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1d85dd;
  border: 4px solid transparent;
  background-clip: padding-box;
  border-radius: 9999px;
  min-height: 40px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1d85dd;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

.custom-table {
  width: 100%;
  border-collapse: collapse; /* Ensures borders are collapsed for the table */
  border-radius: 8px; /* Rounds the corners of the table */
  overflow: hidden; /* Ensures content does not overflow rounded corners */
}

.custom-table thead,
.custom-table tbody {
  border: 1px solid #ddd; /* Outer border for the table */
}

.custom-table th,
.custom-table td {
  min-height: 44px;
  padding: 8px; /* Padding inside cells */
  text-align: left; /* Align text to the left */
}

.custom-table th {
  background-color: #1e1e1e; /* Slightly darker gray for headers */
  font-weight: bold; /* Bold text for headers */
}

.custom-table td {
  background-color: #232323; /* Remove background color from cells */
}

.custom-table tr {
  border-bottom: 1px solid #ddd; /* Bottom border for each row */
}

.custom-table tr {
  border-left: 1px solid #ddd; /* Left border for the first cell in each row */
  background-color: #1e1e1e; /* Background color for the first column */
}

.regular-14 {
  /* design/text/14-regular */
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.semibold-14 {
  /* design/text/14-semibold */
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.semibold-16 {
  /* design/text/16-semibold */
  color: rgb(255, 255, 255);
  font-family: 'Source Sans 3';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0%;
  text-align: left;
}

.semibold-24 {
  font-family: 'Source Sans 3';
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0%;
  text-align: left;
}

/* .loader-dialog {
  background: rgba(43, 44, 49, .7)
} */

.v-overlay__scrim {
  background: rgba(43, 44, 49, 1) !important;
}

.v-card {
  min-height: 48px !important;
}
</style>
