<template>
  <v-card>
    <v-card-title>{{$t('accountMenu.settings')}}</v-card-title>
    <v-card-text>
      <div  v-if="localModel">
        <div style="width: 300px">
          <v-text-field
            v-model="localModel.displayed_name"
            :rules="[required]"
            :label="$t('settings.displayedName')"
            variant="outlined"
          />
        </div>
        <div  style="width: 300px">
          <v-text-field
            v-model="localModel.phone"
            :rules="[]"
            :label="$t('login.phone')"
            variant="outlined"
          />
        </div>
      </div>
    </v-card-text>
    <div class="d-flex align-center justify-center mb-4 ml-2" style="gap: 1em">
      <v-btn @click="$emit('close')">{{$t('common.cancel')}}</v-btn>
      <!-- <v-btn color="blue" :disabled="!localModel || !localModel.displayed_name" @click="handleSave">Save</v-btn> -->
    </div>
  </v-card>
</template>

<script>
import { mapActions} from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
export default {
  emits: ["close"],
  data: () => ({
    localModel: null
  }),
  methods: {
    handleSave(){

    }
  },
  mounted() {
    this.localModel = cloneDeep(this.$store.state.ui.userSettings)
  }
};
</script>

<style scoped></style>
