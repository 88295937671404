<template>
  <div :style="{ width: compact ? 212 : 314 + 'px' }">
    <div>
      <v-btn-toggle
        v-model="viewModel"
        density="compact"
        size="small"
        v-if="!compact"
      >
        <v-btn
          density="compact"
          size="small"
          v-for="option in viewOptions"
          :key="option.id"
          style="width: 157px"
          class="text-none semibold-14"
        >
          {{ option.text }}
        </v-btn>
      </v-btn-toggle>
    </div>

    <div class="px-2 mt-2" v-if="viewModel === 0 || compact">
      <div class="semibold-14 my-2">{{ this.heroName }}</div>
      <div class="d-flex regular-14">
        <div :class="compact ? 'item-left-compact' : 'item-left'">
          {{ $t('statistics.numberOfGames') }}
        </div>
        <div :class="compact ? 'item-right-compact' : 'item-right'">
          {{ heroSummary }}
        </div>
      </div>
      <div class="d-flex regular-14">
        <div :class="compact ? 'item-left-compact' : 'item-left'">
          {{ $t('statistics.resultsPerc') }}
        </div>
        <div
          :class="`${
            compact ? 'item-right-compact' : 'item-right'
          } d-flex align-center`"
        >
          <div
            v-for="(colorObj, index) in $getFill(
              { array: summaryArray, totalCount: heroSummary },
              false,
            )"
            class="text-black"
            :key="'asfasf' + index"
            :style="`overflow:hidden; height20px; background-color:${colorObj.color};width: ${colorObj.number}%`"
          >
            {{ Math.round(colorObj.number) }}
          </div>
        </div>
      </div>
      <div class="d-flex regular-14" v-if="heroDecisionsColors">
        <div :class="compact ? 'item-left-compact' : 'item-left'">
          {{ $t('statistics.decisonsPerc') }}
        </div>
        <div
          :class="`${
            compact ? 'item-right-compact' : 'item-right'
          } d-flex align-center`"
        >
          <div
            v-for="(colorObj, index) in heroDecisionsColors"
            :key="'asfasf' + index"
            class="d-flex align-center justify-start text-black"
            :style="`overflow:hidden; height:20px; background-color:${colorObj.color};width: ${colorObj.number}%`"
          >
            {{ Math.round(colorObj.number) }}
          </div>
        </div>
      </div>

      <v-divider class="my-4" />

      <div
        class="semibold-14 my-2"
        :style="{
          maxWidth: compact ? '200px' : 'auto',
          overflow: 'hidden',
          whiteSpace: compact ? 'nowrap' : 'wrap',
          textOverflow: 'ellipsis',
        }"
      >
        {{ compareString }}
      </div>

      <div class="d-flex regular-14">
        <div :class="compact ? 'item-left-compact' : 'item-left'">
          {{ $t('statistics.numberOfGames') }}
        </div>
        <div :class="compact ? 'item-right-compact' : 'item-right'">{{ groupSumary }}</div>
      </div>
      <div class="d-flex regular-14">
        <div :class="compact ? 'item-left-compact' : 'item-left'">
          {{ $t('statistics.resultsPerc') }}
        </div>
        <div  :class="`${
            compact ? 'item-right-compact' : 'item-right'
          } d-flex align-center`">
          <div
            v-for="(colorObj, index) in $getFill(
              { array: groupArray, totalCount: groupSumary },
              false,
            )"
            class="text-black"
            :key="'asfasf' + index"
            :style="`overflow:hidden; height20px; background-color:${colorObj.color};width: ${colorObj.number}%`"
          >
            {{ Math.round(colorObj.number) }}
          </div>
        </div>
      </div>
      <div class="d-flex regular-14" v-if="groupDecisionColors">
        <div :class="compact ? 'item-left-compact' : 'item-left'">
          {{ $t('statistics.decisonsPerc') }}
        </div>
        <div :class="`${
            compact ? 'item-right-compact' : 'item-right'
          } d-flex align-center`">
          <div
            v-for="(colorObj, index) in groupDecisionColors"
            :key="'asfasf' + index"
            class="d-flex align-center justify-start text-black"
            :style="`overflow:hidden; height:20px; background-color:${colorObj.color};width: ${colorObj.number}%`"
          >
            {{ Math.round(colorObj.number) }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="px-2 mt-2"
      v-if="
        viewModel === 1 &&
        selectedOpening &&
        selectedOpening.children &&
        selectedOpening.children.length > 0
      "
    >
      <div style="width: 100%" class="d-flex align-center semibold-14">
        <div style="flex: 2">{{ $t('statistics.moveEval') }}</div>
        <div style="flex: 1">{{ $t('statistics.frequency') }}</div>
        <div style="flex: 3">{{ $t('statistics.resultsPerc') }}</div>
      </div>

      <div
        v-for="child in selectedOpening.children"
        :key="child.id"
        class="d-flex align-center regular-14 text-lightGrey"
        style="heigth: 20px"
      >
        <div style="flex: 2">
          {{ $getMoveText(child) }}{{ ` (${child.ev_text})` }}
        </div>
        <div style="flex: 1">
          {{ getChildCountPercent(child, selectedOpening.children) }}%
        </div>
        <div style="flex: 3" class="d-flex align-center">
          <div
            v-for="(colorObj, index) in $getFill(
              {
                array: getChildResults(child),
                totalCount: getChildrenResultGroupsCount(child),
              },
              false,
            )"
            :key="'asfasf' + index"
            class="d-flex align-center justify-start text-black"
            :style="`overflow:hidden; height20px; background-color:${colorObj.color};width: ${colorObj.number}%`"
          >
            {{ Math.round(colorObj.number) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'selectedOpening',
    'heroName',
    'compareString',
    'handleSelectOpening',
    'compact',
  ],
  data: () => ({
    viewModel: 0,
  }),
  computed: {
    viewOptions() {
      return [
        { id: 0, text: this.$t('statistics.stat') },
        { id: 1, text: this.$t('statistics.variations') },
      ];
    },
    summaryArray() {
      if (!this.selectedOpening) return [];
      const { hm, results_hero } = this.selectedOpening;
      const arr = results_hero;
      const parsedArray = this.$parseArray(arr).map((i) => Number(i));
      return parsedArray;
    },
    heroSummary() {
      if (!this.selectedOpening) return null;
      const summaryArray = this.summaryArray;
      return summaryArray.reduce((acc, item) => (acc += Number(item)), 0);
    },
    groupArray() {
      const { results_groups } = this.selectedOpening;
      const parsedArray = this.$parseArray(results_groups).map((i) =>
        Number(i),
      );
      return parsedArray;
    },
    groupSumary() {
      if (!this.selectedOpening) return null;
      const summaryArray = this.groupArray;
      return summaryArray.reduce((acc, item) => (acc += Number(item)), 0);
    },
    heroDecisionsColors() {
      const {
        // waste, move_count_hero_black, move_count_hero_white, hm,
        children,
        id,
        gooddec_hero,
      } = this.selectedOpening;

      // const moveCount =
      //   hm % 2 === 0 ? move_count_hero_black : move_count_hero_white;
      const greenCount = gooddec_hero;
      // children.find(item => item.pos_from_id === id)?.parentdecisions_hero
      // const redCount = this.$getMoveQualityFill(
      //   moveCount,
      //   this.$store.state.data.deviation,
      //   waste
      // );
      if (greenCount === undefined) return null;
      if (greenCount === null) return null;

      return [
        {
          color: '#80ff80',
          number: greenCount || 0,
        },
        {
          color: '#ff8080',
          number: 100 - (greenCount || 0),
        },
      ];
    },
    groupDecisionColors() {
      // const { waste, move_count_groups } = this.selectedOpening;

      const {
        // waste, move_count_hero_black, move_count_hero_white, hm,
        children,
        id,
        gooddec_groups,
      } = this.selectedOpening;
      // const moveCount =
      //   hm % 2 === 0 ? move_count_hero_black : move_count_hero_white;
      const greenCount = gooddec_groups;
      //  children.find(item => item.pos_from_id === id)?.parentdecisions_groups
      // const redCount = this.$getMoveQualityFill(
      //   moveCount,
      //   this.$store.state.data.deviation,
      //   waste
      // );
      if (greenCount === null) return null;

      if (greenCount === undefined) return null;

      return [
        {
          color: '#80ff80',
          number: greenCount || 0,
        },
        {
          color: '#ff8080',
          number: 100 - (greenCount || 0),
        },
      ];
    },
  },
  methods: {
    getChildCountPercent(child, children) {
      const total = children.reduce(
        (acc, item) => (acc += Number(item.move_count_groups || 0)),
        0,
      );
      if (!total || !child.move_count_groups) return 0;
      return (100 * (child.move_count_groups / total)).toFixed(0);
    },
    getChildrenResultGroupsCount(child) {
      const { results_groups } = child;

      const reduced = results_groups?.reduce((acc, item) => {
        acc += Number(item);
        return acc;
      }, 0);
      if (!reduced) return null;
      return reduced;
    },
    getChildResults(child) {
      if (!child) return [];
      return child.results_groups;
    },
  },
  watch: {
    selectedOpening: {
      deep: true,
      handler: function (val) {},
    },
  },
};
</script>

<style scoped>
.item-left {
  width: 120px;
  height: 20px;
}

.item-right {
  width: 186px;
  height: 20px;
}

.item-left-compact {
  width: 90px;
  height: 20px;
}

.item-right-compact {
  width: 90px;
  height: 20px;
}
</style>
