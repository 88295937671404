<template>
  <!-- <v-container> -->
    <div v-if ="!isTokenValid">
      <v-card-title class="d-flex justify-center text-h4">
        <div>{{ $t('changePassword.problem') }}</div>
      </v-card-title>
      <div
        class="pt-8"
        style="display: flex; flex-direction: column; align-items: center"
      >
        <div class="regular-text">{{ $t("changePassword.expired") }}</div>
     
      </div>
      <div class="pt-8">
        <CustomButton
          :label="$t('changePassword.resend')"
          backgroundColor="#1D85DD"
          textColor="white"
          customHeight="52px"
          iconClass="mr-2"
          rounded="medium"
          block
          :disabled="false"
          size="large"
          @click="()=>{}"
        />
      </div>
    </div>
    <div v-else-if="SuccessfullyChanged">
      <div class="text-h4 d-flex justify-center">
        {{ $t('changePassword.success') }}
      </div>
      <div class="pt-8">
        <CustomButton
          :label="$t('changePassword.login')"
          backgroundColor="#1D85DD"
          textColor="white"
          customHeight="52px"
          iconClass="mr-2"
          rounded="medium"
          block
          :disabled="false"
          size="large"
          @click="switchModeToLogin"
        />
      </div>
    </div>
    <div v-else>
    <v-card-title class="d-flex justify-center text-h4">
      <div>{{ $t('changePassword.title') }}</div>
    </v-card-title>
    <CustomPasswordField
      v-model="newPasswordModel"
      :placeholder="$t('changePassword.enterPassword')"
      :isValid="isNewPasswordValid"
      @validate="debouncedValidatePassword"
      prependIcon="lock"
      height="48px"
      class="mb-2 pt-8"
    />
    <CustomPasswordField
      v-model="confirmPasswordModel"
      :placeholder="$t('changePassword.confirmPassword')"
      :isValid="isConfirmPasswordValid"
      @validate="debouncedValidateConfirmPassword"
      prependIcon="lock"
      height="48px"
      class="mb-2"
    />

 
       <div class="pb-2">
        <div
          v-if="errors.length==0"
          v-html="$t('changePassword.tip')"
          class="checkbox-label"
        ></div>

        <top-three-list
          v-if="errors.length>0"
          :records="errors.map(item=>item.message)"
          :rowsQty="4"
        />
      </div>
 

    <div >
      <CustomButton
        :label="$t('changePassword.submit')"
        backgroundColor="#1D85DD"
        textColor="white"
        customHeight="52px"
        iconClass="mr-2"
        rounded="medium"
        block
        :disabled="isSubmitButtonDisabled"
        size="large"
        @click="onSubmitChangePassword"
      />
    </div>
    </div>
  <!-- </v-container> -->
</template>

<script>
import { debounce } from 'lodash';
import CustomPasswordField from './CustomPasswordField.vue';
import CustomButton from './CustomButton.vue';
import TopThreeList from './TopThreeList.vue';
import { requiredRule, minLengthRule, uppercaseRule,numberRule,matchesRule } from './rules';
export default {
  props: {
    token: {
      type: String,
      required: true
    }
  },
  components: {
    CustomPasswordField,
    CustomButton,
    TopThreeList,
  },
  data() {
    return {
      SuccessfullyChanged:false,
      newPasswordModel: '',
      confirmPasswordModel: '',
      errorText: '',
      errors: [],
      isNewPasswordValid: false,
      isConfirmPasswordValid: false,
    };
  },
  computed: {
    isTokenValid() {
      // Check if the token is valid by calling the backend API
      return this.checkToken(); // Call the API to validate token
    //   return this.token !== null; // Adjust according to your validation logic
    },
    isSubmitButtonDisabled() {
      return !this.isNewPasswordValid || !this.isConfirmPasswordValid;
    },
    passwordRules() {
      return [
        value => requiredRule(this.$t)(value, 'password'),
        value => minLengthRule(this.$t)(value, 'password', 8),
        value => uppercaseRule(this.$t)(value, 'password'),
        value => numberRule(this.$t)(value, 'password'),
      ];
    },
    confirmPasswordRules(){
        return[
           value => matchesRule(this.$t)(value, this.newPasswordModel),
        ]
    },
  },
//   mounted() {
//     this.checkToken();
//   },
  methods: {
    switchModeToLogin(){
     this.$emit('switch-to-login')
    },
    checkToken() {
    //   const urlParams = new URLSearchParams(window.location.search);
    //   const token = urlParams.get('token');
         
      let isValid = true; // Replace with actual API call

      if (!isValid || this.token==='invalid') {
        isValid = false
      } 
      return isValid
    },
    // async isTokenValid(token) {
    //   // Simulate an API call to check if the token is valid
    //   const isValid = true; // Replace with actual API call

    //   if (!isValid || token==='invalid') {
    //     this.errorText = this.$t('changePassword.tokenExpired');
    //   }
    // },
    async onSubmitChangePassword() {
    //    this.errors = []; // Clear previous errors
    //   if (this.newPasswordModel !== this.confirmPasswordModel) {
    //     this.errors.push({ message: this.$t('changePassword.passwordMismatch') });
    //     return;
    //   }

      // Add logic to submit new password with the token
      console.log('Changing password with token:', this.token);
      console.log('New password:', this.newPasswordModel);
      this.SuccessfullyChanged = true;
      // Reset form on success
    //   this.newPasswordModel = '';
    //   this.confirmPasswordModel = '';
    },
    validatePassword() {
      if (!this.$t) return ;
      this.errors = this.errors.filter(error => error.field !== 'password');
      let isValid = true;
      const checkEmpty = requiredRule(this.$t)(this.newPasswordModel, 'password')
      if ( checkEmpty== true ){
            this.passwordRules.forEach(rule => {
                const result = rule(this.newPasswordModel);
                if (result !== true) {
                this.errors.push({ field: 'password', message: result});
                isValid = false;
                }
            });
      } else {
         this.errors.push({ field: 'password', message: checkEmpty });
         isValid = false;
      }

      this.isNewPasswordValid = isValid;
    },
    validateConfirmPassword() {
      if (!this.$t) return ;
      this.errors = this.errors.filter(error => error.field !== 'cofirmPassword');
      let isValid = true;
      if (this.newPasswordModel && !this.confirmPasswordModel){
         this.errors.push({ field: 'cofirmPassword', message: this.$t('rules.confirmpwd') })
         isValid = false;

      }  else {
        this.confirmPasswordRules.forEach(rule => {
            const result = rule(this.confirmPasswordModel);
                if (result !== true) {
            this.errors.push({ field: 'cofirmPassword', message: result });
            isValid = false;
         }
         });
      }

       this.isConfirmPasswordValid = isValid;
    }, 
    debouncedValidatePassword: debounce(function() {
      this.validatePassword();
    }, 300),
    debouncedValidateConfirmPassword: debounce(function() {
      this.validateConfirmPassword();
    }, 300),
  },
};
</script>

<style scoped>
.checkbox-label {
  color: var(--text-secondary, #9a9a9a);
  height: 64px;
  font-family: "Source Sans 3";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.text-h4 {
  text-align: center; /* Ensures text is centered */
} 
.hyperlink-text { 
  color: var(--text-primary, #F4F4F4);  
  font-family: "Source Sans 3";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.regular-text {
  color: var(--text-secondary, #9A9A9A);
 
  font-family: "Source Sans 3";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
}
</style>
