<template>
    <div style="height: 100%; width: 100%; " >
        <!-- <AdvancedBoard/> -->

        <v-btn color="green" class="ma-2" @click="handleClick">Click me</v-btn>
        <div style="width: 100%; height: 30vh" class="bg-white">
            Ответ:

            <code>
                {{responseMsg}}
            </code>
        </div>
    </div>  
</template>

<script>
    // import AdvancedBoard from "@/components/AdvancedBoard_alt"
    import axios from '@/plugins/axiosWrapper'

    export default {
        data: () => ( {
            responseMsg: null
        }),
        methods: {
            handleClick() {
                axios({
                    method: 'post',
                    url: `${process.env.VUE_APP_DEV_API}/api/`,
                    data: {"mtd": "help"},
                    headers: { "Content-Type": "application/json; charset=utf-8" },
                    //  responseType: "application/json",
                    //  withCredentials: true
                }).catch(e => {
                    console.log(e)
                    // this.responseMsg = e
                }).then(resp => {
                    //  this.responseMsg = resp
                     console.log(resp)
                     return resp
                })
            }
        },
     components: {
        // AdvancedBoard
     }   
    }
</script>

<style scoped>

</style>