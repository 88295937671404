export interface BackArrow {
  lineStr: string;
  reversed?: boolean;
};

export interface BackArrowsParams {
  parentX: number;
  parentY: number;
  borderRadius: number;
  blockHeight: number;
  blockWidth: number;
  diffX: number;
  diffY: number;
  parentRoot: boolean;
};

export const generateBackArrow = (
  type: string,
  { parentX, parentY, borderRadius, blockHeight, blockWidth, diffX, diffY, parentRoot }: BackArrowsParams): BackArrow | null => {
  switch (type) {
    case 'rightUpper':
      return {
        lineStr: `M${parentX - blockWidth},${
          parentY + blockHeight / 4 - 1
        } 
                a-${borderRadius},-${borderRadius} 1 0 0 ${borderRadius},${borderRadius}
                h${blockWidth / 2 + borderRadius}
                a${borderRadius},-${borderRadius} 0 0 0 ${borderRadius},-${borderRadius}
                v-${diffY - borderRadius / 2}
                a${borderRadius},${borderRadius} 0 0 0 -${borderRadius},-${borderRadius}
                h-${diffX - blockWidth - borderRadius}
                a${borderRadius},${borderRadius} 0 0 0 -${borderRadius},${
          borderRadius / 2
        }
     
     
     `,
        reversed: false,
      };
    case 'rightLower':
      return {
        lineStr: `M${parentX - blockWidth},${
          parentY + blockHeight / 4 - 1
        }
                 v8
                        // a-${borderRadius},-${borderRadius} 1 0 0 ${borderRadius},${borderRadius}
                        // h${blockWidth / 2 + borderRadius}
                        // a${borderRadius},${borderRadius} 0 0 1 ${borderRadius},${borderRadius}
                        // v${
                          diffY * -1 -
                          blockHeight / 2 -
                          borderRadius / 2
                        }
                        // a${borderRadius},${borderRadius} 0 0 1 -${borderRadius},${borderRadius}
                        // h-${diffX - blockWidth - borderRadius}
                        // a${borderRadius},${borderRadius} 0 0 0 -${borderRadius},${
          borderRadius / 2
        }
  
    `,
        reversed: false,
      };
      case 'right':
        return {
          lineStr: `M${parentX - blockWidth},${
            parentY + blockHeight / 4 - 1
          }
                            a-${borderRadius},-${borderRadius} 1 0 0 ${borderRadius},${borderRadius}
                            h${blockWidth / 2 + borderRadius}
                            a${borderRadius},${borderRadius} 0 0 1 ${borderRadius},${borderRadius}
                            v${
                              diffY * -1 -
                              blockHeight / 2 -
                              borderRadius / 2
                            }
                            a${borderRadius},${borderRadius} 0 0 1 -${borderRadius},${borderRadius}
                            h-${diffX - blockWidth - borderRadius}
                            a${borderRadius},${borderRadius} 0 0 0 -${borderRadius},${
            borderRadius / 2
          }

        `,
          reversed: false,
        };
      case 'leftUpper':
        return {
          lineStr: `M${parentX - blockWidth + 6},${
            parentY + blockHeight - 6
          }
          v6
                          a-${borderRadius / 2},${
            borderRadius / 2
          } 1 0 0 ${borderRadius / 2},${borderRadius / 2 - 1}
                          h${blockWidth / 2 + borderRadius}
          
                          h${diffX * -1 + blockWidth - 48}
                            a${borderRadius / 2},${
            borderRadius / 2
          } 0 0 1 ${borderRadius / 2},${borderRadius / 2}
v2
      `,
          reversed: false,
        };
      case 'leftLower':
        return {
          lineStr: `M${parentX - blockWidth + 6},${
           parentRoot ? 58 : parentY + blockHeight - 6
          }
          
                            a-${borderRadius / 2},${
            borderRadius / 2
          } 1 0 0 ${borderRadius / 2},${borderRadius / 2}
                            h${blockWidth  - 18}
                            a${borderRadius / 2},${
            borderRadius / 2
          } 0 0 1 ${borderRadius / 2},${borderRadius / 2}
                            v${diffY * -1 - (parentRoot ? blockHeight + 15 : blockHeight  + 5)}
                           a-${borderRadius / 2},-${
            borderRadius / 2
          } 1 0 0 ${borderRadius / 2},${borderRadius / 2}
                            h${diffX * -1 -8}
                              a${borderRadius / 2},${
            borderRadius / 2
          } 0 0 1 ${borderRadius / 2},${borderRadius / 2}
v2

        `,
          reversed: false,
        };
      default:
        return null;
    };
};