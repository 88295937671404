<template>
  <v-card width="100%" class="regular-14">
    <v-card-title>{{ $t('compare.title') }}</v-card-title>
    <v-card-text v-if="localModel">
      <div>
        <div>
          <label>{{ $t('playground.site') }}</label>
          <v-radio-group density="compact" v-model="siteModel">
            <v-radio
              v-for="site in siteItems"
              :key="site.id"
              :label="site.text"
              :value="site.id"
              @change="updateLocalModel('compare_pgid', site.id)"
               color="blue"
            ></v-radio>
          </v-radio-group>
        </div>

        <div>
          <label>{{ $t('compare.aveloRange') }}</label>
          <v-checkbox
            density="compact"
            class="my-0 py-0"
            v-for="range in rangeItems"
            :key="range.id"
            :label="range.text"
            :value="range.id"
            v-model="rangeModel"
            hide-details
             color="blue"
          />
        </div>

        <div>
          <label>{{ $t('compare.timeControl') }}</label>
          <v-checkbox
            density="compact"
            class="my-0 py-0"
            v-for="tc in timeControlItems"
            :key="tc.id"
            :label="tc.text"
            :value="tc.id"
            v-model="timeControlModel"
            hide-details
             color="blue"
          />
        </div>

        <div>
          <label>{{ $t('compare.rivals') }}</label>
          <v-checkbox
            density="compact"
            class="my-0 py-0"
            v-for="rival in rivalsItems"
            :key="rival.id"
            :label="rival.text"
            :value="rival.id"
            v-model="rivalsModel"
            hide-details
            color="blue"
          />
        </div>
      </div>
    </v-card-text>
    <div
      class="d-flex align-center justify-center semibold-14"
      style="gap: 1em"
    >
      <v-btn size="small" @click="reset">Сбросить</v-btn>
      <v-btn color="blue" size="small" @click="handleSave">Применить</v-btn>
    </div>
  </v-card>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { mapState, mapActions } from 'vuex';
import fixPrefs from '@/helpers/fixPrefs';

export default {
  props: ['text'],
  data: () => ({
    localModel: {
      compare_pgid: 2,
      compare_elo: [],
      compare_tc: [],
      compare_ed: [],
    },
  }),
  computed: {
    ...mapState('data', ['compareFilters']),
    ...mapState('ui', ['userPreferences']),

    siteItems() {
      return [
        { id: 1, text: this.$t('playground.pg1') },
        { id: 2, text: this.$t('playground.pg2') },
        { id: 3, text: this.$t('playground.pg3') },
      ];
    },

    rivalsItems() {
      return [
        { id: '4', text: this.$t('compare.ed4') },
        { id: '3,4,5', text: this.$t('compare.ed345') },
        { id: '2,3,4,5,6', text: this.$t('compare.ed23456') },
      ];
    },

    siteModel: {
      get() {
        return this.localModel?.compare_pgid || 2;
      },
      set(val) {
        this.updateLocalModel('compare_pgid', val);
      },
    },

    rangeItems() {
      const pgid = this.localModel?.compare_pgid;
      if (!pgid || pgid === 1) return [];
      return [
        { id: 2100, text: pgid === 2 ? '1800-2199' : '2000-2399' },
        { id: 2500, text: pgid === 2 ? '2200-2599' : '2400-2799' },
        { id: 2900, text: pgid === 2 ? '2600+' : '2800+' },
      ];
    },

    rangeModel: {
      get() {
        return this.localModel?.compare_elo || [];
      },
      set(val) {
        this.updateLocalModel('compare_elo', val);
      },
    },

    timeControlItems() {
      const pgid = this.localModel?.compare_pgid;
      if (!pgid) return [];
      if (pgid === 1) return [{ id: 1, text: '-' }];
      return [
        { id: 2, text: this.$t('compare.tc2_2') },
        { id: 3, text: this.$t('compare.tc2_3') },
        { id: 4, text: this.$t('compare.tc2_4') },
      ];
    },

    timeControlModel: {
      get() {
        return this.localModel?.compare_tc || [];
      },
      set(val) {
        this.updateLocalModel('compare_tc', val);
      },
    },

    rivalsModel: {
      get() {
        const compare_ed = this.localModel?.compare_ed || [];
        return (
          this.rivalsItems.find((i) => i.id === compare_ed.join(','))?.id || []
        );
      },
      set(val) {
        const clone = cloneDeep(this.localModel);
        clone.compare_ed = val.split(',');
        this.localModel = clone;
      },
    },
  },
  methods: {
    ...mapActions('data', ['updateCompareFilters']),
    ...mapActions('ui', ['setUserPreferences']),
    reset() {
      this.localModel = cloneDeep(this.compareFilters);
    },
    handleSave() {
      this.updateCompareFilters({
        filters: this.localModel,
        heroid: this.$route.params.heroid,
      });
      this.setUserPreferences(
        cloneDeep({ ...this.userPreferences, ...fixPrefs(this.localModel) }),
      );
    },
    updateLocalModel(key, value) {
      const clone = cloneDeep(this.localModel);
      clone[key] = value;
      this.localModel = clone;
    },
  },
  watch: {
    userPreferences: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.localModel = cloneDeep(val);
        }
      },
    },
  },
};
</script>

<style scoped></style>
