import { createI18n } from 'vue-i18n';

export const L10n = {
  "en": {
    "langid": 1,
    "login": {
      "signin": "Sign in",
      "signup": "Register",
      "remember": "Remember me",
      "main": "Login to continue",
      "name": "Name",
      "email": "Email",
      "password": "Password",
      "forgotPassword": "Forgot password?",
      "accept": "Login",
      "payment": "Join membership",
      "confirmPassword": "Confirm password",
      "phone": "Phone",
      "longPassword": "The password length must be at least 8 characters",
      "passwordComplexity": "The password must match uppercase and lowercase letters, numbers and special characters",
      "passwordsMatch": "The passwords entered in both passwords fields should match",
      "confirmRegister": "Complete registration"
    },
    "pages": {
      "main": "Persons and accounts",
      "mistakes": "Mistakes overview",
      "tree": "Tree of opening theory",
      "training": "Training"
    },
    "accountMenu": {
      "settings": "Settings",
      "preferences": "Preferences",
      "logoutall": "Log out of all devices",
      "logout": "Log out"
    },
    "common": {
      "like": "Like",
      "copied": "Copied!",
      "feedback": "Feedback",
      "aboutCompany": "About the company",
      "footer": "2024 Science64 LLP. All rights reserved.",
      "fieldRequired": "This field is required",
      "ok": "OK",
      "cancel": "Cancel",
      "apply": "Apply",
      "reset": "Reset",
      "dateFrom": "Start date",
      "dateTo": "End date",
      "all": "all",
      "allCapital": "All"
    },
    "card": {
      "addPerson": "Add person",
      "editPerson": "Edit person",
      "removePerson": "Remove the person",
      "heroname": "Person name",
      "description": "Description"
    },
    "cardSummary": {
      "accounts": "Accounts",
      "editAccount": "Edit account",
      "addAccount": "Add account",
      "loadGames": "Load games",
      "uploadButton": "Upload",
      "name": "Name",
      "gamesLoaded": "Games loaded",
      "analysisEnabled": "Analyze",
      "noGames": "No data",
      "totalGames": "Total games",
      "analysisQuery": "Analyze",
      "debutAnalysis": "Opening analyzed",
      "mittelspielAnalysis": "Middlegame analyzed",
      "endspielAnalysis": "Endgame analyzed"
    },
    "playground": {
      "site": "Source",
      "pg1": "Correspondence games",
      "pg2": "OTB",
      "pg3": "lichess.org",
      "pg4": "chess.com"
    },
    "gameData": {
      "classic": "Classical",
      "rapid": "Rapid",
      "blitz": "Blitz",
      "bullet": "Bullet"
    },
    "opening1": {
      "legend": "Legend",
      "selectAnother": "Select another position to explore",
      "toTree": "Explore on the tree",
      "toTraining": "Go to training",
      "tree": "Tree chart",
      "list": "Simple list",
      "openingsOpen": "Open Games",
      "openingsSemiOpen": "Semi-Open Games",
      "openingsClosed": "Closed Games",
      "openingsSemiClosed": "Semi-Closed Games",
      "openingsIndian": "Indian Defences",
      "openingsFlank": "Flank and irregular openings",
      "headerBeforeHeroname": "Opening research: ",
      "headerAfterHeroname": "",
      "weak": "MISTAKES SUMMARY",
      "filters": "Filters",
      "searchStart": "Start",
      "searchStartHint": "Initial position",
      "search": "Show this",
      "searchHint": "Show tree from position given by FEN",
      "searchAfter": "Mistakes after position",
      "searchAfterHint": "List mistakes in games after position given by FEN",
      "searchFieldPrompt": "Paste FEN here",
      "side": "Side",
      "sideWhite": "White",
      "sideBlack": "Black",
      "studied": "Studied",
      "studiedYes": "Yes",
      "studiedNo": "No",
      "setStudied": "Mark as studied",
      "watchlist": "Watchlist",
      "watchlistListed": "Listed",
      "moveNum": "Moves range",
      "moveNumFrom": "Min",
      "moveNumTo": "Max",
      "opening": "Opening",
      "sortBy": "Sort by",
      "sortByTotalShortage": "Total shortage at position",
      "sortByAverageShortage": "Average shortage at position",
      "sortByNumMistakes": "Number of mistakes made",
      "sortByMoveDesc": "Move number descending",
      "sortByMoveAsc": "Move number ascending",
      "compareWithBeforeHeroname": "Compare ",
      "compareWithAfterHeroname": " with: ",
      "mistakesEco": "ECO",
      "mistakesEcoHint": "Most common ECO in games where this position has arose",
      "mistakesdEval": "ΔEval",
      "mistakesdEvalLong": "Downgrade",
      "mistakesdEvalHint": "Average shortage of evaluation in pawns value",
      "mistakesNum": "Num",
      "mistakesNumHint": "Number of games (number of mistakes made)",
      "mistakesTotalShortage": "Total shortage",
      "mistakesTotalShortageHint": "Total shortage due to mistakes",
      "mistakesStudiedStatus": "Research status",
      "mistakesStudiedStatusHint": "\"Studied\" state at position",
      "mistakesNotStudied": "Not studied",
      "mistakesNotStudiedHint": "",
      "mistakesStudied": "Studied",
      "mistakesStudiedHint": "",
      "mistakesReFail": "Fault again",
      "mistakesReFailHint": "Mistake was made again after marking as studied",
      "barMode": "Bars",
      "barModeResults": "game results",
      "barModeDecisions": "decisions",
      "displayMoves": "Display moves",
      "displayMovesBestOnly": "regular list",
      "displayMovesExtended": "extended list",
      "cTO": "Training offers",
      "tOIgnore": "don't show",
      "tOBest": "single best",
      "tOAll": "all",
      "cPractice": "Encountered in the games",
      "practiceIgnore": "don't show",
      "practiceHero": "hero's turn",
      "practiceAll": "all",
      "cEmphasis": "Other moves",
      "emphasisIgnore": "don't show",
      "hintemphasisIgnore": "Disable showing the additional moves",
      "emphasis3": "single best",
      "hintemphasis3": "Exactly one move from among the strongest",
      "emphasis2": "good",
      "hintemphasis2": "Quality moves",
      "emphasis1": "extended list",
      "hintemphasis1": "All theoretical moves",
      "flipBoard": "Flip board",
      "switchEngine": "Stockfish"
    },
    "opening2": {
      "tooSmallTree": "Task not created: current training tree is too small",
      "autoplayDelay": "autoplay delay",
      "arrowDelay": "arrow delay",
      "leading": "Repeat moves",
      "testing": "Check of knowledge",
      "pageLearn": "Learn",
      "pageTest": "Test",
      "buttonStart": "Start",
      "buttonAbort": "Stop",
      "buttonReset": "Reset",
      "labelCorrect": "Correct",
      "labelWrong": "Wrong"
    },
    "compare": {
      "separator1": ", ",
      "separator2": ", ",
      "separator3": " ",
      "title": "Comparison selector",
      "aveloRange": "Rating range",
      "timeControl": "Time control",
      "rivals": "Rivals skill comparison",
      "pg1": "Correspondence games",
      "pg2": "OTB",
      "pg3": "lichess.org",
      "pg4": "chess.com",
      "avelo1": "strong amateurs",
      "avelo2": "masters",
      "avelo3": "top",
      "tc2_2": "classical",
      "tc2_3": "rapid",
      "tc2_4": "blitz",
      "tc3_3": "classical, rapid",
      "tc3_4": "blitz",
      "ed4": "rivals are equal",
      "ed345": "rivals are comparable",
      "ed23456": "any",
      "headered4": " (equal)",
      "headered345": " (comparable)",
      "headered23456": ""
    },
    "statistics": {
      "numberOfGames": "Number of games",
      "numberOfGamesShort": "Games",
      "stat": "Statistics of the position",
      "variations": "Variations",
      "resultsPerc": "Results, %",
      "decisonsPerc": "Decisions, %",
      "move": "Move",
      "eval": "Eval",
      "moveEval": "Move (eval)",
      "frequency": "Freq.",
      "numberOfMoveMade": "Num"
    },
    "feedback": {
      "title": "Please describe your problem as detailed as possible",
      "description": "Description of the problem"
    },
    "settings": {
      "displayedName": "Displayed name"
    },
    "preferences": {
      "piecesDisplayedAs": "Pieces displayed as",
      "shortNotation": "Use short notation",
      "flipWhenBlack": "Flip board when black moves at initial position",
      "mistakesSensivity": "Mistakes sensitivity"
    },
    "moveMarking": {
      "lbHero": "Training",
      "hbydefault": "by default",
      "hchoice": "choice",
      "hignore": "ignore",
      "lbOpp": "Training",
      "obydefault": "by default",
      "ochoice": "consider",
      "oignore": "ignore"
    },
    "pieces": {
      "N": "N",
      "B": "B",
      "R": "R",
      "Q": "Q",
      "K": "K"
    },
    "loginPage": {
      "signin": "Log in", 
      "signup": "Sign Up",
      "enterEmail": "Enter your email",
      "enterPassword": "Enter your password",
      "confirmRegister": "Sign up",
      "dontHaveAccount":"Don’t have an account?",
      "logInViaGoogle":"Log in with Google"
    },
    "register": {
      "signup": "Sign Up",
      "signupGoogle": "Sign up with Google",
      "title": "Sign up",
      "enterEmail": "Email",
      "enterName": "Your name",
      "enterPhone": "Phone number (optional)",
      "enterPassword": "Create a password",
      "confirmPassword": "Confirm your password",
      "alreadyHaveAccout":"Already have an account?",
      "tip":"Password must be at least 8 characters long, include mixed case letters, numbers, and a special character",
      "agreement":"I give my consent to the processing of my personal data and agree to the ", 
      "pivacy":"Privacy policy",
      "and":" and ",
      "terms":"Terms of use", 
      "confirmationTitle":"Almost done!",
      "confirmationText":"To complete your registration, please confirm your email address by clicking the link in the email sent to"
    },
    "registerConfirmation":{
      "title":"Almost done!",
      "text":"To complete your registration, please confirm your email address by clicking the link in the email sent to",
      "incorrectEmail":"Incorrect email?",
      "backToSign":"Back to Sign Up form",
      "noMail":"Didn’t receive confirmation email?",
      "resend":"Resend confirmation link",
      "problem":"Problem occured", 
      "expired":"This confirmation link has expired",
      "successTitle":"Your email has been successfully confirmed",
      "successText":"You can now use all the features of our service",
      "login":"Log in"
    },
    "recovery":{
      "title": "Forgot password?",
      "enterEmail": "Enter your email",
      "submit":"Send request",
      "returnTo":"Remembered password?",
      "login":"Log in"
    },
    "recoveryConfirmation":{
      "title":"Check your mail box",
      "text":"Password recovery instructions were sent to ",
      "continue":"Continue"
    },
    "changePassword":{
      "title":"Change password",
      "enterPassword": "Create new password",
      "confirmPassword": "Confirm new password",
      "submit":"Save changes",
      "tip":"New password must be different from the previous one and contain at least 8 characters long",
      "expired":"This confirmation link has expired",
      "problem":"Problem occured",
      "resend":"Resend",
      "success":"Password has been successfully changed",
      "login":"Log in"
    },
    "rules":{
      "confirmpwd":"Please confirm your password",
      "dontMatch":"Passwords do not match",
      "enterEmail":"Enter your email",
      "enterPwd":"Enter your password",
      "enterName":"Enter your name",
      "fieldRequired":"This field is required",
      "emailMin":"Email must be at least ",
      "pwdMin":"Password must be at least ",
      "nameMin":"Name must be at least ",
      "phoneMin":"Phone number must be at least ",
      "phoneMax":"Phone number must be no more than ",
      "phoneInvalid":"Phone number contains invalid characters",
      "valueMin":"Value must be at least ",
      "minPostfix":" characters",
      "pwdUpper":"Password must contain at least one uppercase letter",
      "pwdNumber":"Password must contain at least one digit",
      "wrongPassword": "Incorrect password",
      "incorrectEmail": "Incorrect email address",
      "emailNotRegistered": "Specified email address not registered",
      "getName": "How should we address you?"
    }
   },
  "ru": {
    "langid": 4,
    "login": {
      "signin": "Вход",
      "signup": "Регистрация",
      "remember": "Запомнить меня",
      "main": "Войдите, чтобы продолжить",
      "name": "Имя",
      "email": "Адрес электронной почты",
      "password": "Пароль",
      "forgotPassword": "Забыли пароль?",
      "accept": "Войти",
      "payment": "Оформите подписку",
      "confirmPassword": "Подтверждение пароля",
      "phone": "Телефон",
      "longPassword": "Длина пароля должна быть не менее 8 символов",
      "passwordComplexity": "Пароль должен содержать строчные и заглавные буквы, цифры и специальные символы",
      "passwordsMatch": "Пароли должны совпадать",
      "confirmRegister": "Зарегистрироваться"
    },
    "pages": {
      "main": "Герои и аккаунты",
      "mistakes": "Обзор ошибок",
      "tree": "Дерево дебютной теории",
      "training": "Тренировка"
    },
    "accountMenu": {
      "settings": "Настройки",
      "preferences": "Установки",
      "logoutall": "Выйти со всех устройств",
      "logout": "Выйти"
    },
    "common": {
      "like": "Нравится",
      "copied": "Скопировано!",
      "feedback": "Обратная связь",
      "aboutCompany": "О компании",
      "footer": "2024 Science64 LLP. All rights reserved.",
      "fieldRequired": "Поле обязательно для заполнения",
      "ok": "OK",
      "cancel": "Отменить",
      "apply": "Применить",
      "reset": "Сбросить",
      "dateFrom": "Начальная дата",
      "dateTo": "Конечная дата",
      "all": "все",
      "allCapital": "Все"
    },
    "card": {
      "addPerson": "Добавить героя",
      "editPerson": "Редактировать героя",
      "removePerson": "Удалить героя",
      "heroname": "Имя героя",
      "description": "Описание"
    },
    "cardSummary": {
      "accounts": "Аккаунты",
      "editAccount": "Редактировать аккаунт",
      "addAccount": "Добавить аккаунт",
      "loadGames": "Загрузить партии",
      "uploadButton": "Загрузить",
      "name": "Имя",
      "gamesLoaded": "Загружено партий",
      "analysisEnabled": "Анализировать",
      "noGames": "Нет данных",
      "totalGames": "Всего партий",
      "analysisQuery": "Анализировать",
      "debutAnalysis": "Анализ дебюта",
      "mittelspielAnalysis": "Анализ миттельшпиля",
      "endspielAnalysis": "Анализ эндшпиля"
    },
    "playground": {
      "site": "Источник",
      "pg1": "Заочные партии",
      "pg2": "Игра вживую",
      "pg3": "lichess.org",
      "pg4": "chess.com"
    },
    "gameData": {
      "classic": "Классика",
      "rapid": "Быстрые",
      "blitz": "Блиц",
      "bullet": "Пуля"
    },
    "opening1": {
      "legend": "Легенда",
      "selectAnother": "Выбрать другую позицию для изучения",
      "toTree": "Разобрать на дереве",
      "toTraining": "Перейти к тренировке",
      "tree": "Дерево",
      "list": "Только список",
      "openingsOpen": "Открытые дебюты",
      "openingsSemiOpen": "Полуоткрытые дебюты",
      "openingsClosed": "Закрытые дебюты",
      "openingsSemiClosed": "Полузакрытые дебюты",
      "openingsIndian": "Индийские защиты",
      "openingsFlank": "Фланговые дебюты и неправильные начала",
      "headerBeforeHeroname": "Исследование дебюта: ",
      "headerAfterHeroname": "",
      "weak": "ОБЗОР ОШИБОК",
      "filters": "Фильтры",
      "searchStart": "Старт",
      "searchStartHint": "Начальная позиция",
      "search": "Показать",
      "searchHint": "Отобразить варианты, начиная с заданной через FEN позиции",
      "searchAfter": "Ошибки в партиях с позицией",
      "searchAfterHint": "Перечень ошибок, которые совершались в тех партиях, где встречалась заданная через FEN позиция",
      "searchFieldPrompt": "Вставьте FEN сюда",
      "side": "Сторона",
      "sideWhite": "Белые",
      "sideBlack": "Чёрные",
      "studied": "Изучено",
      "studiedYes": "Да",
      "studiedNo": "Нет",
      "setStudied": "Отметить как изученное",
      "watchlist": "Наблюд.",
      "watchlistListed": "В списке",
      "moveNum": "Диапазон ходов",
      "moveNumFrom": "Мин",
      "moveNumTo": "Макс",
      "opening": "Дебют",
      "sortBy": "Сортировка",
      "sortByTotalShortage": "Сумма потерь в позиции",
      "sortByAverageShortage": "Средние потери в позиции",
      "sortByNumMistakes": "Количество совершённых ошибок",
      "sortByMoveDesc": "Номер хода по убыванию",
      "sortByMoveAsc": "Номер хода по возрастанию",
      "compareWithBeforeHeroname": "Сравнить ",
      "compareWithAfterHeroname": " против: ",
      "mistakesEco": "ECO",
      "mistakesEcoHint": "ECO большинства партий, где встречалась эта позиция",
      "mistakesdEval": "ΔОц",
      "mistakesdEvalLong": "Ухудшение",
      "mistakesdEvalHint": "Средние потери оценки позиции в количестве пешек",
      "mistakesNum": "Кол",
      "mistakesNumHint": "Количество партий (количество совершённых ошибок)",
      "mistakesTotalShortage": "Общий ущерб",
      "mistakesTotalShortageHint": "Общая сумма потерь из-за совершённых в позиции ошибок",
      "mistakesStudiedStatus": "Состояние",
      "mistakesStudiedStatusHint": "Состояние изученности позиции",
      "mistakesNotStudied": "Не изучено",
      "mistakesNotStudiedHint": "",
      "mistakesStudied": "Изучено",
      "mistakesStudiedHint": "",
      "mistakesReFail": "Вновь ошибки",
      "mistakesReFailHint": "Ошибка совершается снова после того, как позиция отмечена изученной",
      "barMode": "Гистограммы",
      "barModeResults": "результаты партий",
      "barModeDecisions": "принятые решения",
      "displayMoves": "Показ ходов",
      "displayMovesBestOnly": "обычный список",
      "displayMovesExtended": "расширенный список",
      "cTO": "Предложения по обучению",
      "tOIgnore": "не отображать",
      "tOBest": "один лучший",
      "tOAll": "все",
      "cPractice": "Встречалось в партиях",
      "practiceIgnore": "не отображать",
      "practiceHero": "при ходе героя",
      "practiceAll": "все",
      "cEmphasis": "Прочие ходы",
      "emphasisIgnore": "не отображать",
      "hintemphasisIgnore": "Отключить отображение дополнительных ходов",
      "emphasis3": "один лучший",
      "hintemphasis3": "Ровно один ход из числа сильнейших",
      "emphasis2": "хорошие",
      "hintemphasis2": "Качественные ходы",
      "emphasis1": "расширенный список",
      "hintemphasis1": "Все теоретические ходы",
      "flipBoard": "Повернуть доску",
      "switchEngine": "Stockfish"
    },
    "opening2": {
      "tooSmallTree": "Задание не создано: текущее дерево тренировки слишком мало",
      "autoplayDelay": "задержка автоигры",
      "arrowDelay": "задержка стрелки",
      "leading": "Повторение ходов",
      "testing": "Проверка знаний",
      "pageLearn": "Обучение",
      "pageTest": "Тест",
      "buttonStart": "Начать",
      "buttonAbort": "Остановить",
      "buttonReset": "Обнулить",
      "labelCorrect": "Правильно",
      "labelWrong": "Ошибочно"
    },
    "compare": {
      "separator1": ", ",
      "separator2": ", ",
      "separator3": " ",
      "title": "Параметры сравнения",
      "aveloRange": "Рейтинг",
      "timeControl": "Контроль времени",
      "rivals": "Уровень соперников между собой",
      "pg1": "Заочные партии",
      "pg2": "Игра вживую",
      "pg3": "lichess.org",
      "pg4": "chess.com",
      "avelo1": "крепкие середняки",
      "avelo2": "мастера",
      "avelo3": "топ",
      "tc2_2": "классика",
      "tc2_3": "быстрые",
      "tc2_4": "блиц",
      "tc3_3": "классика, быстрые",
      "tc3_4": "блиц",
      "ed4": "соперники равны",
      "ed345": "соперники сопоставимы",
      "ed23456": "любые",
      "headered4": " (равные)",
      "headered345": " (сопоставимые)",
      "headered23456": ""
    },
    "statistics": {
      "numberOfGames": "Кол-во партий",
      "numberOfGamesShort": "Партий",
      "stat": "Статистика позиции",
      "variations": "Варианты",
      "resultsPerc": "Результаты, %",
      "decisonsPerc": "Решения, %",
      "move": "Ход",
      "eval": "Оценка",
      "moveEval": "Ход (оценка)",
      "frequency": "Доля",
      "numberOfMoveMade": "Кол."
    },
    "feedback": {
      "title": "Пожалуйста, опишите проблему по возможности подробно",
      "description": "Описание"
    },
    "settings": {
      "displayedName": "Отображаемое имя"
    },
    "preferences": {
      "piecesDisplayedAs": "Отображение фигур",
      "shortNotation": "Использовать короткую нотацию",
      "flipWhenBlack": "Повернуть доску, когда в исходной позиции ход чёрных",
      "mistakesSensivity": "Чувствительность к ошибкам"
    },
    "moveMarking": {
      "lbhero": "Тренировка",
      "hbydefault": "по умолч.",
      "hchoice": "выбрать",
      "hignore": "игнор.",
      "lbOpp": "Тренировка",
      "obydefault": "по умолч.",
      "ochoice": "учитывать",
      "oignore": "игнор."
    },
    "pieces": {
      "N": "К",
      "B": "С",
      "R": "Л",
      "Q": "Ф",
      "K": "Кр"
    },
    "loginPage": {
      "signin": "Вход",
      "signup": "Регистрация",
      "enterEmail": "Введите свой email",
      "enterPassword": "Введите свой пароль",
      "confirmRegister": "Регистрация",
      "dontHaveAccount":"Нет учётной записи?",
      "logInViaGoogle":"Войти с аккаунтом Google"
    },
    "register": {
      "signup": "Регистрация",
      "signupGoogle": "Регистрация через аккаунт Google",
      "title": "Регистрация",
      "enterEmail": "Адрес электронной почты",
      "enterName": "Имя",
      "enterPhone": "Номер телефона (необязательно)",
      "enterPassword": "Создайте пароль",
      "confirmPassword": "Подтверждение пароля",
      "alreadyHaveAccout":"Already have an account?",
      "tip": "Пароль должен состоять не менее чем из 8 символов: строчные и заглавные буквы, цифры и специальные символы",
      "agreement":"Я даю согласие на обработку моих персональных данных, и соглашаюсь с ", 
      "pivacy":"политикой конфиденциальности",
      "and":" и ",
      "terms":"условиями использования.", 
      "confirmationTitle":"Почти готово!",
      "confirmationText":"Для завершения процесса регистрации подтвердите указанный Вами адрес электронной почты"
    },
    "registerConfirmation":{
      "title":"Почти готово!",
      "text":"Для завершения процесса регистрации подтвердите указанный Вами адрес электронной почты",
      "incorrectEmail":"Неправильно указан email?",
      "backToSign":"Вернуться к форме регистрации",
      "noMail":"Не получили на email письмо со ссылкой для подтверждения?",
      "resend":"Отправить письмо со ссылкой для подтверждения снова",
      "problem":"Что-то произошло", 
      "expired":"Данная ссылка для подтверждения устарела",
      "successTitle":"Ваш адрес электронной почты успешно подтверждён",
      "successText":"Теперь Вы можете пользоваться всеми возможностями нашего сервиса",
      "login":"Log in"
    },
    "recovery":{
      "title": "Забыли пароль?",
      "enterEmail": "Введите свой email",
      "submit":"Отправить запрос",
      "returnTo":"Вспомнили пароль?",
      "login": "Вход"
    },
    "recoveryConfirmation":{
      "title":"Проверьте указанную Вами при регистрации почту",
      "text":"Инструкции по восстановлению пароля были отправлены на ",
      "continue":"Продолжить"
    },
    "changePassword":{
      "title":"Сменить пароль",
      "enterPassword": "Создайте новый пароль",
      "confirmPassword": "Подтвердите новый пароль",
      "submit":"Сохранить изменения",
      "tip":"Новый пароль должен отличаться от предыдущего и состоять не менее чем из 8 символов: строчные и заглавные буквы, цифры и специальные символы",
      "expired":"Данная ссылка для подтверждения устарела",
      "problem":"Что-то произошло",
      "resend":"Отправить письмо со ссылкой для подтверждения снова",
      "success":"Пароль был успешно изменён",
      "login": "Вход"
    },
    "rules":{
      "confirmpwd":"Пожалуйста, подтвердите пароль",
      "dontMatch":"Пароли не совпадают",
      "enterEmail":"Введите email",
      "enterPwd":"Введите пароль",
      "enterName":"Введите имя",
      "fieldRequired":"Данное поле обязательно к заполнению",
      "emailMin":"Email должен содержать не менее ",
      "pwdMin":"Пароль должен содержать не менее ",
      "nameMin":"Имя должно содержать не менее ",
      "phoneMin":"Номер телефона должен содержать не менее ",
      "phoneMax":"Номер телефона не должен быть длиннее ",
      "phoneInvalid":"Номер телефона содержит недопустимые символы",
      "valueMin":"Значение должно быть не менее ",
      "minPostfix":" символов",
      "pwdUpper":"Пароль должен содержать по меньшей мере одну заглавную латинскую букву",
      "pwdNumber":"Пароль должен содержать по меньшей мере одну цифру",
      "wrongPassword": "Пароль неверен",
      "incorrectEmail": "Адрес электронной почты некорректен",
      "emailNotRegistered": "Адрес электронной почты не зарегистрирован",
      "getName": "Как можно к Вам обращаться?"
    }
   }
};

export const i18n = createI18n({
  locale: 'en', // set locale
  fallbackLocale: 'ru', // set fallback locale
  messages: L10n, // set locale messages
  // If you need to specify other options, you can set other options
  // ...
});

