<template>
  <v-container
    style="height: 100%; width: 100%"
    class=" align-center justify-center d-flex"
  >
    <v-sheet>
      <div class="h2">
        ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ИНТЕЛЛЕКТУАЛЬНЫЕ СИСТЕМЫ"
      </div>
      <div class="my-4">Разработка аналитического программного обеспечения</div>
      <div class="my-4">ИНН: 2801277294</div>
      <div class="my-4">КПП: 280101001</div>
      <div>
        Электронная почта:
        <a href="mailto:solutions.chess@gmail.com">
          solutions.chess@gmail.com
        </a>
      </div>
      <div class="my-4">
        Адрес: Амурская область, г.Благовещенск уг.Горького 174 офис 407
      </div>
     <v-img class="my-2" src="/images/map.JPG" width="50vw">

     </v-img>
    </v-sheet>
  </v-container>
</template>

<script>
export default {};
</script>

<style scoped></style>
