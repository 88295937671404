<template>
  <v-row align="center">
    <v-col
      cols="auto"
      class="pl-2"
    > 
      <CustomCheckbox
        :model-value="modelValue"
        color="primary"
        hide-details
        @update:model-value="handleCheckboxChange"
        density="compact"
        :isChecked="checked"
      /> 
    </v-col>
    <v-col class="pa-0">
      <div 
        v-html="$t('register.agreement')+`<a href='https://example.com/privacy-policy' class='clickable-link'>`+$t('register.pivacy')+`</a>`+$t('register.and')+`<a href='https://example.com/terms-of-use' class='clickable-link'>`+$t('register.terms')+`</a>`"
        class="checkbox-label pl-1"  
      ></div>
    </v-col>
  </v-row>
</template>

<script setup>
import { ref, watch } from 'vue';
import CustomCheckbox from '../Common/CustomCheckbox.vue';
  
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  font: {
    type: String,
    default: 'Arial'
  },
  fontSize: {
    type: String,
    default: '14px'
  }
});
 
const emit = defineEmits(['check', 'uncheck']);
 
const checked = ref(props.modelValue);
 
watch(() => props.modelValue, (newValue) => {
  checked.value = newValue;
});
 
const handleCheckboxChange = (newValue) => {
  checked.value = newValue;
  emit(newValue ? 'check' : 'uncheck', newValue);  
};

 
</script>

<style scoped>
.checkbox-label {
  color: var(--text-secondary, #9A9A9A);
 
  font-family: "Source Sans 3";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;  
  }

  .clickable-link {
    color: var(--text-primary, #F4F4F4);
  
  font-family: "Source Sans 3";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  }

  .clickable-link:hover {
    color: #F4F4F4;
  }

 

</style>
