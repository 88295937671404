<template>
  <circle
    :fill="getDropShadow"
    stroke="rgba(55,55,55,.6)"
    :stroke-width="selected ? 4 : 1"
    :cx="x - blockSize "
    :cy="y - blockSize"
    :r="blockSize / 2"
    @click="handleClick(item)"
    @mouseleave="handleTooltip(null)"
    @mouseenter="e => handleTooltip({left: e.offsetX + 800, bottom: 6, fen: item.fen})"
  />
</template>

<script>
export default {
  props: ['item', 'x', 'y', 'blockSize' , 'selected', 'handleTooltip', 'handleClick'],
  computed: {
    getDropShadow() {
      const item  = this.item
      if(!item) return 'white'
      const {default_training , move_count_hero, status } = item

      if(!item || !item.showBg) {
        return 'white'
      }

      const mapper = ['white', 'rgba(	52, 170, 220, .2)', 'rgba(134, 138, 105, .3)', 'rgba(	33, 230, 79, .2 )']

        if (status === 1) {
        return 'white';
      }

      //ход героя
      if(move_count_hero > 0) {
        //если consider или default

        
         if (status === 0 || (status === 2 && default_training === 1)) {
          return  'rgba(134, 138, 105, .3)'
        }
         return 'white'
       // ход оппонента
      } else {
         //если consider или default
        if (status === 0 || (status === 2 && default_training === 1)) {
          return  'rgba(	33, 230, 79, .2 )'
        } 
      }


      return  'white' 
    },
  }
};
</script>

<style scoped></style>
