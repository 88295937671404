 
const prePareStatement = (t) => (array) =>{
  return array.map(item=>t(item)).join(' ') 
}

export const requiredRule  = (t) => (value, field) => {
    let message;
    switch (field) {
      case 'email':
        message =  ["rules.enterEmail"] ; 
        break;
      case 'password':
        message = ['rules.enterPwd'];
        break;
      case 'name':
        message = ['rules.enterName'];
        break;
      default:
        message = ['rules.fieldRequired'];
    }
    return !!value || prePareStatement(t)(message);
  };
   
  export const minLengthRule = (t) => (value, field, minLength = 8) => {
    let message; 
    switch (field) {
      case 'email':
        message = ['rules.emailMin', `${minLength}`, 'rules.minPostfix'];
        break;
      case 'password':
        message = ['rules.pwdMin',`${minLength}`, 'rules.minPostfix'];
        break;
      case 'name':
        message = ['rules.nameMin',`${minLength}`, 'rules.minPostfix'];
        break;
      case 'phone':
        message = ['rules.phoneMin',`${minLength}`, 'rules.minPostfix'];
        break;
      default:
        message = ['rules.valueMin',`${minLength}`, 'rules.minPostfix'];
    }
    return value.length >= minLength || prePareStatement(t)(message);
  };
 
export const phoneTooLongRule = (t) => (value, maxLength = 15) => {
    const message = ['rules.phoneMax',`${maxLength}`, 'rules.minPostfix'];
    return value.length <= maxLength || prePareStatement(t)(message);
  };
   
export const phoneInvalidCharsRule = (t) => (value) => {
    const regex = /^[\d+\-()\s]+$/; // Allow digits, +, -, (), and spaces
    const message = ['rules.phoneInvalid'];
    return regex.test(value) || prePareStatement(t)(message);
  };
  
export const emailFormatRule = (t) => (value) => {
    const message = ['Enter a valid email address.'];
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(value) || prePareStatement(t)(message);
  };
  
export const uppercaseRule = (t) => (value) => {
      const message = ['rules.pwdUpper'];
      return /[A-Z]/.test(value) || prePareStatement(t)(message);
    };
export const numberRule = (t) => (value) => {
      const message = ['rules.pwdNumber'];
      return /[0-9]/.test(value) || prePareStatement(t)(message);
    }; 

export const matchesRule = (t) => (value, matchValue) => {
    const message = ['rules.dontMatch'];
    return value === matchValue || prePareStatement(t)(message);
  };