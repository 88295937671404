<template>
  <g
    style="cursor: pointer"
    class="test"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <path
      fill="black"
      :stroke="borderColor"
      stroke-width="2"
      :d="`M${left - 12},${top + 10} h${10} a${4},${4} 0 0 1 ${4},${4}
          v${8} a${4},${4} 0 0 1 -${4},${4}
          h-${10} a${4},${4} 0 0 1 -${4},-${4}
          v-${8} a${4},${4} 0 0 1 ${4},-${4}
          z`"
    />
    <path
      v-for="(color, index) in lines"
      :key="color + index"
      :d="`M${left -12},${top + 14 + (index*4)} h${10} `"
      :stroke="color"
      stroke-width="1"
    />
  </g>
</template>

<script setup lang="ts">
import { defineProps, computed, inject, ref, watch } from 'vue';
import type { TreeItem } from '@/components/SvgTree/SvgTree.types';

enum SandwichBorderColor {
  HOVERED = '#f4f4f4',
  SELECTED = '#1D85DD',
};

const props = defineProps<{
  top: number,
  left: number,
  item: TreeItem,
}>();

const activeSandwichId = inject('activeSandwichId') as () => string;

const lines = computed(() => props.item.children.slice(0, 3)
  .map((child: TreeItem) => child?.b_arrow_color)
  .filter((color?: string) => color !== undefined));

const isActive = computed<boolean>(() => activeSandwichId() === props.item.nodethis);

const isHovered = ref<boolean>(false);

const borderColor = computed<string>(() => {
  if (isActive.value) {
    return SandwichBorderColor.SELECTED;
  }
  if (isHovered.value) {
    return SandwichBorderColor.HOVERED;
  }
  return '';
});
</script>