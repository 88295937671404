<template>
  <Teleport to="body">
  <div
    class="tooltip-with-board"
    :style="`
      ${tooltip.top ? 'top: ' + tooltip.top + 'px;' : ''}
      ${tooltip.left ? 'left: ' + tooltip.left + 'px;' : ''}
      ${tooltip.bottom ? 'bottom: ' + tooltip.bottom + 'px;' : ''}
      ${tooltip.right ? 'right: ' + tooltip.right + 'px;' : ''}`"
  >
  <v-card
    :height="`${tooltip.height}px`"
    :width="`${tooltip.width}px`"
  >
    <div class="board-wrapper" :style="`padding: ${DEFAULT_PADDING}px`">
      <advanced-board
        :size="boardSize"
        :config="{
          viewOnly: true,
          fen: tooltip && tooltip.fen,
          lastMove: getLastMoveArray(tooltip),
          orientation: tooltip && tooltip.herowhite == 0 ? 'black' : 'white',
        }"
        :key="'tooltip'"
      />
    </div>
      <v-divider/>
      <div
        class="board-info"
        :style="`padding: ${DEFAULT_PADDING}px`"
      >
        <slot />
      </div>
    </v-card>
  </div>
</Teleport>
</template>

<script setup lang="ts">
import type { TooltipParams } from '@/shared/composables/useTooltip.types';
import { computed } from 'vue';
import AdvancedBoard from './AdvancedBoard.vue';

const DEFAULT_PADDING = 12;

const props = defineProps<{ tooltip: TooltipParams }>();

const boardSize = computed(() => {
  const initialSize = props.tooltip.width  ?? 204;
  return `${initialSize - (DEFAULT_PADDING * 2) - 12 }px`;
})

// TODO: пока что any хз какой тип здесь
const getLastMoveArray = (opening: any) => {
      if (!opening || !opening.prevmove) {
        return;
      }

      return [
        opening.prevmove.substring(0, 2),
        opening.prevmove.substring(2, 4),
      ];
    };
</script>

<style scoped>
.tooltip-with-board {
  z-index: 5000;
  position: absolute;
}
:deep(.ranks), :deep(.files) {
  display: none;;
}
.board-info {
  display: flex;
}
</style>