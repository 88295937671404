<template>
  <v-container>
    <div v-if="isSubmited">
      <v-card-title class="d-flex justify-center text-h4">
        <div>{{ $t('recoveryConfirmation.title') }}</div>
      </v-card-title>
      <div
        class="pt-8"
        style="display: flex; flex-direction: column; align-items: center"
      >
        <div class="regular-text">{{ $t("recoveryConfirmation.text") }}</div>
        <div
          class="hyperlink-text pl-2"  
        >
          {{ emailModel }}
        </div>
      </div>
      <div class="pt-8">
        <CustomButton
          :label="$t('recoveryConfirmation.continue')"
          backgroundColor="#1D85DD"
          textColor="white"
          customHeight="52px"
          iconClass="mr-2"
          rounded="medium"
          block
          :disabled="false"
          size="medium"
          @click="switchModeToLogin"
        />
      </div>
      <div class="regular-text d-flex flex-row justify-center pt-8">
        <div style="text-align: right" class="text-text-secondary">
          {{ $t("recovery.returnTo") }}
        </div>
        <div
          class="hyperlink-text pl-2"
          style="text-align: left; cursor: pointer"
          @click="$emit('switch-to-login')"
        >
          {{ $t("recovery.login") }}
        </div>
      </div>
    </div>
    <div v-else>
      <v-card-title class="d-flex justify-center text-h4">
        <div>{{ $t('recovery.title') }}</div>
      </v-card-title>
      <CustomTextInput
        v-model="emailModel"
        :placeholder="$t('recovery.enterEmail')"
        :isValid="isEmailValid"
        @validate="debouncedValidateEmail"
        prependIcon="at"
        height="48px"
        class="mb-2 pt-8"
      />
      <top-three-list :records="errors.map(item=>item.message)" :rowsQty="2" />
      <CustomButton
        :label="$t('recovery.submit')"
        backgroundColor="#1D85DD"
        textColor="white"
        customHeight="52px"
        iconClass="mr-2"
        rounded="medium"
        block
        :disabled="isSubmitButtonDisabled"
        size="medium"
        @click="onSubmitRecovery"
      />
      <div class="regular-text d-flex flex-row justify-center py-12">
        <div style="text-align: right" class="text-text-secondary">
          {{ $t("recovery.returnTo") }}
        </div>
        <div
          class="hyperlink-text pl-2"
          style="text-align: left; cursor: pointer"
          @click="$emit('switch-to-login')"
        >
          {{ $t("recovery.login") }}
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import TopThreeList from './TopThreeList.vue';
import { debounce } from 'lodash';
import { requiredRule, emailFormatRule } from './rules';
import CustomTextInput from './CustomTextInput.vue';
import CustomButton from './CustomButton.vue';
export default {
  components: {
    TopThreeList,
    CustomTextInput,
    CustomButton,
  },
  data() {
    return {
      isSubmited: false,
      recoveryForm: false,
      emailModel: '',
      errors: [],
      isEmailValid:false,
    };
  },
  computed: {
    isFieldValid() {
      return (fieldName) => this.errors.filter(error => error.field === fieldName).length === 0;
    },
    isSubmitButtonDisabled() {
      return (!this.isEmailValid || !this.isFieldValid('email'));
    },
     emailRules() {
      return [
        value => requiredRule(this.$t)(value, 'email'),
        value => emailFormatRule(this.$t)(value, 'email'),
        // value => minLengthRule(value, 'email'),
      ];
    },
  },
  methods: {
    switchModeToLogin(){
     this.$emit('switch-to-login')
    },
    onSubmitRecovery() {
      console.log('submit-recovery', this.emailModel);
       this.isSubmited = true;
    },
    validateEmail() {
      if (!this.$t) return;
      this.errors = this.errors.filter(error => error.field !== 'email');
      let isValid = true;
      const checkEmpty = requiredRule(this.$t)(this.emailModel, 'email')
      if ( checkEmpty== true ){
            this.emailRules.forEach(rule => {
                const result = rule(this.emailModel);
                if (result !== true) {
                this.errors.push({ field: 'email', message: result});
                isValid = false;
                }
            });
      } else {
         this.errors.push({ field: 'email', message: checkEmpty });
          isValid = false;
      }

      this.isEmailValid = isValid;
    },
    debouncedValidateEmail: debounce(function() {
      this.validateEmail();
    }, 300),
  }
};
</script>

<style scoped>
.hyperlink-text { 
  color: var(--text-primary, #f4f4f4);
  font-family: "Source Sans 3";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.regular-text {
  color: var(--text-secondary, #9a9a9a);

  font-family: "Source Sans 3";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
</style>
