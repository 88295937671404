<template>
  <div class="top-three-list"> 
    <div v-for="(item, index) in rowsQty" :key="index" class="list-item">
      <span class="text-subtitle-2">
        {{ records[index] || '' }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    records: {
      type: Array,
      default: () => ['', '', '']
    },
    rowsQty: {
        type: Number,
        default: () => 3,
    }
  }
};
</script>

<style scoped>
.top-three-list {
  padding: 0;
  margin: 0;
}

.list-item {
  padding: 0;
  margin: 0;
  height: 16px;  
  display: flex;
  align-items: center;  
}

.text-subtitle-2 {
  color: var(--red, #DD6464); 
  font-family: "Source Sans 3";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
</style>
