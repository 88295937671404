import { ref } from 'vue';
import { TooltipParams } from './useTooltip.types';

const DEFAULT_WIDTH = 204;

export const useTooltip = (height: number, width: number = DEFAULT_WIDTH) => {
  const isTooltipShown = ref(false);
  const tooltip = ref<TooltipParams | null>(null);

  const toggleTooltip = (params?: TooltipParams) => {
    if (!params) {
      isTooltipShown.value = false;
      tooltip.value = null;
      return;
    }

    isTooltipShown.value = true;
    tooltip.value = {
      //поменял порядок, чтобы если на одной странице два разных по размерам тултипа (как на AnalysisPage) - можно было менять высоту пропсом в tooltip
      height,
      width,
      ...params,
    };
  };

  return {
    toggleTooltip,
    isTooltipShown,
    tooltip,
  };
};
