<template>
  <div
    style="width: 210px; height: 116px; background: #232323; border-radius: 8"
    @click="$emit('close')"
    class="pa-2"
  >
    <div @click="$emit('changePractice', item)" class="context-menu-item">
      <div class="mr-2">
        <svg
          width="24"
          height="20"
          viewBox="0 0 24 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          v-if="item.status === 1"
        >
          <path
            d="M4.25959 8.14692C3.98453 10.2525 3.82064 12.3711 3.76859 14.4939C6.64739 15.6964 9.40377 17.1732 11.9996 18.9039C14.5957 17.1732 17.3524 15.6964 20.2316 14.4939C20.1795 12.3711 20.0156 10.2525 19.7406 8.14692M19.7406 8.14692C20.6156 7.85292 21.5026 7.58092 22.3986 7.33292C19.1352 5.04517 15.6516 3.08881 11.9996 1.49292C8.34758 3.08914 4.86398 5.04584 1.60059 7.33392C2.49386 7.58055 3.38019 7.85165 4.25859 8.14692C6.92721 9.04413 9.51649 10.162 11.9996 11.4889C14.4823 10.162 17.0723 9.04412 19.7406 8.14692ZM6.74959 12.9999V9.32492C8.44632 8.27026 10.1989 7.30818 11.9996 6.44292M4.99259 17.9929C5.55056 17.4363 5.99302 16.7749 6.29455 16.0468C6.59608 15.3186 6.75072 14.538 6.74959 13.7499V12.2499"
            stroke="#F4F4F4"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <svg
          v-else
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.6999 2.80505C11.7946 2.76375 11.8967 2.74243 11.9999 2.74243C12.1032 2.74243 12.2053 2.76375 12.2999 2.80505C15.998 4.42145 19.5255 6.40294 22.8299 8.72005C22.9464 8.80171 23.0372 8.91481 23.0918 9.04614C23.1464 9.17748 23.1626 9.32163 23.1384 9.46179C23.1141 9.60195 23.0506 9.73233 22.9551 9.83773C22.8596 9.94312 22.736 10.0192 22.5989 10.057C19.1683 11.0045 15.8488 12.316 12.6969 13.969L12.6939 13.971C12.5799 14.031 12.4669 14.09 12.3539 14.151C12.2452 14.2092 12.1238 14.2396 12.0004 14.2396C11.8771 14.2396 11.7557 14.2092 11.6469 14.151C10.2951 13.4296 8.91135 12.7696 7.49994 12.173V11.949C7.49994 11.818 7.56694 11.701 7.67194 11.638C9.18131 10.7234 10.7341 9.88237 12.3249 9.11805C12.5042 9.03185 12.6419 8.87796 12.7078 8.69024C12.7736 8.50252 12.7621 8.29633 12.6759 8.11704C12.5897 7.93776 12.4359 7.80006 12.2481 7.73423C12.0604 7.66841 11.8542 7.67985 11.6749 7.76605C10.0407 8.55133 8.44552 9.41534 6.89494 10.355C6.67434 10.4889 6.48403 10.6672 6.33614 10.8786C6.18824 11.09 6.08601 11.3299 6.03594 11.583C4.51715 10.9986 2.97063 10.489 1.40194 10.056C1.26483 10.0182 1.14132 9.94212 1.04581 9.83673C0.9503 9.73133 0.886728 9.60095 0.862512 9.46079C0.838297 9.32063 0.854435 9.17648 0.909045 9.04514C0.963655 8.91381 1.05449 8.80071 1.17094 8.71905C4.47513 6.40236 8.00227 4.42121 11.6999 2.80505Z"
            fill="#F4F4F4"
          />
          <path
            d="M13.0598 15.4729C15.516 14.1607 18.0809 13.0626 20.7258 12.1909C20.8598 13.6049 20.9458 15.0339 20.9808 16.4749C20.9846 16.626 20.9428 16.7747 20.8607 16.9016C20.7786 17.0284 20.6601 17.1276 20.5208 17.1859C17.686 18.3699 14.9719 19.8239 12.4158 21.5279C12.2926 21.6101 12.1478 21.6539 11.9998 21.6539C11.8517 21.6539 11.707 21.6101 11.5838 21.5279C9.028 19.824 6.31415 18.3699 3.47977 17.1859C3.34037 17.1278 3.22178 17.0289 3.13952 16.9022C3.05726 16.7755 3.01518 16.6269 3.01877 16.4759C3.05377 15.0339 3.13977 13.6059 3.27377 12.1899C4.19477 12.4939 5.10377 12.8239 5.99977 13.1799V14.4499C5.7837 14.5744 5.60208 14.7508 5.47133 14.9632C5.34058 15.1755 5.26483 15.4171 5.25094 15.666C5.23704 15.915 5.28543 16.1635 5.39173 16.3891C5.49804 16.6147 5.6589 16.8102 5.85977 16.9579C5.76977 17.3379 5.63777 17.7109 5.46277 18.0679C5.91477 18.2809 6.36377 18.5019 6.80877 18.7279C7.05961 18.2177 7.24475 17.6777 7.35977 17.1209C7.61265 17.0085 7.8295 16.8283 7.98632 16.6003C8.14313 16.3722 8.2338 16.1052 8.24829 15.8289C8.26278 15.5525 8.20053 15.2775 8.06843 15.0343C7.93632 14.7911 7.73951 14.5892 7.49977 14.4509V13.8059C8.66787 14.3162 9.81543 14.8723 10.9398 15.4729C11.2659 15.6471 11.63 15.7383 11.9998 15.7383C12.3695 15.7383 12.7336 15.6471 13.0598 15.4729Z"
            fill="#F4F4F4"
          />
          <path
            d="M4.46201 19.4619C4.88201 19.0429 5.21501 18.5719 5.46201 18.0669C5.91501 18.2809 6.36401 18.5019 6.80901 18.7289C6.48181 19.3935 6.04732 19.9996 5.52301 20.5229C5.38084 20.6554 5.19279 20.7275 4.99849 20.7241C4.80419 20.7206 4.6188 20.6419 4.48139 20.5045C4.34398 20.3671 4.26526 20.1817 4.26184 19.9874C4.25841 19.7931 4.33053 19.6051 4.46301 19.4629L4.46201 19.4619Z"
            fill="#F4F4F4"
          />
        </svg>
      </div>

      {{ item.status === 1 ? 'Add to practice' : 'Remove from practice' }}
    </div>
    <div class="context-menu-item" @click="$emit('changeBookmark', item)">
      <div class="mr-2">
        <svg
          v-if="!inBookmarks"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 5C5 3.89543 5.89543 3 7 3H17C18.1046 3 19 3.89543 19 5V21L12 17.5L5 21V5Z"
            stroke="#F4F4F4"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <svg
          v-else
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 5C5 3.89543 5.89543 3 7 3H17C18.1046 3 19 3.89543 19 5V20.191C19 20.5627 18.6088 20.8044 18.2764 20.6382L12 17.5L5.72361 20.6382C5.39116 20.8044 5 20.5627 5 20.191V5Z"
            fill="#F4F4F4"
            stroke="#F4F4F4"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      {{ inBookmarks ? 'Remove from bookmarks' : 'Add to bookmarks' }}
    </div>
    <div class="context-menu-item">
      <div class="mr-2">
        <svg
          v-if="!isHidden"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.99976 3L6.58891 6.58916M20.9998 21L17.411 17.4112M13.8746 18.8246C13.2675 18.9398 12.6409 19 12.0002 19C7.52256 19 3.73226 16.0571 2.45801 12C2.80491 10.8955 3.33826 9.87361 4.02119 8.97118M9.87844 9.87868C10.4213 9.33579 11.1713 9 11.9998 9C13.6566 9 14.9998 10.3431 14.9998 12C14.9998 12.8284 14.664 13.5784 14.1211 14.1213M9.87844 9.87868L14.1211 14.1213M9.87844 9.87868L6.58891 6.58916M14.1211 14.1213L6.58891 6.58916M14.1211 14.1213L17.411 17.4112M6.58891 6.58916C8.14874 5.58354 10.0063 5 12.0002 5C16.4778 5 20.2681 7.94291 21.5424 12C20.8355 14.2507 19.3543 16.1585 17.411 17.4112"
            stroke="#F4F4F4"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      {{ isHidden ? 'Show' : 'Hide' }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['item', 'permanentlyHiddenNodes', 'bookMarks'],
  computed: {
    inBookmarks() {
      const { item, bookMarks } = this;
      return bookMarks.find((i) =>
        i.pos_to_id === item.pos_to_id && i.hm === item.hm ? true : false,
      );
    },
    isHidden() {
      const { item, permanentlyHiddenNodes } = this;
      return permanentlyHiddenNodes[item.nodethis];
    },
  },
};
</script>

<style scoped>
.context-menu-item {
  color: #f4f4f4;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
</style>
