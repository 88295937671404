<template>
  <v-menu :close-on-content-click="false">
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        size="small"
        class="text-caption d-flex align-center ma-2"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.5 3.5C2.5 2.94771 2.94772 2.5 3.5 2.5H16.5C17.0523 2.5 17.5 2.94772 17.5 3.5V5.41912C17.5 5.68434 17.3946 5.93869 17.2071 6.12623L11.9596 11.3738C11.772 11.5613 11.6667 11.8157 11.6667 12.0809V14.1667L8.33333 17.5V12.0809C8.33333 11.8157 8.22798 11.5613 8.04044 11.3738L2.79289 6.12623C2.60536 5.93869 2.5 5.68434 2.5 5.41912V3.5Z"
            stroke="#9A9A9A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <div style="color: #9a9a9a">
          {{ $t('opening1.displayMoves') }}
        </div>
      </v-btn>
    </template>
    <v-card class="pa-4">
      <div class="my-2">
        <input
          type="checkbox"
          :checked="TOMode !== 0"
          @click="TOModel = TOMode !== 0 ? 0 : 1"
        /><span>{{$t('opening1.cTO')}}</span>
        <v-radio-group v-model="TOModel">
          <v-radio
            v-for="(item, index) in TOOptions"
            :key="'to' + index"
            :label="item.title"
            :value="item.id"
          />
        </v-radio-group>
        <!-- <v-select
          density="compact"
          variant="outlined"
          item-title="title"
          item-value="id"
          hide-details
          :label="$t('opening1.cTO')"
          :items="TOOptions"
          v-model="TOMode"
          class="text-caption"
        /> -->
      </div>
      <div class="my-2">
        <input
          type="checkbox"
          :checked="practiceMode !== 0"
          @click="practiceModel = practiceMode !== 0 ? 0 : 1"
        /><span>{{$t('opening1.cPractice')}}</span>
        <v-radio-group v-model="practiceModel">
          <v-radio
            v-for="(item, index) in practiceOptions"
            :key="'pr' + index"
            :label="item.title"
            :value="item.id"
          />
        </v-radio-group>
        <!-- <v-select
          density="compact"
          variant="outlined"
          item-title="title"
          item-value="id"
          hide-details
          :label="$t('opening1.cPractice')"
          :items="practiceOptions"
          v-model="practiceMode"
          class="text-caption"
        /> -->
      </div>
      <div class="my-2">
        <input
          type="checkbox"
          :checked="emphasisMode !== 0"
          @click="emphasisModel = emphasisMode !== 0 ? 0 : 1"
        /><span>{{$t('opening1.cEmphasis')}}</span>
        <v-radio-group v-model="emphasisModel">
          <v-radio
            v-for="(item, index) in emphasisOptions"
            :key="'em' + index"
            :label="item.title"
            :value="item.id"
          />
        </v-radio-group>
        <!-- <v-select
          density="compact"
          variant="outlined"
          item-title="title"
          item-value="id"
          hide-details
          :label="$t('opening1.cEmphasis')"
          :items="emphasisOptions"
          v-model="emphasisMode"
          class="text-caption"
        /> -->
      </div>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: ['updateMode', 'TOMode', 'practiceMode', 'emphasisMode'],
  data: () => ({}),
  computed: {
    TOModel: {
      get() {
        return this.TOMode;
      },
      set(val) {
        this.updateMode('TOMode', val);
      },
    },
    practiceModel: {
      get() {
        return this.practiceMode;
      },
      set(val) {
        this.updateMode('practiceMode', val);
      },
    },
    emphasisModel: {
      get() {
        return this.emphasisMode;
      },
      set(val) {
        this.updateMode('emphasisMode', val);
      },
    },
    emphasisOptions() {
      return [
        // {
        //   id: 0,
        //   title: this.$t('opening1.emphasisIgnore'),
        // },
        {
          id: 1,
          title: this.$t('opening1.emphasis3'),
        },
        {
          id: 2,
          title: this.$t('opening1.emphasis2'),
        },
        {
          id: 3,
          title: this.$t('opening1.emphasis1'),
        },
      ];
    },
    practiceOptions() {
      return [
        // {
        //   id: 0,
        //   title: this.$t('opening1.practiceIgnore'),
        // },
        {
          id: 1,
          title: this.$t('opening1.practiceHero'),
        },
        {
          id: 2,
          title: this.$t('opening1.practiceAll'),
        },
      ];
    },
    TOOptions() {
      return [
        // {
        //   id: 0,
        //   title: this.$t('opening1.tOIgnore'),
        // },
        {
          id: 1,
          title: this.$t('opening1.tOBest'),
        },
        {
          id: 2,
          title: this.$t('opening1.tOAll'),
        },
      ];
    },
  },
};
</script>

<style scoped></style>
