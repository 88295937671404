<template>
  <g style="cursor: pointer">
    <circle
      fill="transparent"
      stroke="#9A9A9A"
      stroke-width="2"
      :cx="left - borderRadius"
      :cy="top"
      :r="borderRadius"
    />
    <g v-if="!status">
      <path
        stroke="#9A9A9A"
        stroke-width="2"
        :d="`M${left - borderRadius},${
          top - borderRadius / 2
        } v${borderRadius}z`"
      />
      <path
        stroke="#9A9A9A"
        stroke-width="2"
        :d="`M${
          left - (borderRadius + borderRadius / 2)
        },${top} h${borderRadius}z`"
      />
    </g>
    <g v-else>
      <path
        stroke="#9A9A9A"
        stroke-width="2"
        :d="`M${
          left - (borderRadius + borderRadius / 2)
        },${top} h${borderRadius}z`"
      />
    </g>
  </g>
</template>

<script>
export default {
  props: ['top', 'left', 'status', 'borderRadius'],
};
</script>

<style scoped></style>
