<template>
  <g>
    <path
      fill="none"
      :style="{ strokeWidth: getThickness }"
      :d="pathString"
      :stroke="item.ancestor ? '#9A9A9A' : getColor"
      marker-end="url(#simpleArrowEnd)"
    />
    <g v-if="backArrows">
      <g
        v-for="(backArrow, index) in backArrows"
        :key="`backArrow-${index}-${item.nodethis}`"
      >
        <path
          v-if="!backArrow.reversed"
          fill="none"
          style="strokewidth: 1; opacity: 0.7"
          :d="backArrow.lineStr"
          stroke="#9A9A9A"
          marker-end="url(#simpleArrowEnd)"
        />
        <path
          v-if="backArrow.reversed"
          fill="none"
          style="strokewidth: 1; opacity: 0.7"
          :d="backArrow.lineStr"
          stroke="blue"
          marker-start="url(#simpleArrowEnd)"
        />
      </g>
    </g>

    <text
      :x="leftPadding - blockWidth + borderRadius * 2 + 6"
      :y="topPadding + 16"
      font-size="12px"
      font-weight="bold"
      fill="#9A9A9A"
      v-if="!simpleArrows"
      style="userselect: none"
    >
      {{ item.move_count_hero || '' }}
    </text>
    <text
      :x="leftPadding - (blockWidth +8)"
      :y="topPadding + 16"
      font-size="12px"
      font-weight="bold"
      fill="#9A9A9A"
      v-if="simpleArrows && (item.t_need || item.l_need) && !item.isEnemyMove"
      style="userselect: none"
    >
      {{   item.trainMode === 1 ? item.t_passed : item.l_passed }}/{{   item.trainMode === 1 ? item.t_need : item.l_need }}
    </text>
    </g>
</template>

<script>
import { mapState } from 'vuex';
import { generateBackArrow } from './LineItem.utils';
export default {
  props: [
    'nodeparent',
    'topPadding',
    'leftPadding',
    'blockWidth',
    'blockHeight',
    'parentTopCoords',
    'item',
    'rootColor',
    'simpleArrows',
    'getBg',
    'isSelected',
    'updateSelectedArrows',
    'updatePreviewArrows',
    'hovered',
    'getCoordsFromDict',
    'blockGap',
  ],
  emits: ['highlightAncestor'],
  data: () => ({
    parentCoords: null,
    borderRadius: 8,
    backArrows: null,
  }),
  computed: {

    fixedHm() {
      return this.item?.hm;
    },
    getThickness() {
      if (this.simpleArrows) {
        return 1;
      }
      return this.item.tr_arrow_thickness;
      // return Math.ceil(this.item.b_arrow_thickness / 5) || 2
    },
    getColor() {
      if (this.getBg) {
        return this.getBg(this.item.nodethis);
      }

      if (this.simpleArrows) {
        return 'black';
      }
      return this.item.b_arrow_color || 'black';
    },
    getSimpleArrowString() {
      const { topPadding, leftPadding, blockHeight, borderRadius } = this;
      const halfBlock = blockHeight / 2;
      return `
      ${leftPadding - borderRadius * 3 + 6},${topPadding + halfBlock + 6}
      ${leftPadding - borderRadius * 4 + 14},${topPadding + halfBlock / 2 + 8} 
      ${leftPadding - borderRadius},${topPadding + halfBlock}
       ${leftPadding - borderRadius * 4 + 14},${
        topPadding + halfBlock * 1.5 - 8
      } `;
    },
    getArrowString() {
      const { topPadding, leftPadding, blockHeight, borderRadius } = this;
      const halfBlock = blockHeight / 2;
      return `
      ${leftPadding - borderRadius * 3},${topPadding + halfBlock}
      ${leftPadding - borderRadius * 4},${topPadding + halfBlock / 2 + 8} 
      ${leftPadding - borderRadius - 2},${topPadding + halfBlock}
       ${leftPadding - borderRadius * 4},${topPadding + halfBlock * 1.5 - 8} `;
    },
    getSquareString() {
      const { topPadding, leftPadding, blockHeight, borderRadius } = this;
      const halfBlock = blockHeight / 2;
      const halfBorder = borderRadius / 2;
      return `
      
      ${leftPadding - halfBorder * 3},${topPadding + halfBlock - halfBorder} 
      ${leftPadding - halfBorder * 4},${topPadding + halfBlock}
      ${leftPadding - halfBorder * 3},${topPadding + halfBlock + halfBorder} 
      ${leftPadding - halfBorder * 2},${topPadding + halfBlock}
      `;
    },
    pathString() {
      const {
        getParentX,
        fixedParentTopPosition,
        blockWidth,
        parentTopCoords,
        borderRadius,
        topPadding,
        blockHeight,
        getTopPosition,
      } = this;

      if (parentTopCoords === getTopPosition)
        return `M${getParentX - (this.simpleArrows ? 48 : 30)},${
          fixedParentTopPosition + blockHeight / 2 - 4
        } h${this.simpleArrows ? 42 : 26} `;
      if (this.simpleArrows) {
        return `M${getParentX - (this.simpleArrows ? blockWidth + 6 : 0)},${
          fixedParentTopPosition + blockHeight / 2 - 4
        } 
    h2
    a${borderRadius / 2},${borderRadius / 2} 0 0 1 ${borderRadius / 2},${
          borderRadius / 2
        }
    v${
      topPadding -
      fixedParentTopPosition +
      blockHeight / 2 -
      borderRadius * 2 +
      6
    }
    a-${borderRadius / 2},-${borderRadius / 2} 1 0 0 ${borderRadius / 2},${
          borderRadius / 2
        }
    h34
    `;
      }

      return `M${getParentX - 30},${
        fixedParentTopPosition + blockHeight / 2 - 4
      } 
              a${borderRadius},${borderRadius} 0 0 1 ${borderRadius},${borderRadius}
              v${topPadding - fixedParentTopPosition}
              a-${borderRadius},-${borderRadius} 1 0 0 ${borderRadius},${borderRadius}
              h${10}
    `;
    },
    fixedParentTopPosition() {
      const { parentTopCoords, blockHeight } = this;

      return parentTopCoords + blockHeight / 2;
    },
    getParentX() {
      const { leftPadding, blockWidth, blockGap } = this;
      if (blockGap) {
        const actual = leftPadding - 8;
        return actual < 0 ? 0 : actual;
      }
      return leftPadding - blockWidth < 0 ? 0 : leftPadding - blockWidth;
    },
    halfXPoint() {
      const { parentCoords, leftPadding, blockWidth } = this;

      if (!parentCoords) return 0;

      return leftPadding - 8 - (parentCoords[0] - 8) / 2;
    },
    getTopPosition() {
      const { topPadding, parentCoords, blockHeight } = this;
      return this.topPadding;
      //   if(!parentCoords) return topPadding
      //   const fixedTopPosition = parentCoords[1] - (blockHeight / 2)
      //   return fixedTopPosition > topPadding ? fixedTopPosition : topPadding
    },
  },
  methods: {
    getSometimesArrow(sometimesId) {
      if (this.item.allSometimesPresent) {
        return null;
      }

      const dataCoords = this.getCoordsFromDict(sometimesId);

      if (dataCoords) {
        const currentY = this.topPadding;
        const currentX = this.getParentX + this.blockWidth / 2;

        const [parentX, parentY] = dataCoords;

        const onSameY = currentY - parentY === 0;
        const onSameX = currentX - (parentX + this.blockWidth / 2) === 0;

        const { leftPadding, borderRadius, topPadding, blockHeight } = this;
        const { getParentX, fixedParentTopPosition, blockWidth } = this;
        const halfBlock = blockHeight / 2;
        const parentRoot = parentY === 18 && parentX === 79
        const diffX = parentX - currentX;
        const diffY = parentY - currentY;

        const params = {
          parentX,
          parentY,
          borderRadius,
          blockHeight,
          blockWidth,
          diffX,
          diffY,
          parentRoot,
        };
        //тупо обратная стрелка, т.к. прямой родитель в sometimes
        if (onSameY && onSameX) {
          return null;
        }


        // если больше 0 то идёт сверху родителя вправо и заходит справа от блока
        // если меньше - то идёт снизу родителя влево и заходит слева блока

        if (onSameY && !onSameX) {
          // надо понять, какое между ними расстояние
          return null;
        }

        // если больше 0 то идёт сверху родителя и заходит снизу блока
        // если меньше - то идёт снизу родителя и заходит сверху блока

        if (!onSameY && onSameX) {
          return null;
        }

        if (!onSameY && !onSameX) {
          if (diffX > 0) {
            // правее

            if (diffY > 0) {
              // правее и выше
              return generateBackArrow('rightUpper', params);
            } else {
              // ниже
              // правее и ниже

              if (diffY === -10) {
                return generateBackArrow('rightLower', params);
              }
              return generateBackArrow('right', params);
            }
          } else {
            // левее
            if (diffY > 0) {
              // левее и выше
              // выше
            } else {
              if (diffY === -38) {
                return generateBackArrow('leftUpper', params);
              }
              // левее и ниже
              return generateBackArrow('leftLower', params);
            }
          }
        }
      }
    },
    getPathInfo() {
      return {
        lineStr: this.pathString,
        reversed: false,
      };
    },
    highlightAncestor(type) {
      if (this.item.nodeparent) {
        this.$emit('highlightAncestor', type);
      }
    },
    async highligntArrows(isSelected, type) {
      if (type ==='select') {
        this.updateSelectedArrows(null);
      }
      await this.$nextTick();

      if (this.item.ancestor) {
        this.backArrows = null;

        const coords = this.getCoordsFromDict(this.nodeparent);

        this.parentCoords = coords || null;
        let result = [];

        const allSometimes = [...this.item.ancestor.sometimes.filter((id) => id !== this.item.nodeparent)];

        if (this.item.sometimesParent) {
          allSometimes.push('null');
        }
        for (let ancestorId of allSometimes) {
          const backArrow = this.getSometimesArrow(ancestorId);
          if (backArrow) {
            if (!result) {
              result = [];
            }
            result.push(backArrow);
          }
        }

        this.backArrows = result;
        const nearestAncestorPath = {
          lineStr: this.pathString,
          reversed: false,
        };
        result.push(nearestAncestorPath);

        if (type === 'select') {
          if (isSelected) {
            this.highlightAncestor(type);
            this.updateSelectedArrows(result);
          }
          this.backArrows = result;
          return;
        }

        if (type === 'hover') {
          this.highlightAncestor(type);
          if (isSelected) {
            this.updatePreviewArrows(result);
          } else {
            this.updatePreviewArrows(null);
          }
          return;
        }
      }
    },
    async updateBackArrows(isSelected) {
      await this.highligntArrows(isSelected, 'select');
    },
    async updatePreviewBackArrows(isSelected) {
      await this.highligntArrows(isSelected, 'hover');
    },
  },
  // async mounted() {
  //   // await new Promise(res => setTimeout(res,1000))
  //   this.updateBackArrows()
  // },
  watch: {
    isSelected: {
      immediate: true,
      handler: function (val, oldVal) {
        if (val !== oldVal) {
          this.updateBackArrows(val);
        }
      },
    },
    hovered: {
      immediate: true,
      handler: function (val, oldVal) {
        if (val !== oldVal) {
          this.updatePreviewBackArrows(val);
        }
      },
    },
  },
};
</script>
