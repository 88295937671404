<template>
  <div v-html="svgContent" :style="{ width: width + 'px', height: height + 'px' }"></div>
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    id: {
      type: String,
      required: true,
    },
    width: {
      type: [String, Number],
      default: 24,
    },
    height: {
      type: [String, Number],
      default: 24,
    },
    color: {
      type: String,
    },
  },
  computed: {
    svgContent() {
      const color = this.color
      const svgs = {
        'at': `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.3333 10C13.3333 8.15905 11.8409 6.66667 10 6.66667C8.15905 6.66667 6.66667 8.15905 6.66667 10C6.66667 11.8409 8.15905 13.3333 10 13.3333C11.8409 13.3333 13.3333 11.8409 13.3333 10ZM13.3333 10V11.25C13.3333 12.4006 14.2661 13.3333 15.4167 13.3333C16.5673 13.3333 17.5 12.4006 17.5 11.25V10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5M13.75 16.4952C12.5667 17.1784 11.2746 17.5024 9.99997 17.5013" stroke="#797C84" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        `,
        'lock': `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.0002 12.5V14.1667M5.00016 17.5H15.0002C15.9206 17.5 16.6668 16.7538 16.6668 15.8333V10.8333C16.6668 9.91286 15.9206 9.16667 15.0002 9.16667H5.00016C4.07969 9.16667 3.3335 9.91286 3.3335 10.8333V15.8333C3.3335 16.7538 4.07969 17.5 5.00016 17.5ZM13.3335 9.16667V5.83333C13.3335 3.99238 11.8411 2.5 10.0002 2.5C8.15921 2.5 6.66683 3.99238 6.66683 5.83333V9.16667H13.3335Z" stroke="#797C84" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
        `,
        
        'account':`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.3332 5.83333C13.3332 7.67428 11.8408 9.16667 9.99984 9.16667C8.15889 9.16667 6.6665 7.67428 6.6665 5.83333C6.6665 3.99238 8.15889 2.5 9.99984 2.5C11.8408 2.5 13.3332 3.99238 13.3332 5.83333Z" stroke="#797C84" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.99984 11.6667C6.77818 11.6667 4.1665 14.2783 4.1665 17.5H15.8332C15.8332 14.2783 13.2215 11.6667 9.99984 11.6667Z" stroke="#797C84" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>         
        `,
        'phone':`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.5 4.16667C2.5 3.24619 3.24619 2.5 4.16667 2.5H6.89937C7.25806 2.5 7.57651 2.72953 7.68994 3.06981L8.93811 6.81434C9.06926 7.20777 8.89115 7.63776 8.52022 7.82322L6.63917 8.76375C7.55771 10.801 9.19898 12.4423 11.2363 13.3608L12.1768 11.4798C12.3622 11.1088 12.7922 10.9307 13.1857 11.0619L16.9302 12.3101C17.2705 12.4235 17.5 12.7419 17.5 13.1006V15.8333C17.5 16.7538 16.7538 17.5 15.8333 17.5H15C8.09644 17.5 2.5 11.9036 2.5 5V4.16667Z" stroke="#797C84" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        `,
        'eye-closed':`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.4998 2.5L5.49076 5.49097M17.4998 17.5L14.5091 14.5093M11.5622 15.6872C11.0562 15.7831 10.5341 15.8333 10.0002 15.8333C6.2688 15.8333 3.11022 13.3809 2.04834 9.99997C2.33742 9.07958 2.78189 8.22801 3.35099 7.47598M8.23203 8.23223C8.68444 7.77982 9.30944 7.5 9.9998 7.5C11.3805 7.5 12.4998 8.61929 12.4998 10C12.4998 10.6904 12.22 11.3154 11.7676 11.7678M8.23203 8.23223L11.7676 11.7678M8.23203 8.23223L5.49076 5.49097M11.7676 11.7678L5.49076 5.49097M11.7676 11.7678L14.5091 14.5093M5.49076 5.49097C6.79061 4.65295 8.3386 4.16667 10.0002 4.16667C13.7315 4.16667 16.8901 6.61909 17.952 10C17.3629 11.8756 16.1286 13.4654 14.5091 14.5093" stroke="#797C84" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        `,
        'eye-opened':`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.4998 10.0001C12.4998 11.3808 11.3805 12.5001 9.9998 12.5001C8.61908 12.5001 7.4998 11.3808 7.4998 10.0001C7.4998 8.61937 8.61908 7.50008 9.9998 7.50008C11.3805 7.50008 12.4998 8.61937 12.4998 10.0001Z" stroke="#797C84" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.04834 10.0001C3.11023 6.61915 6.2688 4.16675 10.0002 4.16675C13.7315 4.16675 16.8901 6.61918 17.952 10.0001C16.8901 13.381 13.7315 15.8334 10.0002 15.8334C6.2688 15.8334 3.11022 13.381 2.04834 10.0001Z" stroke="#797C84" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        `,
        'valid':`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.1665 10.8334L7.49984 14.1667L15.8332 5.83337" stroke="#06D295" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        `,
        'fav-icon-toggled':`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.9896 2.67499C9.36293 1.77749 10.6363 1.77749 11.0096 2.67499L12.7446 6.84665L17.2479 7.20832C18.2179 7.28582 18.6113 8.49582 17.8721 9.12915L14.4413 12.0683L15.4888 16.4625C15.7146 17.4092 14.6854 18.1567 13.8554 17.65L9.9996 15.295L6.14376 17.65C5.31376 18.1567 4.2846 17.4083 4.51043 16.4625L5.55793 12.0683L2.1271 9.12915C1.38793 8.49582 1.78126 7.28582 2.75126 7.20832L7.2546 6.84665L8.9896 2.67499Z" fill="${color}"/>
        </svg>`,
        'fav-icon-untoggled':`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.0521 7.13466L12.228 7.55758L12.6846 7.59425L17.1879 7.95591L17.1882 7.95594C17.4928 7.98027 17.6169 8.36012 17.3842 8.55958C17.3841 8.5596 17.3841 8.55961 17.3841 8.55962L13.9533 11.4988L13.6055 11.7967L13.7117 12.2422L14.7592 16.6364L14.7592 16.6365C14.8303 16.9343 14.507 17.1689 14.2464 17.0099C14.2463 17.0099 14.2463 17.0099 14.2462 17.0098L10.3905 14.6549L9.9996 14.4162L9.60867 14.6549L5.75299 17.0098C5.75294 17.0099 5.75289 17.0099 5.75284 17.0099C5.49251 17.1687 5.16897 16.9338 5.23992 16.6367L5.23999 16.6364L6.28749 12.2422L6.39369 11.7967L6.04587 11.4988L2.61508 8.55962C2.61507 8.55961 2.61505 8.5596 2.61504 8.55958C2.38231 8.36012 2.50641 7.98027 2.81099 7.95594L2.8113 7.95591L7.31464 7.59425L7.7712 7.55758L7.94709 7.13466L9.68207 2.96304C9.68208 2.96302 9.68209 2.96301 9.68209 2.963C9.79923 2.68148 10.2 2.68148 10.3171 2.963C10.3171 2.96301 10.3171 2.96302 10.3171 2.96304L12.0521 7.13466Z" stroke="${color}" stroke-width="1.5"/>
        </svg>`,
        'expandCard':`<svg width="20.000000" height="20.000000" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <defs>
            <clipPath id="clip4_18734">
              <rect id="Icon" rx="-0.500000" width="19.000000" height="19.000000" transform="translate(0.500000 0.500000)" fill="white" fill-opacity="0"/>
            </clipPath>
          </defs>
          <g clip-path="url(#clip4_18734)">
            <path id="Icon" d="M15.83 7.5L10 13.33L4.16 7.5" stroke="#9A9A9A" stroke-opacity="1.000000" stroke-width="1.500000" stroke-linejoin="round" stroke-linecap="round"/>
          </g>
        </svg>
        `,
        'collapseCard':`<svg width="20.000000" height="20.000000" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <defs>
            <clipPath id="clip4_18338">
              <rect id="Icon" rx="-0.500000" width="19.000000" height="19.000000" transform="translate(0.500000 0.500000)" fill="white" fill-opacity="0"/>
            </clipPath>
          </defs>
          <g clip-path="url(#clip4_18338)">
            <path id="Icon" d="M4.16 12.5L10 6.66L15.83 12.5" stroke="#9A9A9A" stroke-opacity="1.000000" stroke-width="1.500000" stroke-linejoin="round" stroke-linecap="round"/>
          </g>
        </svg>
        `,
        'editCard':`<svg width="20.000000" height="20.000000" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <defs>
            <clipPath id="clip4_18729">
              <rect id="Icon" rx="-0.500000" width="19.000000" height="19.000000" transform="translate(0.500000 0.500000)" fill="white" fill-opacity="0"/>
            </clipPath>
          </defs>
          <g clip-path="url(#clip4_18729)">
            <path id="Icon" d="M16.88 3.11C17.7 3.92 17.7 5.24 16.88 6.05L5.41 17.52L2.5 17.52L2.5 14.55L13.94 3.11C14.75 2.29 16.07 2.29 16.88 3.11ZM12.69 4.36L15.63 7.3" stroke="#9A9A9A" stroke-opacity="1.000000" stroke-width="1.500000" stroke-linejoin="round" stroke-linecap="round"/>
          </g>
        </svg>`,
        'cancelCard':`<svg width="20.000000" height="20.000000" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
          <clipPath id="clip4_19070">
            <rect id="Icon" rx="-0.500000" width="19.000000" height="19.000000" transform="translate(0.500000 0.500000)" fill="white" fill-opacity="0"/>
          </clipPath>
        </defs>
        <g clip-path="url(#clip4_19070)">
          <path id="Icon" d="M5 15L15 5M5 5L15 15" stroke="#9A9A9A" stroke-opacity="1.000000" stroke-width="1.500000" stroke-linejoin="round" stroke-linecap="round"/>
        </g>
      </svg>
      `
      };

      const svg = svgs[this.id];
      if (!svg) {
        return this.id;
      } 
      return svg //.replace(/width="[^"]+"/, `width="${this.width}"`).replace(/height="[^"]+"/, `height="${this.height}"`);
    },
  },
};
</script>
 