<template>
 <div v-if ="!isTokenValid">
      <v-card-title class="d-flex justify-center text-h4">
        <div>{{ $t('registerConfirmation.problem') }}</div>
      </v-card-title>
      <div
        class="pt-8"
        style="display: flex; flex-direction: column; align-items: center"
      >
        <div class="regular-text">{{ $t("registerConfirmation.expired") }}</div>
     
      </div>
      <div class="pt-8">
        <CustomButton
          :label="$t('registerConfirmation.resend')"
          backgroundColor="#1D85DD"
          textColor="white"
          customHeight="52px"
          iconClass="mr-2"
          rounded="medium"
          block
          :disabled="false"
          size="large"
          @click="()=>{}"
        />
      </div>
    </div>
    <div v-else>
      <div class="text-h4 d-flex justify-center">
        {{ $t('registerConfirmation.successTitle') }}
      </div>
         <div
        class="pt-8"
        style="display: flex; flex-direction: column; align-items: center"
      >
        <div class="regular-text">{{ $t("registerConfirmation.successText") }}</div>
     
      </div>
      <div class="pt-8">
        <CustomButton
          :label="$t('registerConfirmation.login')"
          backgroundColor="#1D85DD"
          textColor="white"
          customHeight="52px"
          iconClass="mr-2"
          rounded="medium"
          block
          :disabled="false"
          size="large"
          @click="switchModeToLogin"
        />
      </div>
    </div>
</template>

<script>
import CustomButton from './CustomButton.vue';
export default {
      props: {
    token: {
      type: String,
      required: true
    }
  },
    components: { 
    CustomButton, 
  },
      data() {
    return {
    }},
    computed: {
    isTokenValid() {
      // Check if the token is valid by calling the backend API
      return this.checkToken(); // Call the API to validate token
    //   return this.token !== null; // Adjust according to your validation logic
    },
    },  
  methods: {
  switchModeToLogin(){
     this.$emit('switch-to-login')
    },
    checkToken() { 
         
      let isValid = true; // Replace with actual API call

      if (!isValid || this.token==='invalid') {
        isValid = false
      } 
      return isValid
    },
  },
};
</script>

<style scoped>
.text-h4 {
  text-align: center; /* Ensures text is centered */
} 
.hyperlink-text { 
  color: var(--text-primary, #F4F4F4);  
  font-family: "Source Sans 3";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.regular-text {
  color: var(--text-secondary, #9A9A9A);
 
  font-family: "Source Sans 3";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
}
</style>
