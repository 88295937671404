<template>
  <v-container
    style="height: 100%; width: 100%"
    class="bg-white align-center justify-center d-flex"
  >
    <v-sheet>
      <div class="h2">Подтверждение электронной почты</div>
      <div>{{ this.message }}</div>
    </v-sheet>
  </v-container>
</template>

<script>
import axios from '@/plugins/axiosWrapper';

export default {
  data: () => ({
    message: '',
  }),
  async mounted() {
    const cc = this.$route.query?.cc;
    if (!cc) {
      this.message = 'Похоже что-то со ссылкой';
    } else {
      const resp = await axios({
        url: `${process.env.VUE_APP_DEV_API}/api/`,
        method: 'post',
        data: {
          mtd: 'confirmemail',
          cc,
        },
      }).catch((e) => console.log(e));
      if (resp && resp.data) {
        console.log(resp.data);
      } else {
        this.message = 'Ошибка подтверждения почты';
      }
    }

    console.log(cc);
  },
};
</script>

<style scoped></style>
