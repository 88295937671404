<template>
  <v-btn
    :class="buttonClass"
    :color="color"
    :icon="true"
    :elevation="0"
    @click="handleClick"
  >
    <v-icon :style="{ color: iconColor }">{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'IconButton',
  props: {
    icon: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: 'primary'
    },
    size: {
      type: String,
      default: '32px' // Default size
    },
    borderRadius: {
      type: String,
      default: '8px' // Default border radius
    },
    iconColor: {
      type: String,
      default: 'currentColor' // Default color is 'currentColor'
    },
    disableClick: {
      type: Boolean,
      default: false // Default is false, meaning the click event is not disabled
    }
  },
  computed: {
    buttonClass() {
      return {
        'rounded-square': true,
        [`size-${this.size}`]: true,
        [`radius-${this.borderRadius}`]: true
      };
    }
  },
  methods: {
    handleClick(event) {
      if (!this.disableClick) {
        this.$emit('click', event);
      }
    }
  }
}
</script>

<style scoped>
.rounded-square {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--button-size, 32px); /* Use custom property for dynamic size */
  height: var(--button-size, 32px); /* Use custom property for dynamic size */
  border-radius: var(--button-radius, 12px); /* Use custom property for dynamic border radius */
}

.size-32px {
  --button-size: 32px;
}

.size-64px {
  --button-size: 64px;
}

/* Additional size classes can be added as needed */

.radius-8px {
  --button-radius: 8px;
}

.radius-16px {
  --button-radius: 16px;
}

/* Additional border-radius classes can be added as needed */
</style>
