<template>
  <div
    style="
      position: absolute;
      width: 300px;
      height: 200px;
      right: 10px;
      bottom: 40px;
      z-index: 500;
      overflow: auto;
      background-color: rgba(155, 155, 155, 0.2);
      backdrop-filter: blur(8px);
      border: 1px solid rgba(55, 55, 55, 0.2);
    "
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="blockSize * 1.5 * items.length + 2"
      :height="getHeight"
      id="svg-tree"
    >
      <g v-for="(column, xIndex) in items" :key="xIndex + 'col'">
        <rect
          :x="blockSize * 1.5 * xIndex + blockSize / 4"
          :y="0"
          :width="blockSize * 1.5"
          :height="getHeight"
          :fill="getColumnFill(column)"
        />
        <text
          fill="rgba(55,55,55,.8)"
          :style="{ fontSize: `12px` }"
          :y="blockSize"
          :x="blockSize * 1.5 * xIndex + blockSize / 2"
        >
          {{ column[0] && column[0].movenum }}
        </text>

        <g
          v-for="(row, yIndex) in column"
          :key="xIndex + 'col' + yIndex + 'row' + row.nodeid"
        >
          <!-- <mini-line
            v-if="xIndex > 0"
            :blockSize="blockSize"
            :parentX="blockSize * 1.5 * xIndex - 1"
            :parentY="
              blockSize * 2 + blockSize * 1.5 * row.parentIndex
            "
            :x="blockSize * 2 + blockSize * 1.5 * xIndex"
            :y="blockSize + blockSize * 2 + blockSize * 1.5 * yIndex"
            :color="row.b_arrow_color || 'black'"
            :parentIndex="row.parentIndex"
            :blockIndex="yIndex"
          /> -->
          <mini-dot
            :selected="selectedOpening && row.nodethis === selectedOpening.nodethis"
            :item="row"
            :blockSize="blockSize"
            :x="blockSize * 2 + blockSize * 1.5 * xIndex" 
            :y="blockSize + blockSize * 2 + blockSize * 1.5 * yIndex"
            :handleTooltip="handleTooltip"
            :handleClick="handleClick"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
import MiniDot from './MiniDot.vue';
// import MiniLine from './MiniLine.vue';

export default {
  props: ['items', 'movesModel', 'selectedOpening', 'handleTooltip', 'handleClick'],
  data: () => ({
    blockSize: 20,
  }),
  components: {
    MiniDot,
    // MiniLine,
  },
  computed: {
    getHeight() {
      const { items, blockSize } = this;

      if (!items || items.length === 0 || !items[0][0]) return 0;
      const key = 'visibleChildren'
       
      return items[0][0][key] * blockSize + blockSize * 4;
    },
  },
  methods: {
    getColumnFill(items) {
      if (!items || !items.length === 0) return 'rgba(255,255,255,.4)';

      return items[0]?.hm % 2 === 0
        ? 'rgba(255,255,255,.4)'
        : 'rgba(50,50,50,0)';
    },
  },
  // mounted() {
  //     const el = document.getElementById('svg-tree')
  //     console.log(el)
  //     this.tree = el
  // }
};
</script>

<style scoped></style>
