<template>
  <v-card
    :style="{
      width: isCollapsed ? maxWidth : expandedMaxWidth,
      maxHeight: isCollapsed ? maxHeight : expandedMaxHeight,
      position: 'relative',
    }"
  >
    <v-card-title class="semibold-14">
      <div style="display: flex; justify-content: space-between; width: 100%">
        <div style="white-space: wrap; width: calc(100% - 30px)">
          {{ title }}
        </div>
        <custom-icon
          @click="toggle"
          style="
            position: absolute;
            right: 14px;
            top: 14px;
            min-width: auto;
            padding: 0;
          "
          class="icon append-icon"
          :id="isCollapsed ? collapsedIconId : expandedIconId"
        />
      </div>
    </v-card-title>
    <v-expand-transition>
      <div v-if="!isCollapsed">
        <v-divider />
        <slot></slot>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  name: 'CollapsibleCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    maxWidth: {
      type: String,
      default: '240px', // Default width when collapsed
    },
    expandedMaxWidth: {
      type: String,
      default: '100%', // Default width when expanded
    },
    maxHeight: {
      type: String,
      default: '300px', // Default height when collapsed
    },
    expandedMaxHeight: {
      type: String,
      default: '100%', // Default height when expanded
    },
    collapsedIconId: {
      type: String,
      required: true,
      default: 'expandCard',
    },
    expandedIconId: {
      type: String,
      required: true,
      default: 'collapseCard',
    },
    cardId: {
      type: String,
    },
    openedCardId: {},
    changeOpenedCardId: {},
  },
  data() {
    return {
      isCollapsed: true,
    };
  },
  methods: {
    toggle() {
      if (this.cardId === this.openedCardId) {
        this.changeOpenedCardId(null);
      } else {
        this.changeOpenedCardId(this.cardId);
      }
    },
  },

  watch: {
    openedCardId: {
      immediate: true,
      handler: function (val) {
        const cardId = this.cardId;

        if (cardId === val) {
          if (this.isCollapsed) {
            this.isCollapsed = false;
          }
        } else {
          this.isCollapsed = true;
        }
      },
    },
  },
};
</script>

<style scoped>
.custom-card {
  margin: 4px auto;
  box-sizing: border-box;
  border: 1px solid rgb(60, 60, 60);
  border-radius: 16px;
  background: rgb(35, 35, 35);
}
</style>
