<template>
  <div>
    <!-- <v-table density="compact" class="custom-table bg-grey pr-4">
      <thead class="regular-14" style="width: 100%">
        <tr style="height: 44px">
          <td width="25%">
            <v-tooltip :text="$t('opening1.mistakesdEvalHint')">
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ $t('opening1.mistakesdEval') }}</span>
              </template>
            </v-tooltip>
          </td>
          <td width="25%">
            <v-tooltip :text="$t('opening1.mistakesNumHint')">
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ $t('opening1.mistakesNum') }}</span>
              </template>
            </v-tooltip>
          </td>
          <td width="25%">
            <v-tooltip :text="$t('opening1.mistakesTotalShortageHint')">
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{
                  $t('opening1.mistakesTotalShortage')
                }}</span>
              </template>
            </v-tooltip>
          </td>
          <td width="25%">
            <v-tooltip :text="$t('opening1.mistakesReFailHint')">
              <template v-slot:activator="{ props }">
                <span v-bind="props">Postion Status</span>
              </template>
            </v-tooltip>
          </td>
        </tr>
      </thead>
    </v-table>
    <v-virtual-scroll
      :height="sizes.vh - 264"
      :items="mistakesOpenings"
      ref="vScroll"
    >
      <template v-slot:default="{ item }">
        <v-table
          density="compact"
          class="semibold-14"
          width="100%"
          ref="mistakeTable"
        >
          <tbody>
            <tr
              :key="item.position_id + '' + item.eco_common + '' + item.labels"
              :class="`${isActive(item) ? 'bg-activeMistake' : ''}`"
              @click="handleMistakeClick(item)"
              :style="`color: ${getColor(
                null,
                null,
                item,
              )};cursor: pointer; width: 100%; height:44px`"
            >
              <td
                width="25%"
                @mouseenter="
                  (e) =>
                    toggleTooltip({
                      top: e.clientY - 280,
                      left: 50 - 500,
                      move_uci: item.prevmove,
                      fen: item.fen,
                      prevmove: item.prevmove,
                      herowhite: item.herowhite,
                    })
                "
                @mouseleave="toggleTooltip(null)"
              >
                {{
                  (
                    Math.round(item.total_shortage / (item.total_count || 1)) /
                    100
                  ).toFixed(2)
                }}
              </td>

              <td
                width="25%"
                @mouseenter="
                  (e) =>
                    toggleTooltip({
                      top: e.clientY - 280,
                      left: 50 - 500,
                      move_uci: item.move_uci,
                      fen: item.fen,
                      herowhite: item.herowhite,
                    })
                "
                @mouseleave="toggleTooltip(null)"
              >
                {{ item.total_count }} ({{ item.unreasonable_count }})
              </td>

              <td
                width="25%"
                @mouseenter="
                  (e) =>
                    toggleTooltip({
                      top: e.clientY - 280,
                      left: 50 - 500,
                      move_uci: item.move_uci,
                      fen: item.fen,
                      herowhite: item.herowhite,
                    })
                "
                @mouseleave="toggleTooltip(null)"
              >
                {{ (item.total_shortage / 100).toFixed(2) }}
              </td>

              <td class="d-flex align-center">
                <custom-icon
                  id="fav-icon-untoggled"
                  :color="getColor(null, null, item)"
                />
                <span> {{ getStudiedText(item) }} </span>
              </td>
            </tr>
          </tbody>
        </v-table>
      </template>
    </v-virtual-scroll> -->
    <div v-if="altFilters">
      <v-btn-toggle
        v-model="currentAltFilter"
        density="compact"
        size="small"
        v-if="!compact"
      >
        <v-btn
          density="compact"
          size="small"
          v-for="option in filterOptions"
          :key="option.id"
          style="width: 157px"
          class="text-none semibold-14"
        >
          {{ option.text }}
        </v-btn>
      </v-btn-toggle>
    </div>
    <v-table density="compact" class="custom-table">
      <thead class="regular-14" style="width: 100%">
        <tr style="height: 44px">
          <td
            v-for="column in headers"
            :key="column.id + 'col'"
            :width="column.width"
          >
            <v-tooltip :text="column.tooltip">
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ column.title }}</span>
              </template>
            </v-tooltip>
          </td>
        </tr>
      </thead>
    </v-table>
    <v-virtual-scroll
      :height="customHeight || sizes.vh - 264"
      :items="mistakesOpenings"
      ref="vScroll"
    >
      <template v-slot:default="{ item }">
        <v-table
          density="compact"
          class="semibold-14"
          width="100%"
          ref="mistakeTable"
        >
          <tbody>
            <tr
              :key="item.position_id + '' + item.eco_common + '' + item.labels"
              :class="`${isActive(item) ? 'bg-activeMistake' : ''}`"
              @click="handleMistakeClick(item)"
              :style="`color: ${getColor(
                null,
                null,
                item,
              )};cursor: pointer; width: 100%; height:44px`"
            >
              <td
                v-for="column in headers"
                :key="column.id + 'coldata'"
                :style="{ width: column.width }"
                @mouseenter="
                  (e) =>
                    toggleTooltip(
                      {
                        top: e.clientY - 280,
                        left: e.clientX + 20,
                        move_uci: item.prevmove,
                        fen: item.fen,
                        prevmove: item.prevmove,
                        herowhite: item.herowhite,
                        item,
                      },
                      true,
                    )
                "
                @mouseleave="toggleTooltip(null)"
              >
                <div class="d-flex align-center" style="width: 100%">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    v-if="column.favIcon"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      :d="getStatusIconPath(item).path"
                      :fill="getStatusIconPath(item).fill || 'none'"
                      :stroke="getStatusIconPath(item).stroke || 'none'"
                    />
                  </svg>

                  <div>
                    {{ column.value(item) }}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </v-table>
      </template>
    </v-virtual-scroll>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';

export default {
  props: {
    toggleTooltip: {
      type: Function,
      defailt: () => null,
    },
    exercisesList: {
      default: () => undefined,
    },
    deletable: {
      default: false,
      type: Boolean,
    },
    getBg: {
      type: Function,
    },
    mode: {
      type: String,
    },
    customHeight: {
      type: Number,
      default: null,
    },
    altFilters: {
      type: Boolean,
      default: false,
    },
    exerOnly: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    preventFromClick: false,
    currentAltFilter: 0,
    filterOptions: [
      { id: 0, text: 'Рекомендованные' },
      { id: 1, text: 'План тренировки' },
      { id: 2, text: 'Избранное' },
    ],
  }),
  computed: {
    ...mapGetters('data', ['getMistakesOpenings']),
    ...mapState('data', [
      'selectedMistake',
      'compareFilters',
      'selectedOpening',
      'heroesList',
      'mistakes',
    ]),
    ...mapState(['sizes']),
    headers() {
      const mode = this.mode;
      const modeMapper = {
        preAnalysisCompact: [
          {
            id: 1,
            title: this.$t('opening1.mistakesdEval'),
            tooltip: this.$t('opening1.mistakesdEvalHint'),
            value: (item) =>
              (
                Math.round(item.total_shortage / (item.total_count || 1)) / 100
              ).toFixed(2),
            width: '15%',
          },
          {
            id: 2,
            title: this.$t('opening1.mistakesNum'),
            tooltip: this.$t('opening1.mistakesNumHint'),
            value: (item) => item.total_count,
            width: '15%',
          },
          {
            id: 3,
            title: this.$t('opening1.mistakesTotalShortage'),
            tooltip: this.$t('opening1.mistakesTotalShortageHint'),
            value: (item) => (item.total_shortage / 100).toFixed(2),
            width: '25%',
          },
          {
            id: 4,
            title: this.$t('opening1.mistakesStudiedStatus'),
            tooltip: this.$t('opening1.mistakesStudiedStatusHint'),
            favIcon: true,
            value: (item) => this.getStatusText(item),
            width: '45%',
          },
        ],
        preAnalisysFull: [
          {
            id: 1,
            title: this.$t('opening1.mistakesdEval'),
            tooltip: this.$t('opening1.mistakesdEvalHint'),
            value: (item) =>
              (
                Math.round(item.total_shortage / (item.total_count || 1)) / 100
              ).toFixed(2),
            width: '10%',
          },
          {
            id: 2,
            title: this.$t('opening1.mistakesNum'),
            tooltip: this.$t('opening1.mistakesNumHint'),
            value: (item) => item.total_count,
            width: '10%',
          },
          {
            id: 3,
            title: this.$t('opening1.mistakesTotalShortage'),
            tooltip: this.$t('opening1.mistakesTotalShortageHint'),
            value: (item) => (item.total_shortage / 100).toFixed(2),
            width: '10%',
          },
          {
            id: 4,
            title: this.$t('opening1.mistakesStudiedStatus'),
            tooltip: this.$t('opening1.mistakesStudiedStatusHint'),
            favIcon: true,
            value: () => null,
            width: '10%',
          },
          {
            id: 5,
            title: 'Список ходов для тренировки',
            tooltip: 'Список ходов для тренировки',
            value: () => null,
            width: '20%',
          },
          {
            id: 6,
            title: 'Изучено вариантов',
            tooltip: 'Всего изучено вариантов',
            value: () => null,
            width: '20%',
          },
          {
            id: 7,
            title: 'Пройдено вариантов',
            tooltip: 'Всего пройдено вариантов',
            value: () => null,
            width: '20%',
          },
        ],
        analysis: [
          {
            id: 1,
            title: this.$t('opening1.mistakesdEval'),
            tooltip: this.$t('opening1.mistakesdEvalHint'),
            value: (item) =>
              (
                Math.round(item.total_shortage / (item.total_count || 1)) / 100
              ).toFixed(2),
          },
          {
            id: 2,
            title: this.$t('opening1.mistakesNum'),
            tooltip: this.$t('opening1.mistakesNumHint'),
            value: (item) => item.total_count,
          },
          {
            id: 3,
            title: this.$t('opening1.mistakesTotalShortage'),
            tooltip: this.$t('opening1.mistakesTotalShortageHint'),
            value: (item) => (item.total_shortage / 100).toFixed(2),
          },
          {
            id: 4,
            title: 'Статус',
            tooltip: 'Статус изучения',
            favIcon: true,
            value: null,
          },

          {
            id: 5,
            title: 'Изучено вариантов',
            tooltip: 'Всего изучено вариантов',
            value: null,
          },
          {
            id: 6,
            title: 'Пройдено вариантов',
            tooltip: 'Всего пройдено вариантов',
            value: null,
          },
        ],
        exercise: [],
      };

      return modeMapper[mode] || modeMapper.preAnalysisCompact;
    },
    getColor() {
      const mode = this.mode;
      if (mode !== 'analysis') {
        return () => '#9A9A9A';
      }

      if (!this.getBg) {
        return () => '#9A9A9A';
      }
      return this.getBg;
    },
    mistakesOpenings() {
      const altFilters = this.altFilters;
      const exers = this.exercisesList;
      const exerOnly = this.exerOnly;
      const mistakes = this.mistakes;
      if (!mistakes || !mistakes.list) {
        return [];
      }

      let list = [];

      if (exerOnly) {
        if (!exers || exers.length === 0) {
          return [];
        }
        list = mistakes.list.filter((op) =>
          exers.find(
            (ex) =>
              ex.position_id === op.position_id &&
              ex.herowhite === op.herowhite,
          ),
        );
      } else {
        list = mistakes.list
      }

      if (altFilters) {
        const currentAltFilter = this.currentAltFilter;

        if (currentAltFilter === 0) {
          return list;
        }

        if (currentAltFilter === 1) {
          return list.filter((op) =>
            exers.find(
              (ex) =>
                ex.position_id === op.position_id &&
                ex.herowhite === op.herowhite,
            ),
          );
        }

        if (currentAltFilter === 0) {
          return list.filter((op) => op.labels.indexOf(2) > -1);
        }
      }

     return list
    },
    posid() {
      return this.$route.params?.posid;
    },
    isActive() {
      const selectedMistake = this.selectedMistake;
      const posid = this.posid;
      return (item) => {
        if (!this.deletable) {
          const fen = item.fen;
          if (!selectedMistake) return false;
          return selectedMistake.fen == fen;
        } else {
          if (!posid) return false;
          return posid == item.position_id;
        }
      };
    },
    heroid() {
      return this.$route.params.heroid;
    },
    heroCategories() {
      return this.$route.params.categories;
    },
  },
  methods: {
    ...mapActions('data', [
      'getOpenings',
      'loadChildren',
      'getMistakesOpening',
      'modifyPositionLabel',
      'getHeroesList',
      'removeFromExercises',
    ]),
    getStatusText(item) {
      return 'какой-то';
    },
    handleDeleteClick(item) {
      this.removeFromExercises({
        heroid: Number(this.heroid),
        roots: [
          {
            posid: item.position_id,
            herowhite: item.herowhite,
          },
        ],
      });
    },
    getStudiedText({ studied, mdegree }) {
      const statuses = ['Not studied', 'Studied'];
      if (studied * mdegree > 1) return 'Fault again';

      return statuses[studied] || 'In progress';
    },
    getIconColor(val) {
      if (this.getIgnored(val)) {
        return '#4A4A4A';
      }
      if (val.studied === 1) {
        return '#06D295';
      }
      return '#C05E60';
    },

    getStatusIconPath(val) {
      const fullStar = `
M8.9896 2.67508C9.36293 1.77758 10.6363 1.77758 11.0096 2.67508L12.7446 6.84674L17.2479 7.20841C18.2179 7.28591 18.6113 8.49591 17.8721 9.12924L14.4413 12.0684L15.4888 16.4626C15.7146 17.4092 14.6854 18.1567 13.8554 17.6501L9.9996 15.2951L6.14376 17.6501C5.31376 18.1567 4.2846 17.4084 4.51043 16.4626L5.55793 12.0684L2.1271 9.12924C1.38793 8.49591 1.78126 7.28591 2.75126 7.20841L7.2546 6.84674L8.9896 2.67508Z
`;

      const outlineStar = `
M12.0521 7.13475L12.228 7.55767L12.6846 7.59434L17.1879 7.956L17.1882 7.95603C17.4928 7.98036 17.6169 8.36021 17.3841 8.55967C17.3841 8.55969 17.3841 8.5597 17.3841 8.55971L13.9533 11.4988L13.6055 11.7968L13.7117 12.2423L14.7592 16.6365L14.7592 16.6366C14.8303 16.9344 14.507 17.1689 14.2464 17.01C14.2463 17.01 14.2463 17.01 14.2462 17.0099L10.3905 14.655L9.9996 14.4163L9.60867 14.655L5.75299 17.0099C5.75294 17.01 5.75289 17.01 5.75284 17.01C5.49251 17.1688 5.16897 16.9339 5.23992 16.6368L5.23999 16.6365L6.28749 12.2423L6.39369 11.7968L6.04587 11.4988L2.61508 8.55971C2.61507 8.5597 2.61505 8.55969 2.61504 8.55967C2.38231 8.36021 2.50641 7.98036 2.81099 7.95603L2.8113 7.956L7.31464 7.59434L7.7712 7.55767L7.94709 7.13475L9.68207 2.96313C9.68208 2.96312 9.68209 2.9631 9.68209 2.96309C9.79923 2.68157 10.2 2.68158 10.3171 2.96309C10.3171 2.9631 10.3171 2.96311 10.3171 2.96313L12.0521 7.13475Z`;
      if (this.getIgnored(val)) {
        return { path: outlineStar, stroke: this.getIconColor(val) };
      }
      return { path: fullStar, fill: this.getIconColor(val) };
      // return outlineStar;
    },
    handleMistakeClick(item) {
      this.preventFromClick = true;
      if (this.exercisesList?.length > 0 && !this.altFilters) {
        const occur = this.exercisesList?.find(
          (ex) =>
            ex.position_id === item.position_id &&
            ex.herowhite === item.herowhite,
        );
        if (occur) {
          this.$emit('mistakeClick', occur, item);
          return;
        }
      }
      this.$emit('mistakeClick', item);
    },
    getIgnored(item) {
      return item?.labels?.indexOf(2) > -1;
    },
    handleGoToMistake(item) {
      const mistakesOpenings = this.mistakesOpenings;

      const index = mistakesOpenings.findIndex(
        (m) => m.position_id === item.position_id,
      );

      const scrollEl = this.$refs.vScroll;
      if (scrollEl && index > -1) {
        scrollEl.scrollToIndex(index);
      }
    },
    // getEyeColor(labels) {
    //   if (!labels) return 'grey';
    //   const has3 = labels.indexOf(3) > -1;
    //   const has2 = labels.indexOf(2) > -1;

    //   if (has2 || has3) return 'white';
    //   return 'grey';
    // },
    // getEyeIcon(labels) {
    //   if (!labels || labels.indexOf(2) > -1) {
    //     return 'mdi-eye';
    //   }
    //   return 'mdi-eye-off';
    // },
    // handleEyeClick(item) {
    //   const labelsArray = this.$parseArray(item.labels);
    //   const heroid = this.heroid;
    //   if (labelsArray.indexOf(2) > -1) {
    //     this.modifyPositionLabel({
    //       opening: item,
    //       scenario: 'toIgnore',
    //       heroid,
    //       herocats: this.heroCategories,
    //     });
    //     return;
    //   }

    //   if (labelsArray.indexOf(3) > -1) {
    //     this.modifyPositionLabel({
    //       opening: item,
    //       scenario: 'toDefault',
    //       heroid,
    //       herocats: this.heroCategories,
    //     });
    //     return;
    //   }

    //   this.modifyPositionLabel({
    //     opening: item,
    //     scenario: 'toWatchlist',
    //     heroid,
    //     herocats: this.heroCategories,
    //   });
    // },
    getRefailIcon(studied, mdegree) {
      return studied * mdegree > 1 ? '!' : '-';
    },
    getTextColor(mdegree) {
      const mapper = {
        0: 'green',
        1: 'black',
        2: 'red',
      };
      return mapper[mdegree];
    },
  },
  watch: {
    selectedMistake: {
      immediate: true,
      handler: function (val) {
        if (!val) return;
        if (this.preventFromClick) {
          this.preventFromClick = false;
          return;
        } else {
          this.handleGoToMistake(val);
        }
      },
    },
    mistakesOpenings: {
      immediate: true,
      handler: async function (val) {
        if (val) {
          const selectedMistake = this.selectedMistake;
          if (selectedMistake) {
            this.handleGoToMistake(selectedMistake);
          }
        }
      },
    },
  },
  mounted() {
    // console.log('mounted')
    const selectedMistake = this.selectedMistake;
    if (selectedMistake) {
      this.handleGoToMistake(selectedMistake);
    }
  },
};
</script>

<style scoped>
.mistakes-table td {
  height: 44px;
}
</style>
